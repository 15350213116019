import React from "react";

const Add3DProductIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M21 17.25V19.9395L17.25 16.1895V11.25C17.2499 11.1108 17.2111 10.9743 17.1378 10.8559C17.0646 10.7374 16.9598 10.6417 16.8353 10.5795L12.75 8.53575V4.371L14.6895 6.3105L15.75 5.25L12 1.5L8.25 5.25L9.3105 6.3105L11.25 4.371V8.5365L7.16475 10.5787C7.04008 10.641 6.93523 10.7369 6.86197 10.8554C6.78871 10.974 6.74994 11.1106 6.75 11.25V16.1895L3 19.9395V17.25H1.5V22.5H6.75V21H4.0605L7.64775 17.4127L11.6648 19.4212C11.7689 19.4733 11.8836 19.5003 12 19.5003C12.1164 19.5003 12.2312 19.4733 12.3353 19.4212L16.3522 17.4127L19.9395 21H17.25V22.5H22.5V17.25H21ZM11.25 17.5365L8.25 16.0365V12.4635L11.25 13.9635V17.5365ZM12 12.6615L9.177 11.25L12 9.8385L14.823 11.25L12 12.6615ZM15.75 16.0365L12.75 17.5365V13.9635L15.75 12.4635V16.0365Z"
      fill="#4E5057"
    />
  </svg>
);

export default Add3DProductIcon;
