import PropTypes from "prop-types";
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PapperBlock from "components/PapperBlock/PapperBlock";
import ReduxForm from "./ReduxForm";

const styles = (theme) => ({});

class PaperForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  onGetError(error) {
    this.setState({ error });
  }

  componentDidUpdate(_, prevState) {
    const { formError } = this.props;
    const { error } = this.state;
    if (error !== prevState.error) {
      formError(error);
    }
  }

  render() {
    const {
      classes,
      children,
      title,
      description,
      headingBorder,
      hideHeading,
      className,
      container: Container,
      md,
      noWrapper,
      ...rest
    } = this.props;
    return (
      <Container
        title={title}
        description={description}
        headingBorder={headingBorder}
        hideHeading={hideHeading}
        className={className}
        noWrapper={noWrapper}
      >
        <ReduxForm
          {...rest}
          onGetError={(err) => this.onGetError(err)}
          buttonsContainer={({ ...props }) => (
            <div {...props} className={`${className}`} />
          )}
        >
          <div>{children}</div>
        </ReduxForm>
      </Container>
    );
  }
}

PaperForm.propTypes = {
  form: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.array.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  hideButtons: PropTypes.bool,
  /* eslint-disable react/no-unused-prop-types */
  blockOnDirty: PropTypes.bool,
  blockOnSubmitting: PropTypes.bool,
  /* eslint-enable react/no-unused-prop-types */
  md: PropTypes.number,
  container: PropTypes.element,
};

PaperForm.defaultProps = {
  hideButtons: false,
  blockOnDirty: true,
  blockOnSubmitting: true,
  md: 6,
  container: PapperBlock,
  title: "",
  description: "",
};

export default withStyles(styles)(PaperForm);
