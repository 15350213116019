const styles = (theme) => ({
  tagsTreeCard: {
    border: "1px solid rgba(224, 235, 255, 1)",
    borderRadius: ".5rem",
    padding: "1rem",
    paddingTop: 0,
    "& h3": {
      background: "rgba(233, 240, 253, 1)",
      color: "#206CEE",
      width: "fit-content",
      maxWidth: "95%",
      marginTop: "-20px",
      margin: "0 auto",
      padding: ".5rem 1rem",
      whiteSpace: "nowrap",
      borderRadius: ".5rem",
      fontSize: 18,
      marginBottom: 14,
      textTransform: "capitalize",
      position: "relative",
      zIndex: 3
    },
    "&:before": {
      position: "absolute",
      top: "-1rem",
      left: "",
    },
  },
  tagsTreeList: {
    // paddingTop: 13,
    padding: 0,
  },
  tagsTreeItem: {
    fontFamily: "Roboto",
    fontStyle: " normal",
    fontWeight: " 400",
    fontSize: " 16px",
    lineHeight: " 23px",
    display: " flex",
    alignItems: " center",
    letterSpacing: " 0.143662px",
    padding: 0,
    "& div:first-child": {
      minWidth: "30px",
      "& svg": {
        width: 30,
        height: 30,
      },
    },
  },
  tagsTreeItemActive: {
    color: "#FF8042",
    cursor: "pointer"
  },
});

export default styles;
