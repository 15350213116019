const styles = {
  productImage: {
    maxWidth: 88,
    borderRadius: 8,
    overflow: "hidden",
    width: 48,
    height: 48,
    background: "#f4f4f4f",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      background: "#f4f4f4f",
    },
  },
  chip: {
    margin: "8px 0 8px auto",
    color: "#FFF",
  },
  brand: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
  },
  brandImgContainer: {
    width: "50px",
    maxWidth: "50px",
    height: "50px",
    maxHeight: "50px",
    objectFit: "contain",
    padding: 10,
    borderRadius: "50%",
    backgroundColor: "#F6F6F6",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  brandImg: {
    width: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  currency: {
    marginLeft: 4,
  },
  spinner: {
    minWidth: 320,
    width: "100%",
    height: "calc(100vh - 100px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    flexDirection: "column",
  },
  wrapperContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
  },
};

export default styles;
