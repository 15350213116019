import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { ButtonAdd } from "components";
import PropTypes from "prop-types";
import LampIcon from "api/icons/LampIcon";
import styles from "./EmptyData-jss";

function EmptyData(props) {
  const { classes, msg, label, children, handleClick, icon, description, height } = props;
  return (
    <div className={classes.emptyDataContainer} style={{ height }}>
      {icon && <div className={classes.iconContainer}>{icon}</div>}
      <div className={classes.emptyDataContent}>
        {msg && <p className={classes.emptyDataTitle}>{msg}</p>}
        {description && (
          <p className={classes.emptyDataDescription}>{description}</p>
        )}
      </div>
      {handleClick && <ButtonAdd handleClick={handleClick} title={label} />}
      {children}
    </div>
  );
}

EmptyData.propTypes = {
  classes: PropTypes.object.isRequired,
  msg: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  handleClick: PropTypes.func,
  icon: PropTypes.node,
  description: PropTypes.string,
  height: PropTypes.string,
};

EmptyData.defaultProps = {
  children: "",
  label: "Create new",
  height: "100vh"
};

export default withStyles(styles)(EmptyData);
