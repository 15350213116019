import React, { useMemo, useReducer } from "react";
import { initialState, reducer } from "./reducer";
import ConfirmContext from "./ConfirmContext";

export const ConfirmContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const providerState = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <ConfirmContext.Provider value={providerState}>
      {children}
    </ConfirmContext.Provider>
  );
};
