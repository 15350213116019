import React from "react";

const ProductsAttributesIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M13.4302 15.75H4.4002C3.2802 15.75 2.2602 15.17 1.6902 14.2C1.1202 13.23 1.1002 12.07 1.6402 11.08L6.1502 2.87C6.7102 1.85 7.7402 1.23999 8.9102 1.23999C10.0802 1.23999 11.1102 1.85 11.6702 2.87L16.1902 11.08C16.7302 12.06 16.7102 13.23 16.1402 14.2C15.5702 15.17 14.5502 15.75 13.4302 15.75ZM8.9102 2.75C8.3002 2.75 7.7602 3.07001 7.4702 3.60001L2.9602 11.81C2.6802 12.33 2.6902 12.93 2.9802 13.44C3.2802 13.95 3.8102 14.25 4.4002 14.25H13.4302C14.0202 14.25 14.5502 13.95 14.8502 13.44C15.1502 12.93 15.1602 12.33 14.8702 11.81L10.3502 3.60001C10.0602 3.07001 9.5202 2.75 8.9102 2.75Z"
      fill="#4E5057"
    />
    <path
      d="M15.5 22.75C11.5 22.75 8.25 19.5 8.25 15.5C8.25 15.32 8.26 15.14 8.27 14.96C8.29 14.56 8.62 14.25 9.02 14.25H13.43C14.02 14.25 14.55 13.95 14.85 13.44C15.15 12.93 15.16 12.33 14.87 11.81L13.59 9.48001C13.47 9.27001 13.47 9.01999 13.57 8.79999C13.67 8.57999 13.87 8.43 14.1 8.38C14.55 8.29 15.02 8.25 15.5 8.25C19.5 8.25 22.75 11.5 22.75 15.5C22.75 19.5 19.5 22.75 15.5 22.75ZM9.76 15.75C9.89 18.81 12.42 21.25 15.5 21.25C18.67 21.25 21.25 18.67 21.25 15.5C21.25 12.33 18.67 9.75 15.5 9.75C15.48 9.75 15.47 9.75 15.45 9.75L16.19 11.09C16.73 12.07 16.71 13.24 16.14 14.21C15.57 15.18 14.56 15.76 13.43 15.76H9.76V15.75Z"
      fill="#4E5057"
    />
  </svg>
);

export default ProductsAttributesIcon;
