import React from "react";

const LampIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M14.77 19.75H9.26C8.82 19.75 8.25999 19.7 7.89 19.32C7.52999 18.95 7.54 18.49 7.55 18.18V17.29C5.03 15.61 3.37 12.69 3.37 9.91C3.37 7.28 4.55 4.82 6.6 3.17C8.64999 1.52 11.34 0.900001 13.97 1.48C16.47 2.03 18.63 3.7 19.75 5.95C21.92 10.32 19.83 15.05 16.49 17.3V18.05C16.5 18.34 16.51 18.87 16.1 19.29C15.79 19.59 15.35 19.75 14.77 19.75ZM9.04 18.24C9.09 18.24 9.16 18.25 9.25 18.25H14.77C14.87 18.25 14.94 18.24 14.98 18.23C14.98 18.2 14.98 18.16 14.98 18.13V16.87C14.98 16.61 15.12 16.36 15.34 16.23C18.3 14.44 20.26 10.35 18.39 6.6C17.47 4.75 15.69 3.38 13.63 2.93C11.45 2.45 9.22 2.96 7.52 4.33C5.82 5.7 4.85 7.73 4.85 9.91C4.85 12.69 6.77 15.09 8.67 16.24C8.9 16.38 9.02999 16.62 9.02999 16.88V18.23C9.04 18.23 9.04 18.23 9.04 18.24Z"
      fill="#292D32"
    />
    <path
      d="M15.5 22.75C15.43 22.75 15.36 22.74 15.29 22.72C13.13 22.1 10.86 22.1 8.70001 22.72C8.30001 22.83 7.89001 22.6 7.77001 22.2C7.65001 21.8 7.89001 21.39 8.29001 21.27C10.71 20.58 13.28 20.58 15.7 21.27C16.1 21.38 16.33 21.8 16.22 22.2C16.13 22.54 15.83 22.75 15.5 22.75Z"
      fill="#292D32"
    />
  </svg>
);

export default LampIcon;
