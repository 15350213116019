import React from "react";

const TagsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M12.07 21.9801C10.66 21.9801 9.24003 21.4401 8.17003 20.3701L3.64003 15.8401C2.54003 14.7401 1.96003 13.2201 2.03003 11.6701L2.27003 6.67005C2.38003 4.28005 4.27003 2.39005 6.67003 2.27005L11.67 2.03005C13.22 1.97005 14.74 2.54005 15.84 3.64005L20.37 8.17005C22.52 10.3201 22.52 13.8301 20.37 15.9801L15.98 20.3701C14.9 21.4401 13.49 21.9801 12.07 21.9801ZM4.70003 14.7701L9.23003 19.3001C9.99003 20.0601 11 20.4801 12.07 20.4801C13.14 20.4801 14.15 20.0601 14.91 19.3001L19.3 14.9101C20.06 14.1501 20.48 13.1401 20.48 12.0701C20.48 11.0001 20.06 9.99005 19.3 9.23005L14.77 4.70005C13.97 3.90005 12.86 3.47005 11.74 3.53005L6.74003 3.77005C5.11003 3.84005 3.84003 5.11005 3.76003 6.73005L3.52003 11.7301C3.47003 12.8601 3.90003 13.9701 4.70003 14.7701Z"
      fill="#4E5057"
    />
    <path
      d="M9.5 12.75C7.71 12.75 6.25 11.29 6.25 9.5C6.25 7.71 7.71 6.25 9.5 6.25C11.29 6.25 12.75 7.71 12.75 9.5C12.75 11.29 11.29 12.75 9.5 12.75ZM9.5 7.75C8.54 7.75 7.75 8.54 7.75 9.5C7.75 10.46 8.54 11.25 9.5 11.25C10.46 11.25 11.25 10.46 11.25 9.5C11.25 8.54 10.46 7.75 9.5 7.75Z"
      fill="#4E5057"
    />
  </svg>
);

export default TagsIcon;
