import React from "react";

const AttributesIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M11.9999 22.25C11.1799 22.25 10.3599 21.99 9.6799 21.48L4.46991 17.58C3.49991 16.85 2.91992 15.69 2.91992 14.48V1.76001H21.0799V14.48C21.0799 15.69 20.4999 16.85 19.5299 17.58L14.3199 21.48C13.6399 21.99 12.8199 22.25 11.9999 22.25ZM4.41992 3.25002V14.47C4.41992 15.21 4.7799 15.92 5.3699 16.37L10.5799 20.27C11.4199 20.9 12.5899 20.9 13.4299 20.27L18.6399 16.37C19.2299 15.92 19.5899 15.21 19.5899 14.47V3.25002H4.41992Z"
      fill="#999999"
    />
    <path
      d="M22 3.25H2C1.59 3.25 1.25 2.91 1.25 2.5C1.25 2.09 1.59 1.75 2 1.75H22C22.41 1.75 22.75 2.09 22.75 2.5C22.75 2.91 22.41 3.25 22 3.25Z"
      fill="#999999"
    />
    <path
      d="M16 8.75H8C7.59 8.75 7.25 8.41 7.25 8C7.25 7.59 7.59 7.25 8 7.25H16C16.41 7.25 16.75 7.59 16.75 8C16.75 8.41 16.41 8.75 16 8.75Z"
      fill="#999999"
    />
    <path
      d="M16 13.75H8C7.59 13.75 7.25 13.41 7.25 13C7.25 12.59 7.59 12.25 8 12.25H16C16.41 12.25 16.75 12.59 16.75 13C16.75 13.41 16.41 13.75 16 13.75Z"
      fill="#999999"
    />
  </svg>
);

export default AttributesIcon;
