import React from "react";

const CalenderIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.74377 2.83789V5.79349"
      stroke="#8E8E8E"
      strokeWidth="1.4778"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.6254 2.83789V5.79349"
      stroke="#8E8E8E"
      strokeWidth="1.4778"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.31042 9.82227H21.0588"
      stroke="#8E8E8E"
      strokeWidth="1.4778"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.5514 9.24046V17.6147C21.5514 20.5703 20.0736 22.5407 16.6254 22.5407H8.74375C5.29555 22.5407 3.81775 20.5703 3.81775 17.6147V9.24046C3.81775 6.28485 5.29555 4.31445 8.74375 4.31445H16.6254C20.0736 4.31445 21.5514 6.28485 21.5514 9.24046Z"
      stroke="#8E8E8E"
      strokeWidth="1.4778"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.3246 14.3637H16.3334"
      stroke="#8E8E8E"
      strokeWidth="1.9704"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.3246 17.3188H16.3334"
      stroke="#8E8E8E"
      strokeWidth="1.9704"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6802 14.3637H12.689"
      stroke="#8E8E8E"
      strokeWidth="1.9704"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6802 17.3188H12.689"
      stroke="#8E8E8E"
      strokeWidth="1.9704"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.0337 14.3637H9.04255"
      stroke="#8E8E8E"
      strokeWidth="1.9704"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.0337 17.3188H9.04255"
      stroke="#8E8E8E"
      strokeWidth="1.9704"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CalenderIcon;
