import React from "react";

import QueueContext from "./QueueContext";

import queueReducer, { QUEUE_INITIAL_STATE } from "../reducers/queueReducer";

const QueueContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(queueReducer, QUEUE_INITIAL_STATE);

  const providerState = React.useMemo(() => ({ state, dispatch }), [state]);

  return (
    <QueueContext.Provider value={providerState}>
      {children}
    </QueueContext.Provider>
  );
};

export default QueueContextProvider;
