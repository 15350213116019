import React from "react";

const ArrowRightIcon = () => (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M9.37103 6.98193L5.79395 9.66652V13.6403C5.79395 14.3203 6.61561 14.6603 7.09728 14.1786L10.7664 10.5094C11.3544 9.92152 11.3544 8.96527 10.7664 8.37735L9.37103 6.98193Z"
      fill="#FF8042"
    />
    <path
      d="M5.79395 5.25364V9.66655L9.37103 6.98197L7.09728 4.70822C6.61561 4.23364 5.79395 4.57364 5.79395 5.25364Z"
      fill="#FF8042"
    />
  </svg>
);

export default ArrowRightIcon;
