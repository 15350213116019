import Fade from "@material-ui/core/Fade";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { PropTypes } from "prop-types";
import React from "react";

import { Header, Sidebar } from "components";

import Spinner from "components/Spinner/Spinner";
import styles from "../appStyles-jss";

class RightSidebarLayout extends React.Component {
  render() {
    const {
      classes,
      children,
      toggleDrawer,
      sidebarOpen,
      history,
      loadTransition,
      pageLoaded,
      mode,
      gradient,
      changeMode,
      place,
      handleOpenGuide,
      dataMenu
    } = this.props;
    return (
      <>
        <Header
          toggleDrawerOpen={toggleDrawer}
          margin={sidebarOpen}
          gradient={gradient}
          position="right-sidebar"
          changeMode={changeMode}
          mode={mode}
          title={place}
          history={history}
          openGuide={handleOpenGuide}
        />
        <main className={classNames(classes.content, !sidebarOpen ? classes.contentPaddingRight : "")} id="mainContent">
          <section className={classNames(classes.mainWrap, classes.sidebarLayout)}>
            { !pageLoaded && (<div className={classes.circularProgress}><Spinner /></div>) }
            <Fade
              in={pageLoaded}
              mountOnEnter
              unmountOnExit
              {...(pageLoaded ? { timeout: 700 } : {})}
            >
              <div className={!pageLoaded ? classes.hideApp : ""}>
                {/* Application content will load here */}
                { children }
              </div>
            </Fade>
          </section>
        </main>
        <Sidebar
          open={sidebarOpen}
          toggleDrawerOpen={toggleDrawer}
          loadTransition={loadTransition}
          dataMenu={dataMenu}
          leftSidebar={false}
        />
      </>
    );
  }
}

RightSidebarLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  loadTransition: PropTypes.func.isRequired,
  changeMode: PropTypes.func.isRequired,
  sidebarOpen: PropTypes.bool.isRequired,
  pageLoaded: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  gradient: PropTypes.bool.isRequired,
  deco: PropTypes.bool.isRequired,
  bgPosition: PropTypes.string.isRequired,
  place: PropTypes.string.isRequired,
  titleException: PropTypes.array,
  handleOpenGuide: PropTypes.func.isRequired,
  dataMenu: PropTypes.array.isRequired
};

RightSidebarLayout.defaultProps = {
  titleException: [/^\/?$/]
};

export default (withStyles(styles)(RightSidebarLayout));
