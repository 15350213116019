export const guideSliderData = {
  "/": {
    guideSteps: [
      {
        title: "Welcome to MeetusVR!",
        label:
          "This is your gateway to efficient organization management, data analysis, and more. Let’s embark on this exciting journey together!",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "Need Help?",
        label:
          "Find guidance by clicking the “Guide” icon in the top-left corner for any page. It’s your trusted companion for answers.",
        imgPath: "./images/need_help.png",
      },
      {
        title: "Get the Big Picture",
        label: "Your dashboard’s first page, the Organization Overview, offers a snapshot of key metrics, insights, and trends. It’s your executive briefing, providing quick insights into your organization’s health. Keep an eye on it!",
        imgPath: "./images/empower.png",
      },
    ],
  },
  "scan-qr": {
    guideSteps: [
      {
        title: "scan qr guide 1",
        label:
          "Here is some guide to scan qr code, now you can use the qr code to scan, now you can use the qr code to scan",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "scan qr guide 2",
        label:
          "Here is some guide to scan qr code, now you can use the qr code to scan, now you can use the qr code to scan",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "scan qr guide 3",
        label:
          "Here is some guide to scan qr code, now you can use the qr code to scan, now you can use the qr code to scan",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "scan qr guide 4",
        label:
          "Here is some guide to scan qr code, now you can use the qr code to scan, now you can use the qr code to scan",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "scan qr guide 5",
        label:
          "Here is some guide to scan qr code, now you can use the qr code to scan, now you can use the qr code to scan",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  "create-tag": {
    guideSteps: [
      {
        title: "Add New tag",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "Add New tag",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "Add New tag",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  tags: {
    guideSteps: [
      {
        title: "All Tags you can edit",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "All Tags you can edit",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "All Tags you can edit",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  "tags-tree": {
    guideSteps: [
      {
        title: "tags tree",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "tags tree",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "tags tree",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  "products-features": {
    guideSteps: [
      {
        title: "products features",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "products features",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "products features",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  "organization-info": {
    guideSteps: [
      {
        title: "organization info",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "organization info",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "organization info",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  "organization-site-map": {
    guideSteps: [
      {
        title: "organization site map",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "organization site map",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "organization site map",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  "upload-organization-images": {
    guideSteps: [
      {
        title: "upload organization images",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "upload organization images",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "upload organization images",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  "add-branch": {
    guideSteps: [
      {
        title: "add branch",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "add branch",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "add branch",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  "edit-branches": {
    guideSteps: [
      {
        title: "edit branches",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "edit branches",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "edit branches",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  "create-brand": {
    guideSteps: [
      {
        title: "create brand",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "create brand",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "create brand",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  brands: {
    guideSteps: [
      {
        title: "All brands",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "All brands",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "All brands",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  "create-product": {
    guideSteps: [
      {
        title: "Add new product",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "Add new product",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "Add new product",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  products: {
    guideSteps: [
      {
        title: "All products",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "All products",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "All products",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  "create-promotion": {
    guideSteps: [
      {
        title: "Add New promotion",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "Add New promotion",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "Add New promotion",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  promotions: {
    guideSteps: [
      {
        title: "All promotions",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "All promotions",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "All promotions",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  "points-configuration": {
    guideSteps: [
      {
        title: "points configuration",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "points configuration",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "points configuration",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  "control-points": {
    guideSteps: [
      {
        title: "control points",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "control points",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "control points",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  "create-control-points": {
    guideSteps: [
      {
        title: "How to create control point",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "How to create control point",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "How to create control point",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  "create-event": {
    guideSteps: [
      {
        title: "How to create event",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "How to create event",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "How to create event",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  events: {
    guideSteps: [
      {
        title: "you can see all events",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "you can see all events",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "you can see all events",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  "events/ask-for-event": {
    guideSteps: [
      {
        title: "ask people to host your event",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "ask people to host your event",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "ask people to host your event",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  "events/influencers-requests": {
    guideSteps: [
      {
        title: "influencer requests to host your event",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "influencer requests to host your event",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "influencer requests to host your event",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  "add-stores": {
    guideSteps: [
      {
        title: "add 360° stores",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "add 360° stores",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "add 360° stores",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  "edit-stores": {
    guideSteps: [
      {
        title: "All 360° stores",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "All 360° stores",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "All 360° stores",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  chat: {
    guideSteps: [
      {
        title: "chat",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "chat",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "chat",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  "video-calls": {
    guideSteps: [
      {
        title: "video calls",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "video calls",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "video calls",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  "booking-calls": {
    guideSteps: [
      {
        title: "booking calls",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "booking calls",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "booking calls",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  "video-calls/availability": {
    guideSteps: [
      {
        title: "availability",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "availability",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "availability",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  "video-calls/availability-info": {
    guideSteps: [
      {
        title: "availability info",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "availability info",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "availability info",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  "video-calls/reviews": {
    guideSteps: [
      {
        title: "reviews",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "reviews",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "reviews",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  "shared-items": {
    guideSteps: [
      {
        title: "shared items",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "shared items",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "shared items",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  "reviews-requests": {
    guideSteps: [
      {
        title: "Reviews requests",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "Reviews requests",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "Reviews requests",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  orders: {
    guideSteps: [
      {
        title: "Manage your orders",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "Manage your orders",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "Manage your orders",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  "return-orders": {
    guideSteps: [
      {
        title: "return orders",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "return orders",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "return orders",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  customers: {
    guideSteps: [
      {
        title: "customers",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "customers",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "customers",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  "influencers-requests": {
    guideSteps: [
      {
        title: "influencers requests",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "influencers requests",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "influencers requests",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  influencers: {
    guideSteps: [
      {
        title: "All influencers",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "All influencers",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "All influencers",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  "create-user": {
    guideSteps: [
      {
        title: "create user",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "create user",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "create user",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  employees: {
    guideSteps: [
      {
        title: "All employees",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "All employees",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "All employees",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
  "subscribed-users": {
    guideSteps: [
      {
        title: "subscribed users",
        label:
          "Here is some guide to use your dashboard. Click next to continue or click on outside to skip it",
        imgPath: "./images/LoginBg.png",
      },
      {
        title: "subscribed users",
        label:
          "Enjoy your eyes with dark/light mode, just switch the option in theme panel or on header",
        imgPath: "./images/guide/guide2.gif",
      },
      {
        title: "subscribed users",
        label: "Find any page quicker",
        imgPath: "./images/guide/guide4.gif",
      },
    ],
  },
};
