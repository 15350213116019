import React from "react";

const TickCircleIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    {...props}
  >
    <path
      d="M10.4144 2.49609C5.82272 2.49609 2.08105 6.23776 2.08105 10.8294C2.08105 15.4211 5.82272 19.1628 10.4144 19.1628C15.0061 19.1628 18.7477 15.4211 18.7477 10.8294C18.7477 6.23776 15.0061 2.49609 10.4144 2.49609ZM14.3977 8.91276L9.67272 13.6378C9.55605 13.7544 9.39772 13.8211 9.23105 13.8211C9.06439 13.8211 8.90605 13.7544 8.78939 13.6378L6.43105 11.2794C6.18939 11.0378 6.18939 10.6378 6.43105 10.3961C6.67272 10.1544 7.07272 10.1544 7.31439 10.3961L9.23105 12.3128L13.5144 8.02943C13.7561 7.78776 14.1561 7.78776 14.3977 8.02943C14.6394 8.27109 14.6394 8.66276 14.3977 8.91276Z"
      fill="currentColor"
    />
  </svg>
);

export default TickCircleIcon;
