import React from "react";

const QrCodeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <path
      d="M2.5 10.25C2.09 10.25 1.75 9.91 1.75 9.5V7C1.75 4.1 4.11 1.75 7 1.75H9.5C9.91 1.75 10.25 2.09 10.25 2.5C10.25 2.91 9.91 3.25 9.5 3.25H7C4.93 3.25 3.25 4.93 3.25 7V9.5C3.25 9.91 2.91 10.25 2.5 10.25Z"
      fill="white"
    />
    <path
      d="M22.5 10.25C22.09 10.25 21.75 9.91 21.75 9.5V7C21.75 4.93 20.07 3.25 18 3.25H15.5C15.09 3.25 14.75 2.91 14.75 2.5C14.75 2.09 15.09 1.75 15.5 1.75H18C20.89 1.75 23.25 4.1 23.25 7V9.5C23.25 9.91 22.91 10.25 22.5 10.25Z"
      fill="white"
    />
    <path
      d="M18 23.25H16.5C16.09 23.25 15.75 22.91 15.75 22.5C15.75 22.09 16.09 21.75 16.5 21.75H18C20.07 21.75 21.75 20.07 21.75 18V16.5C21.75 16.09 22.09 15.75 22.5 15.75C22.91 15.75 23.25 16.09 23.25 16.5V18C23.25 20.9 20.89 23.25 18 23.25Z"
      fill="white"
    />
    <path
      d="M9.5 23.25H7C4.11 23.25 1.75 20.9 1.75 18V15.5C1.75 15.09 2.09 14.75 2.5 14.75C2.91 14.75 3.25 15.09 3.25 15.5V18C3.25 20.07 4.93 21.75 7 21.75H9.5C9.91 21.75 10.25 22.09 10.25 22.5C10.25 22.91 9.91 23.25 9.5 23.25Z"
      fill="white"
    />
    <path
      d="M9.5 11.75H7.5C6.09 11.75 5.25 10.91 5.25 9.5V7.5C5.25 6.09 6.09 5.25 7.5 5.25H9.5C10.91 5.25 11.75 6.09 11.75 7.5V9.5C11.75 10.91 10.91 11.75 9.5 11.75ZM7.5 6.75C6.91 6.75 6.75 6.91 6.75 7.5V9.5C6.75 10.09 6.91 10.25 7.5 10.25H9.5C10.09 10.25 10.25 10.09 10.25 9.5V7.5C10.25 6.91 10.09 6.75 9.5 6.75H7.5Z"
      fill="white"
    />
    <path
      d="M17.5 11.75H15.5C14.09 11.75 13.25 10.91 13.25 9.5V7.5C13.25 6.09 14.09 5.25 15.5 5.25H17.5C18.91 5.25 19.75 6.09 19.75 7.5V9.5C19.75 10.91 18.91 11.75 17.5 11.75ZM15.5 6.75C14.91 6.75 14.75 6.91 14.75 7.5V9.5C14.75 10.09 14.91 10.25 15.5 10.25H17.5C18.09 10.25 18.25 10.09 18.25 9.5V7.5C18.25 6.91 18.09 6.75 17.5 6.75H15.5Z"
      fill="white"
    />
    <path
      d="M9.5 19.75H7.5C6.09 19.75 5.25 18.91 5.25 17.5V15.5C5.25 14.09 6.09 13.25 7.5 13.25H9.5C10.91 13.25 11.75 14.09 11.75 15.5V17.5C11.75 18.91 10.91 19.75 9.5 19.75ZM7.5 14.75C6.91 14.75 6.75 14.91 6.75 15.5V17.5C6.75 18.09 6.91 18.25 7.5 18.25H9.5C10.09 18.25 10.25 18.09 10.25 17.5V15.5C10.25 14.91 10.09 14.75 9.5 14.75H7.5Z"
      fill="white"
    />
    <path
      d="M17.5 19.75H15.5C14.09 19.75 13.25 18.91 13.25 17.5V15.5C13.25 14.09 14.09 13.25 15.5 13.25H17.5C18.91 13.25 19.75 14.09 19.75 15.5V17.5C19.75 18.91 18.91 19.75 17.5 19.75ZM15.5 14.75C14.91 14.75 14.75 14.91 14.75 15.5V17.5C14.75 18.09 14.91 18.25 15.5 18.25H17.5C18.09 18.25 18.25 18.09 18.25 17.5V15.5C18.25 14.91 18.09 14.75 17.5 14.75H15.5Z"
      fill="white"
    />
  </svg>
);

export default QrCodeIcon;
