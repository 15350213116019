import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import Badge from "@material-ui/core/Badge";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ChatIcon from "api/icons/DashboardIcons/ChatIcon";

import { ADD_NOTIFICATION } from "actions/actionConstants";
import ChatAvatar from "./ChatAvatar/ChatAvatar";

import audio from "../../../assets/notification.wav";
import styles from "../header-jss";

const returnUnReadMessages = (rooms) => rooms?.filter(item => item?.waitingResponse);

const notificationAudio = new Audio(audio);

const Chat = ({
  classes,
  addNotification,
  dark,
  anchorEl,
  openMenu,
  handleMenu,
  handleClose,
  changeIconsColor,
  iconClassName,
}) => {
  const [unReadMessages, setUnreadMessages] = useState([]);

  return (
    <>
      <IconButton
        aria-haspopup="true"
        color="inherit"
        className={classNames(
          classes.notifIcon,
          dark ? classes.dark : classes.light,
          // changeIconsColor ? classes.iconColor : ""
        )}
        onClick={handleMenu}
      >
        <Badge
          className={classNames(classes.badge, classes.badgeColor)}
          //   badgeContent={state?.numberOfUnreadMessages}
          color="red"
        >
          <div className={classes.iconContainer}>
            {!unReadMessages ? <div className={classNames(classes.unRead, iconClassName || "")}><ChatIcon /></div> : <div className={iconClassName || ""}><ChatIcon /></div>}
          </div>
        </Badge>
      </IconButton>
      <Menu
        id="menu-chat"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.notifMenu}
        PaperProps={{
          style: {
            width: 350,
          },
        }}
        open={openMenu === "chat"}
        onClose={handleClose}

      >
        <>
          {unReadMessages?.length === 0 ? (
            <MenuItem>
              No unread messages
            </MenuItem>
          ) : (
            unReadMessages?.map((room, i) => (
              <>
                <Link className={classes.chatLink} to={`/chat/${room?._id}`}>
                  <MenuItem className={room?.waitingResponse ? classes.unReadMessage : ""} onClick={handleClose}>
                    <div className={classes.chatItem}>
                      <ChatAvatar name={room?.fname} />
                      <div className={classes.chatInfo}>
                        <h5>{room?.fname?.length > 25 ? `${room?.fname?.substring(0, 25)}...` : room?.fname}</h5>
                      </div>
                    </div>
                  </MenuItem>
                </Link>
                {Number(i + 1) !== unReadMessages?.length && <Divider className={classes.divider} variant="inset" />}
              </>
            ))
          )}
        </>
      </Menu>
    </>
  );
};

const styledChatContainer = withStyles(styles)(Chat);

export default connect(
  (state) => ({
    orgId: state.getIn(["users", "user", "info", "organization_id"]),
  }),
  (dispatch) => ({
    addNotification: (message) => dispatch({ type: ADD_NOTIFICATION, message }),
  })
)(styledChatContainer);
