import * as types from "./actionConstants";

export const initAction = data => ({ type: types.INIT, data });
export const clearAction = { type: types.CLEAR };

/**
 * returns a function that dispatches a SUBMIT_REQUEST action
 * @param {*} dispatch
 */
export const getSubmitDispatcher = dispatch => (
  formName,
  formUrl,
  formValues,
  multipart, // unused, still here for backward compatibility
  imagesProperties = {},
  stringifyFields = [],
  requestMethod
) =>
  new Promise((resolve, reject) =>
    dispatch({
      type: types.SUBMIT_REQUEST,
      formName,
      formUrl,
      formValues,
      resolve,
      reject,
      imagesProperties,
      stringifyFields,
      requestMethod
    })
  );
