import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MobileStepper from "@material-ui/core/MobileStepper";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { guideSliderData } from "api/dummy/guideData";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import SwipeableViews from "react-swipeable-views";
import styles from "./guide-jss";

const Transition = React.forwardRef(function Transition(props, ref) { // eslint-disable-line
  return <Slide direction="up" ref={ref} {...props} />;
});

class GuideSlider extends React.Component { // eslint-disable-line
  state = {
    activeStepSwipe: 0,
  };

  handleNextSwipe = () => {
    this.setState(prevState => ({
      activeStepSwipe: prevState.activeStepSwipe + 1,
    }));
  };

  handleBackSwipe = () => {
    this.setState(prevState => ({
      activeStepSwipe: prevState.activeStepSwipe - 1,
    }));
  };

  handleStepChangeSwipe = activeStepSwipe => {
    this.setState({ activeStepSwipe });
  };

  handleClose = () => {
    const { closeGuide } = this.props;
    closeGuide();
    this.setState({ activeStepSwipe: 0 });
  };

  guideData = () => {
    const path = this.props.location.pathname;
    const steps = guideSliderData[path.substr(1).toLowerCase()];
    if (steps) {
      return steps.guideSteps;
    }
    return guideSliderData["/"].guideSteps;
  };

  render() {
    const {
      classes,
      theme,
      openGuide,
    } = this.props;
    const { activeStepSwipe } = this.state;
    const guideData = this.guideData();
    const maxStepsSwipe = guideData.length;
    return (
      <Dialog
        TransitionComponent={Transition}
        keepMounted
        open={openGuide}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.root}
      >
        <DialogContent className={classes.rootContent}>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStepSwipe}
            onChangeIndex={this.handleStepChangeSwipe}
            enableMouseEvents
            className={classes.guideWrap}
          >
            {guideData.map((step, index) => (
              <div className={classes.figure} key={index.toString()}>
                <img key={step.label} className={classes.img} src={step.imgPath} alt={step.title} />
              </div>
            ))}
          </SwipeableViews>
          <article className={classes.text}>
            <Typography className={classes.title} variant="h6">{guideData[activeStepSwipe].title}</Typography>
            <Typography className={classes.label}>{guideData[activeStepSwipe].label}</Typography>
          </article>
          <MobileStepper
            variant="progress"
            steps={maxStepsSwipe}
            position="static"
            activeStep={activeStepSwipe}
            className={classes.mobileStepper}
            nextButton={
              activeStepSwipe === maxStepsSwipe - 1 ? (
                <Button size="small" color="primary" onClick={this.handleClose}>
                  Done
                  {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </Button>
              ) : (
                <Button size="small" onClick={this.handleNextSwipe}>
                  Next
                  {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </Button>
              )
            }
            backButton={(
              <Button size="small" onClick={this.handleBackSwipe} disabled={activeStepSwipe === 0}>
                {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                Back
              </Button>
            )}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

GuideSlider.propTypes = {
  openGuide: PropTypes.bool.isRequired,
  closeGuide: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles, { withTheme: true })(GuideSlider));
