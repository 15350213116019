import React from "react";

const AdditionalFeaturedIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_3732_1132)">
      <path
        d="M16.25 13.3333C16.25 13.7942 15.8775 14.1667 15.4167 14.1667H13.75C13.2892 14.1667 12.9167 13.7942 12.9167 13.3333C12.9167 12.8725 13.2892 12.5 13.75 12.5H15.4167C15.8775 12.5 16.25 12.8725 16.25 13.3333ZM20 12.5V16.6667C20 18.505 18.505 20 16.6667 20H3.33333C1.495 20 0 18.505 0 16.6667V12.5C0 10.6617 1.495 9.16667 3.33333 9.16667H4.16667V3.33333C4.16667 1.495 5.66167 0 7.5 0H12.5C14.3383 0 15.8333 1.495 15.8333 3.33333V9.16667H16.6667C18.505 9.16667 20 10.6617 20 12.5ZM5.83333 9.16667H14.1667V3.33333C14.1667 2.41417 13.4192 1.66667 12.5 1.66667H7.5C6.58083 1.66667 5.83333 2.41417 5.83333 3.33333V9.16667ZM3.33333 18.3333H9.16667V10.8333H3.33333C2.41417 10.8333 1.66667 11.5808 1.66667 12.5V16.6667C1.66667 17.5858 2.41417 18.3333 3.33333 18.3333ZM18.3333 12.5C18.3333 11.5808 17.5858 10.8333 16.6667 10.8333H10.8333V18.3333H16.6667C17.5858 18.3333 18.3333 17.5858 18.3333 16.6667V12.5ZM6.25 12.5H4.58333C4.1225 12.5 3.75 12.8725 3.75 13.3333C3.75 13.7942 4.1225 14.1667 4.58333 14.1667H6.25C6.71083 14.1667 7.08333 13.7942 7.08333 13.3333C7.08333 12.8725 6.71083 12.5 6.25 12.5ZM11.6667 4.16667C11.6667 3.70583 11.2942 3.33333 10.8333 3.33333H9.16667C8.70583 3.33333 8.33333 3.70583 8.33333 4.16667C8.33333 4.6275 8.70583 5 9.16667 5H10.8333C11.2942 5 11.6667 4.6275 11.6667 4.16667Z"
        fill="#999999"
      />
    </g>
    <defs>
      <clipPath id="clip0_3732_1132">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AdditionalFeaturedIcon;
