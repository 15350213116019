const styles = (theme) => ({
  root: {
    padding: 0,
    overflow: "hidden",
  },
  rootContent: {
    padding: 0,
    paddingTop: "0 !important",
    overflow: "hidden",
    width: "600px",
    [theme.breakpoints.down("xs")]: {
      width: "350px",
    },
  },
  guideWrap: {
    "& > div > div": {
      overflow: "hidden !important",
    },
  },
  header: {
    textAlign: "center",
    height: 50,
    paddingLeft: theme.spacing(4),
    marginBottom: 20,
    backgroundColor: theme.palette.background.default,
  },
  img: {
    width: "auto",
    display: "block",
    height: "auto",
    overflow: "hidden",
    margin: "0 auto",
    objectFit: "cover",
  },
  figure: {
    width: "100%",
    position: "relative",
    borderRadius: "0 0 35% 35%",
    overflow: "hidden",
    height: 300,
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      height: 255,
      width: "150%",
      left: "-25%",
      borderRadius: "0 0 50% 50%",
    },
  },
  mobileStepper: {
    margin: "0 auto",
    textAlign: "center",
    width: "600px",
    [theme.breakpoints.down("xs")]: {
      width: "350px",
    },
  },
  text: {
    padding: theme.spacing(3),
    "& h2": {
      marginBottom: theme.spacing(2),
    },
  },
  title: {
    fontWeight: 600,
    marginBottom: 7,
  },
  label: {
    fontSize: 14,
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
});

export default styles;
