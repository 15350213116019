import React from "react";

const AdminUserIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M12 22.63C11.33 22.63 10.65 22.48 10.12 22.17L4.61999 19C2.37999 17.49 2.23999 17.26 2.23999 14.89V9.10999C2.23999 6.73999 2.36999 6.50999 4.56999 5.01999L10.11 1.81999C11.16 1.20999 12.81 1.20999 13.86 1.81999L19.38 4.99999C21.62 6.50999 21.76 6.73999 21.76 9.10999V14.88C21.76 17.25 21.63 17.48 19.43 18.97L13.89 22.17C13.35 22.48 12.67 22.63 12 22.63ZM12 2.86999C11.58 2.86999 11.17 2.94999 10.88 3.11999L5.37999 6.29999C3.74999 7.39999 3.74999 7.39999 3.74999 9.10999V14.88C3.74999 16.59 3.74999 16.59 5.41999 17.72L10.88 20.87C11.47 21.21 12.54 21.21 13.13 20.87L18.63 17.69C20.25 16.59 20.25 16.59 20.25 14.88V9.10999C20.25 7.39999 20.25 7.39999 18.58 6.26999L13.12 3.11999C12.83 2.94999 12.42 2.86999 12 2.86999Z"
      fill="#4E5057"
    />
    <path
      d="M12 15.75C9.93 15.75 8.25 14.07 8.25 12C8.25 9.93 9.93 8.25 12 8.25C14.07 8.25 15.75 9.93 15.75 12C15.75 14.07 14.07 15.75 12 15.75ZM12 9.75C10.76 9.75 9.75 10.76 9.75 12C9.75 13.24 10.76 14.25 12 14.25C13.24 14.25 14.25 13.24 14.25 12C14.25 10.76 13.24 9.75 12 9.75Z"
      fill="#4E5057"
    />
  </svg>
);

export default AdminUserIcon;
