const styles = (theme) => ({
  card: {
    height: 220,
    padding: 16,
    display: "flex",
    flexDirection: "column",
    gap: 8,
    boxShadow: "none",
    border: `1px solid ${
      theme.palette.type === "dark" ? "#353232" : "#0000003b"
    }`,
  },
  cardHeader: {
    display: "flex",
    alignItems: "Center",
    justifyContent: "space-between",
    padding: 0,
    "& >div": {
      alignSlef: "unset",
      marginTop: 0,
      marginRight: 0,
    },
  },
  cardTitle: {
    fontSize: 16,
    color: "#2C2C34",
    fontWeight: 600,
  },
  cardActions: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    "& button": {
      background: "none",
      padding: 0,
    },
    "& svg": {
      fill: "transparent",
    },
  },
  cardContent: {
    flex: 1,
    padding: 8,
    borderRadius: 8,
    backgroundColor: "#F5F5F5",
    "& ul": {
      display: "flex",
      flexDirection: "column",
      gap: 8,
      listStyle: "none",
      "& li": {
        display: "flex",
        flexDirection: "column",
        gap: 8,
        fontSize: 14,
        color: "#33383F",
        fontWeight: 600,
        "& span": {
          color: "#8E8E8E",
          fontWeight: 500,
        },
      },
    },
  }
});

export default styles;
