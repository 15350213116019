import axios from "axios/index";
import urlUtils from "./url";
/**
 * Validates an object's data by checking for missing or invalid fields.
 *
 * @param {Object} data - The object containing the data to be validated.
 * @param {Array<string>} [ignoredKeys=[]] - An optional array of keys to ignore during validation.
 * @returns {(Set<string>|false)} A Set containing the keys of missing or invalid fields,
 *   or `false` if all fields are valid.
 *
 * @example
 * // Example usage:
 * const data = {
 *   name: 'Ghazi',
 *   age: 25,
 *   email: '',
 *   address: '',
 *   hobbies: []
 * };
 *
 * const ignoredKeys = ['email', hobbies];
 *
 * const hasInvalidValues = validObjectData(data, ignoredKeys);
 *
 * if (hasInvalidValues) {
 *   console.log('Invalid fields:', [...isValid]);
 * } else {
 *.  console.log('All fields are valid.');
 * }
 */
export const validObjectData = (data, ignoredKeys = []) => {
  const ignoredSet = new Set(ignoredKeys);
  const requiredFields = new Set("");
  for (const key in data) {
    if (!ignoredSet.has(key)) {
      const value = data[key];

      if (!value) {
        requiredFields.add(key);
      }

      if (typeof value === "string" && value.length === 0) {
        requiredFields.add(key);
      }

      if (typeof value === "number" && +value === 0) {
        requiredFields.add(key);
      }

      if (
        typeof value === "object"
          && value !== null
          && Object?.keys(value).length === 0
      ) {
        requiredFields.add(key);
      }

      if (Array.isArray(value) && value.length === 0) {
        requiredFields.add(key);
      }
    }
  }

  return requiredFields.size ? requiredFields : false;
};

export const properApiUrl = (param) => urlUtils.getProperApiUrl(param);
export const properImageUrl = (param) => urlUtils.getProperImageUrl(param);

export const findObjectById = (array, targetId) => {
  for (const element of array) {
    if (element.id === targetId) {
      return element;
    }
  }
  return null; // Object with targetId not found
};

export const appendObjToFormData = (formData, object, name) => {
  const blob = new Blob([JSON.stringify(object)], {
    type: "application/json",
  });
  return formData.append(name, blob);
};

export const appendImageToFormData = (formData, image, name, multi = false) => {
  const blob = new Blob([image], { type: "image/jpeg" });
  if (multi) {
    image.forEach((img) => formData.append(name, blob));
  }
  return formData.append(name, blob);
};

export const imageUpload = async (image, setProgressState, orgId) => {
  setProgressState(0);
  try {
    const imageFormData = new FormData();
    imageFormData.append("file", image, "test.jpg");
    const { data } = await axios.post(
      properApiUrl(`/files?org_id=${orgId}`),
      imageFormData,
      {
        onUploadProgress: (progressEvent) => {
          let percentComplete = progressEvent.loaded / progressEvent.total;
          percentComplete = parseInt(percentComplete * 100);
          setProgressState(percentComplete);
        },
      }
    );
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const replaceSpacesWith = (replaceWith, inputString) => {
  const outPutString = inputString.replace(/\s+/g, replaceWith);
  return outPutString;
};

export const numberToK = (number) => {
  if (number >= 1000) {
    const kValue = number / 1000;
    const roundedK = Math.round(kValue * 10) / 10;
    return `${roundedK}K`;
  }
  return number?.toString();
};

export const goToTop = () => {
  document.querySelector("#mainContent").scrollTo({ top: 0, left: 0, behavior: "smooth" });
};

export const gendersMapping = {
  MALE: "Male",
  FEMALE: "Female",
};

export const genderList = [
  { id: 0, name: "Male", value: "MALE" },
  { id: 1, name: "Female", value: "FEMALE" },
];

export const attributeTypes = {
  STRING: "Text",
  COLOR: "Color",
  IMG_SWATCH: "Color Or Image",
};
