const styles = (theme) => ({
  pagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "auto",
    overflow: "auto",
    width: "100%",
    flexShrink: 0,
    "&::-webkit-scrollbar": {
      width: 4,
      height: 4,
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      gap: "16px",
    },
  },
  formControl: {
    "& .MuiSelect-select": {
      padding: "8px 28px",
      display: "flex",
      gap: 20,
    },
    "& .MuiInputBase-root": {
      border: "none !important",
    },
  },
  selectContainer: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    flexShrink: 0,
  },
  selectLabel: {
    color: "#8B909A",
    fontWeight: "500",
    fontSize: "15px",
    flexShrink: 0,
  },
  dropdownIcon: {
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)",
  },
  paginationContainer: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },
  pagButton: {
    minWidth: "unset",
    padding: "unset",
    width: "28px",
    height: "28px",
    display: "grid",
    placeItems: "center",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    borderRadius: "50%",
    backgroundColor: "#F1F2F6",
    color: "#8B909A",
    fontSize: "13px",
    fontWeight: 400,
    "&:hover": {
      background: theme.palette.uniqueColor,
      color: "#FFF",
    },
    "&:disabled": {
      cursor: "not-allowed",
      opacity: 1,
    },
    "& svg": {
      width: 16,
      height: 16,
    },
  },
  activePagButton: {
    color: "#FFF",
    fontSize: "13px",
    fontWeight: "400",
    background: theme.palette.uniqueColor,
    pointerEvents: "none",
  },
  ul: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  flexEnd: {
    justifyContent: "flex-end",
  },
});

export default styles;
