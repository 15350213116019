/* eslint-disable no-unused-expressions */
import React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { REMOVE_CONFIRMATION } from "../../actions/actionConstants";

const ConfirmationProvider = ({ confirmation, remove }) => {
  const BodyWrapper = confirmation?.get("body") ? DialogContentText : React.Fragment;
  return (
    Boolean(confirmation) && (
      <Dialog
        open={confirmation}
        onClose={() => confirmation.get("cancelCallback")?.(remove)}
        fullWidth
      >
        <DialogTitle id="dialog-title">{confirmation.get("title")}</DialogTitle>
        <DialogContent>
          <BodyWrapper>{confirmation.get("body")}</BodyWrapper>
        </DialogContent>
        <DialogActions>
          <>
            <Button
              type="button"
              onClick={() => {
                remove();
                confirmation.get("cancelCallback")?.(remove);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                remove();
                confirmation.get("confirmCallback")?.();
              }}
            >
              Confirm
            </Button>
          </>
        </DialogActions>
      </Dialog>
    )
  );
};

export default connect(
  state => ({
    confirmation: state.getIn(["confirmation", "confirmation"])
  }),
  dispatch => ({
    remove: () => dispatch({ type: REMOVE_CONFIRMATION })
  })
)(ConfirmationProvider);
