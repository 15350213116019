import React from "react";

const HintIcon = () => (
  <svg
    width="23"
    height="27"
    viewBox="0 0 23 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.8585 11.0777C19.8585 6.38203 15.9864 2.5907 11.261 2.72258C6.77235 2.84784 3.16314 6.54657 3.1417 11.0369C3.13079 13.3213 4.03651 15.3941 5.51225 16.9089C6.86897 18.3016 7.64196 20.1599 7.64196 22.1042V22.744H15.3582V22.1042C15.3582 20.1692 16.1144 18.3098 17.469 16.9282C18.947 15.4207 19.8585 13.3558 19.8585 11.0777Z"
      fill="url(#paint0_radial_629_121772)"
    />
    <path
      d="M11.5 18.8965C15.8184 18.8965 19.3191 15.3958 19.3191 11.0774C19.3191 6.75903 15.8184 3.2583 11.5 3.2583C7.18164 3.2583 3.68091 6.75903 3.68091 11.0774C3.68091 15.3958 7.18164 18.8965 11.5 18.8965Z"
      fill="url(#paint1_linear_629_121772)"
    />
    <path
      d="M11.5 9.35797C14.3011 9.35797 16.5719 8.0381 16.5719 6.40994C16.5719 4.78179 14.3011 3.46191 11.5 3.46191C8.69895 3.46191 6.42822 4.78179 6.42822 6.40994C6.42822 8.0381 8.69895 9.35797 11.5 9.35797Z"
      fill="url(#paint2_linear_629_121772)"
    />
    <path
      d="M15.2389 3.60059C15.8026 4.72569 16.1204 5.99543 16.1204 7.33956C16.1204 11.9558 12.3782 15.698 7.76192 15.698C6.4178 15.698 5.14806 15.3803 4.02295 14.8165C5.39529 17.5555 8.2278 19.436 11.4999 19.436C16.1161 19.436 19.8583 15.6938 19.8583 11.0775C19.8583 7.80539 17.9778 4.97293 15.2389 3.60059Z"
      fill="url(#paint3_linear_629_121772)"
    />
    <path
      d="M11.5 20.6519C14.3011 20.6519 16.5719 18.3812 16.5719 15.5801C16.5719 12.779 14.3011 10.5083 11.5 10.5083C8.69895 10.5083 6.42822 12.779 6.42822 15.5801C6.42822 18.3812 8.69895 20.6519 11.5 20.6519Z"
      fill="url(#paint4_radial_629_121772)"
    />
    <path
      d="M14.543 26.3099C12.6188 27.2303 10.3813 27.2303 8.45702 26.3099C7.95715 26.0708 7.63892 25.5659 7.63892 25.0117V20.8403H15.3611V25.0117C15.3611 25.5659 15.0429 26.0708 14.543 26.3099Z"
      fill="url(#paint5_linear_629_121772)"
    />
    <path
      d="M7.63892 21.7384L8.40455 22.5041H15.3611V21.7319H7.63892V21.7384Z"
      fill="url(#paint6_linear_629_121772)"
    />
    <path
      d="M7.63892 23.6413L8.40455 24.4069H15.3611V23.6348H7.63892V23.6413Z"
      fill="url(#paint7_linear_629_121772)"
    />
    <path
      d="M7.70776 25.4492C7.72073 25.4898 7.73545 25.5296 7.75186 25.5687L8.40452 26.2214H14.7004C14.9836 26.0392 15.1913 25.765 15.2922 25.4492H7.70776Z"
      fill="url(#paint8_linear_629_121772)"
    />
    <path
      d="M13.6753 15.5801H13.5184C12.897 15.5801 12.3914 16.0857 12.3914 16.7071V17.0702H11.5001H10.6087V16.7071C10.6087 16.0857 10.1031 15.5801 9.48163 15.5801H9.32482C8.70334 15.5801 8.19775 16.0857 8.19775 16.7071C8.19775 17.3286 8.70334 17.8342 9.32482 17.8342H9.84465V20.75H10.6087V17.8342H11.5001H12.3914V20.75H13.1554V17.8342H13.6753C14.2967 17.8342 14.8023 17.3286 14.8023 16.7071C14.8023 16.0857 14.2967 15.5801 13.6753 15.5801ZM9.8447 17.0702H9.32487C9.1247 17.0702 8.96185 16.9073 8.96185 16.7071C8.96185 16.507 9.1247 16.3441 9.32487 16.3441H9.48168C9.68185 16.3441 9.8447 16.507 9.8447 16.7071V17.0702ZM13.6753 17.0702H13.1554V16.7071C13.1554 16.507 13.3183 16.3441 13.5184 16.3441H13.6753C13.8754 16.3441 14.0383 16.507 14.0383 16.7071C14.0383 16.9073 13.8755 17.0702 13.6753 17.0702Z"
      fill="url(#paint9_linear_629_121772)"
    />
    <path
      d="M13.6753 15.5801H13.5184C12.897 15.5801 12.3914 16.0857 12.3914 16.7071V17.0702H11.5001H10.6087V16.7071C10.6087 16.0857 10.1031 15.5801 9.48163 15.5801H9.32482C8.70334 15.5801 8.19775 16.0857 8.19775 16.7071C8.19775 17.3286 8.70334 17.8342 9.32482 17.8342H9.84465V20.75H10.6087V17.8342H11.5001H12.3914V20.75H13.1554V17.8342H13.6753C14.2967 17.8342 14.8023 17.3286 14.8023 16.7071C14.8023 16.0857 14.2967 15.5801 13.6753 15.5801ZM9.8447 17.0702H9.32487C9.1247 17.0702 8.96185 16.9073 8.96185 16.7071C8.96185 16.507 9.1247 16.3441 9.32487 16.3441H9.48168C9.68185 16.3441 9.8447 16.507 9.8447 16.7071V17.0702ZM13.6753 17.0702H13.1554V16.7071C13.1554 16.507 13.3183 16.3441 13.5184 16.3441H13.6753C13.8754 16.3441 14.0383 16.507 14.0383 16.7071C14.0383 16.9073 13.8755 17.0702 13.6753 17.0702Z"
      fill="url(#paint10_linear_629_121772)"
    />
    <path
      d="M15.1502 21.7686H7.84964C7.48392 21.7686 7.1875 21.4721 7.1875 21.1065C7.1875 20.7408 7.48397 20.4443 7.84964 20.4443H15.1502C15.5159 20.4443 15.8124 20.7408 15.8124 21.1065C15.8124 21.4721 15.5159 21.7686 15.1502 21.7686Z"
      fill="url(#paint11_linear_629_121772)"
    />
    <path
      d="M15.1502 23.6729H7.84964C7.48392 23.6729 7.1875 23.3764 7.1875 23.0108C7.1875 22.6451 7.48397 22.3486 7.84964 22.3486H15.1502C15.5159 22.3486 15.8124 22.6451 15.8124 23.0108C15.8124 23.3764 15.5159 23.6729 15.1502 23.6729Z"
      fill="url(#paint12_linear_629_121772)"
    />
    <path
      d="M15.1502 25.5772H7.84964C7.48392 25.5772 7.1875 25.2807 7.1875 24.9151C7.1875 24.5493 7.48397 24.2529 7.84964 24.2529H15.1502C15.5159 24.2529 15.8124 24.5494 15.8124 24.9151C15.8124 25.2808 15.5159 25.5772 15.1502 25.5772Z"
      fill="url(#paint13_linear_629_121772)"
    />
    <path
      d="M14.014 26.5362C13.3116 26.2979 12.4418 26.1567 11.4999 26.1567C10.558 26.1567 9.68821 26.2978 8.98584 26.5362C10.6045 27.154 12.3953 27.154 14.014 26.5362Z"
      fill="url(#paint14_linear_629_121772)"
    />
    <path
      d="M1.97623 11.6964H0.867964C0.573982 11.6964 0.335693 11.4581 0.335693 11.1641C0.335693 10.8701 0.573982 10.6318 0.867964 10.6318H1.97623C2.27022 10.6318 2.50851 10.8702 2.50851 11.1641C2.50851 11.4581 2.27022 11.6964 1.97623 11.6964Z"
      fill="url(#paint15_linear_629_121772)"
    />
    <path
      d="M22.132 11.6964H21.0237C20.7297 11.6964 20.4915 11.4581 20.4915 11.1641C20.4915 10.8701 20.7297 10.6318 21.0237 10.6318H22.132C22.426 10.6318 22.6643 10.8702 22.6643 11.1641C22.6643 11.4581 22.426 11.6964 22.132 11.6964Z"
      fill="url(#paint16_linear_629_121772)"
    />
    <path
      d="M12.0323 1.64054V0.532271C12.0323 0.238341 11.794 0 11.5 0C11.2061 0 10.9678 0.238341 10.9678 0.532271V1.64054C10.9678 1.93452 11.2061 2.17281 11.5 2.17281C11.794 2.17286 12.0323 1.93452 12.0323 1.64054Z"
      fill="url(#paint17_linear_629_121772)"
    />
    <path
      d="M4.38928 4.8066L3.60559 4.02291C3.39775 3.81507 3.39775 3.47804 3.60559 3.27014C3.81344 3.0623 4.15052 3.0623 4.35836 3.27014L5.14205 4.05383C5.3499 4.26168 5.3499 4.5987 5.14205 4.8066C4.93415 5.0145 4.59713 5.0145 4.38928 4.8066Z"
      fill="url(#paint18_linear_629_121772)"
    />
    <path
      d="M18.6106 4.8066L19.3942 4.02291C19.6021 3.81507 19.6021 3.47804 19.3942 3.27014C19.1864 3.0623 18.8494 3.0623 18.6415 3.27014L17.8578 4.05383C17.6499 4.26168 17.6499 4.5987 17.8578 4.8066C18.0657 5.0145 18.4027 5.0145 18.6106 4.8066Z"
      fill="url(#paint19_linear_629_121772)"
    />
    <defs>
      <radialGradient
        id="paint0_radial_629_121772"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8.70778 7.92696) scale(13.2255)"
      >
        <stop stopColor="#FFDA45" />
        <stop offset="0.3043" stopColor="#FFCE3E" />
        <stop offset="0.8558" stopColor="#FFAE2B" />
        <stop offset="1" stopColor="#FFA425" />
      </radialGradient>
      <linearGradient
        id="paint1_linear_629_121772"
        x1="12.4333"
        y1="12.0107"
        x2="3.95483"
        y2="3.53222"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA45" stopOpacity="0" />
        <stop offset="1" stopColor="#FCEE21" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_629_121772"
        x1="12.0528"
        y1="8.75143"
        x2="10.533"
        y2="2.31341"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA45" stopOpacity="0" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_629_121772"
        x1="11.7902"
        y1="14.2809"
        x2="23.2793"
        y2="-7.08723"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA45" stopOpacity="0" />
        <stop offset="1" stopColor="#B53759" />
      </linearGradient>
      <radialGradient
        id="paint4_radial_629_121772"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(11.5 15.5801) scale(5.07182)"
      >
        <stop stopColor="#FCEE21" stopOpacity="0.6" />
        <stop offset="0.3263" stopColor="#FDEA28" stopOpacity="0.404" />
        <stop offset="0.7865" stopColor="#FEE03A" stopOpacity="0.128" />
        <stop offset="1" stopColor="#FFDA45" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint5_linear_629_121772"
        x1="7.63892"
        y1="23.9203"
        x2="15.3611"
        y2="23.9203"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7E8595" />
        <stop offset="1" stopColor="#555A66" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_629_121772"
        x1="11.4938"
        y1="22.4533"
        x2="11.5108"
        y2="21.5365"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#555A66" stopOpacity="0" />
        <stop offset="1" stopColor="#555A66" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_629_121772"
        x1="11.4938"
        y1="24.3561"
        x2="11.5108"
        y2="23.4393"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#555A66" stopOpacity="0" />
        <stop offset="1" stopColor="#555A66" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_629_121772"
        x1="11.4866"
        y1="26.1705"
        x2="11.5035"
        y2="25.255"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#555A66" stopOpacity="0" />
        <stop offset="1" stopColor="#555A66" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_629_121772"
        x1="11.5001"
        y1="17.4562"
        x2="11.5001"
        y2="14.7717"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA45" stopOpacity="0" />
        <stop offset="1" stopColor="#FCEE21" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_629_121772"
        x1="11.5001"
        y1="17.9995"
        x2="11.5001"
        y2="21.1921"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA45" stopOpacity="0" />
        <stop offset="1" stopColor="#B53759" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_629_121772"
        x1="11.4799"
        y1="20.636"
        x2="11.5292"
        y2="21.794"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9F7FC" />
        <stop offset="1" stopColor="#F0DDFC" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_629_121772"
        x1="11.4799"
        y1="22.5403"
        x2="11.5292"
        y2="23.6983"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7E8595" />
        <stop offset="1" stopColor="#555A66" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_629_121772"
        x1="11.4799"
        y1="24.4447"
        x2="11.5292"
        y2="25.6026"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7E8595" />
        <stop offset="1" stopColor="#555A66" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_629_121772"
        x1="11.464"
        y1="26.276"
        x2="11.6677"
        y2="27.753"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9F7FC" />
        <stop offset="1" stopColor="#F0DDFC" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_629_121772"
        x1="1.4221"
        y1="11.0401"
        x2="1.4221"
        y2="12.1354"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" />
        <stop offset="1" stopColor="#4AB272" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_629_121772"
        x1="21.5779"
        y1="11.0401"
        x2="21.5779"
        y2="12.1354"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" />
        <stop offset="1" stopColor="#4AB272" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_629_121772"
        x1="11.3761"
        y1="1.08641"
        x2="12.4714"
        y2="1.08641"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" />
        <stop offset="1" stopColor="#4AB272" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_629_121772"
        x1="4.46202"
        y1="3.94926"
        x2="3.68768"
        y2="4.7236"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" />
        <stop offset="1" stopColor="#4AB272" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_629_121772"
        x1="18.5378"
        y1="3.94926"
        x2="19.3122"
        y2="4.7236"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#92F37F" />
        <stop offset="1" stopColor="#4AB272" />
      </linearGradient>
    </defs>
  </svg>
);
export default HintIcon;
