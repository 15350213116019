import React from "react";

const FolderIcon = () => (
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.4069 0.216797H2.4069C1.49023 0.216797 0.748568 0.966797 0.748568 1.88346L0.740234 11.8835C0.740234 12.8001 1.49023 13.5501 2.4069 13.5501H15.7402C16.6569 13.5501 17.4069 12.8001 17.4069 11.8835V3.55013C17.4069 2.63346 16.6569 1.88346 15.7402 1.88346H9.07357L7.4069 0.216797Z"
      fill="black"
      fillOpacity="0.54"
    />
  </svg>
);

export default FolderIcon;
