import QUEUE_ACTIONS_TYPES from "./queueActionsTypes";

export const QUEUE_INITIAL_STATE = {
  notifications: [],
  newCall: null,
};

const queueReducer = (state = QUEUE_INITIAL_STATE, action) => {
  switch (action.type) {
    case QUEUE_ACTIONS_TYPES.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: [...action.payload]
      };
    case QUEUE_ACTIONS_TYPES.SET_NEW_CALL:
      return {
        ...state,
        newCall: action.payload
      };
    default:
      return state;
  }
};

export default queueReducer;
