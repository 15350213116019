import React from "react";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import { InputAdornment } from "@material-ui/core/index";
import { withStyles } from "@material-ui/core/styles";
import { ADD_NOTIFICATION } from "actions/actionConstants";
import BankIcon from "api/icons/DashboardIcons/BankIcon";
import EmailOutline from "api/icons/EmailOutline";
import MeetUsLogo from "api/icons/MeetUsLogo";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { Field, reduxForm } from "redux-form/immutable";
import formUtils from "utils/form";
import { TextFieldRedux } from "./ReduxFormMUI";
import styles from "./user-jss";

const {
  validation: { required, email },
} = formUtils;

const LinkBtn = React.forwardRef((props, ref) => (
  // eslint-disable-next-line react/prop-types
  <NavLink to={props.to} {...props} innerRef={ref} />
));

class ForgotPasswordForm extends React.Component {
  render() {
    const { classes, handleSubmit, loading } = this.props;
    return (
      <div className={classNames(classes.paperWrap)}>
        <header className={classes.titleContainer}>
          <section className={classNames(classes.brand, classes.outer)}>
            <MeetUsLogo className={classes.logoStyle} />
          </section>
          <Typography variant="h6" className={classes.welcomeBackTitle}>
            Forget Password?
          </Typography>
          <Typography variant="h6" className={classes.subTitle}>
            Enter email and check inbox for password reset instructions.
          </Typography>
        </header>
        <form onSubmit={handleSubmit}>
          <FormControl key="email" className={classNames(classes.formControl, classes.spaceMargin)}>
            <Field
              name="email"
              component={TextFieldRedux}
              placeholder="Email"
              required
              validate={[required, email]}
              className={classes.field}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailOutline />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <section className={classes.optionContainer}>
            <p
              className={classes.rememberMe}
            >
              Remember?
            </p>
            <Link
              to="/login"
              className={classNames(classes.buttonLink, classes.loginLink)}
            >
              Login
            </Link>
          </section>
          <footer className={classNames(classes.btnArea, classes.btnMargin)}>
            <Button className={classes.signInInput} size="large" variant="contained" color="primary" type="submit" disabled={loading}>
              Send
            </Button>
          </footer>
        </form>
      </div>
    );
  }
}

ForgotPasswordForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  deco: PropTypes.bool.isRequired,
};

const ResetFormReduxed = reduxForm({
  form: "immutableEResetFrm",
  enableReinitialize: true,
})(ForgotPasswordForm);

const reducer = "ui";
const RegisterFormMapped = connect(
  (state) => ({
    deco: state.getIn([reducer, "decoration"]),
  }),
  (dispatch) => ({
    onSubmitSuccess: (message) => dispatch({ type: ADD_NOTIFICATION, message }),
    onSubmitFail: (...[, , submitError]) =>
      submitError
      && dispatch({ type: ADD_NOTIFICATION, message: submitError?.message }),
  })
)(ResetFormReduxed);

export default withStyles(styles)(RegisterFormMapped);
