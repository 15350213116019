import React from "react";

const RoundedDelete = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="10"
    viewBox="0 0 11 10"
    fill="none"
    {...props}
  >
    <path
      d="M1.97163 0.52827C1.71128 0.26792 1.28917 0.26792 1.02882 0.52827C0.768471 0.788619 0.768471 1.21073 1.02882 1.47108L4.55738 4.99965L1.02876 8.52827C0.768409 8.78862 0.768409 9.21073 1.02876 9.47108C1.28911 9.73143 1.71122 9.73143 1.97157 9.47108L5.50019 5.94245L9.02882 9.47109C9.28917 9.73144 9.71128 9.73144 9.97163 9.47109C10.232 9.21074 10.232 8.78863 9.97163 8.52828L6.443 4.99965L9.97157 1.47108C10.2319 1.21073 10.2319 0.78862 9.97157 0.528271C9.71122 0.267921 9.28911 0.267922 9.02876 0.528271L5.50019 4.05684L1.97163 0.52827Z"
      fill="white"
    />
  </svg>
);

export default RoundedDelete;
