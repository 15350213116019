import React from "react";

const UserIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.05583 1.45703C7.17544 1.45703 5.64673 2.98574 5.64673 4.86613C5.64673 6.71064 7.08932 8.20346 8.96971 8.26806C9.02712 8.26088 9.08454 8.26088 9.1276 8.26806C9.14196 8.26806 9.14913 8.26806 9.16349 8.26806C9.17066 8.26806 9.17066 8.26806 9.17784 8.26806C11.0152 8.20346 12.4578 6.71064 12.4649 4.86613C12.4649 2.98574 10.9362 1.45703 9.05583 1.45703Z"
      fill="currentColor"
    />
    <path
      d="M12.7018 10.1771C10.6994 8.84217 7.43381 8.84217 5.41705 10.1771C4.50557 10.7872 4.00317 11.6125 4.00317 12.4953C4.00317 13.3781 4.50557 14.1963 5.40988 14.7991C6.41466 15.4738 7.73524 15.8111 9.05582 15.8111C10.3764 15.8111 11.697 15.4738 12.7018 14.7991C13.6061 14.1891 14.1085 13.3709 14.1085 12.4809C14.1013 11.5982 13.6061 10.78 12.7018 10.1771Z"
      fill="currentColor"
    />
  </svg>
);

export default UserIcon;
