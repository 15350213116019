const styles = (theme) => ({
  container: {
    height: "40px",
    width: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#004A94",
    color: theme.palette.common.white,
    borderRadius: "50%",
    fontSize: ".75rem",
    fontWeight: 400
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover"
  }
});

export default styles;
