const CHAT_ACTIONS_TYPES = {
  SET_JWT_TOKEN: "SET_JWT_TOKEN",
  SET_AGENT_USER_DATA: "SET_AGENT_USER_DATA",
  SET_ROOMS: "SET_ROOMS",
  SET_ROOM_CHAT: "SET_ROOM_CHAT",
  SET_CHAT_WIDGET_SETTINGS: "SET_CHAT_WIDGET_SETTINGS",
  SET_TYPING: "SET_TYPING",
  SET_SELECTED_ROOM: "SET_SELECTED_ROOM",
  SET_CUSTOMER_ID: "SET_CUSTOMER_ID",
  ADD_NEW_MESSAGE_TO_ROOM: "ADD_NEW_MESSAGE_TO_ROOM",
  SET_LOADING_ROOMS: "SET_LOADING_ROOMS",
  SET_LOADING_CHAT: "SET_LOADING_CHAT",
  SET_MESSAGE_SEEN: "SET_MESSAGE_SEEN",
  SET_JOINED_ROOM_BEFORE: "SET_JOINED_ROOM_BEFORE",
  SEARCH_FOR_ROOM: "SEARCH_FOR_ROOM",
  SET_SEARCH_KEY: "SET_SEARCH_KEY",
  SEARCH_FOR_MESSAGE: "SEARCH_FOR_MESSAGE",
  SET_SEARCH_MESSAGES_RESULT: "SET_SEARCH_MESSAGES_RESULT",
  APPEND_NEW_ROOM: "APPEND_NEW_ROOM",
  REMOVE_JWT_TOKEN: "REMOVE_JWT_TOKEN",
  SET_IMAGES_FOR_UPLOAD: "SET_IMAGES_FOR_UPLOAD",
  SET_IMAGES_UPLOAD_PROGRESS: "SET_IMAGES_UPLOAD_PROGRESS",
  SET_ATTACHMENTS: "SET_ATTACHMENTS",
  REMOVE_IMAGE_ATTACHMENT: "REMOVE_IMAGE_ATTACHMENT",
  REMOVE_ALL_ATTACHMENTS: "REMOVE_ALL_ATTACHMENTS",
  SET_PROMO_CODE_MODAL: "SET_PROMO_CODE_MODAL",
  SET_PRODUCTS_MODAL: "SET_PRODUCTS_MODAL",
  SET_PROMO_CODE: "SET_PROMO_CODE",
  FILTER_QUERY_FOR_ROOMS: "FILTER_QUERY_FOR_ROOM",
  UPDATE_ROOM_CONTENT: "UPDATE_ROOM_CONTENT",
  UPDATE_LATEST_MESSAGE_AND_SORT: "UPDATE_LATEST_MESSAGE_AND_SORT",
  FIRE_ERROR_MESSAGE: "FIRE_ERROR_MESSAGE",
  FIRE_NOTIFY_MESSAGE: "FIRE_NOTIFY_MESSAGE",
};

export default CHAT_ACTIONS_TYPES;
