const statusMap = Object.freeze({
  CLIENT_CONFIRMED: "Pending payment choice",
  CLIENT_CANCELLED: "Cancelled",
  FINALIZED: "New",
  STORE_CONFIRMED: "Confirmed",
  STORE_PREPARED: "Prepared",
  DISPATCHED: "Dispatched",
  DELIVERED: "Delivered",
  STORE_CANCELLED: "Rejected",
  RETURNED: "Returned",
  PAID: "Paid",
  UNPAID: "Unpaid",
  FAILED: "Failed",
  PROCESSING: "Processing",
  NEW: "New",
  RECEIVED: "Received",
  REJECTED: "Rejected",
  CONFIRMED: "Confirmed",
});

export const shippingTypeMap = Object.freeze({
  BOSTA_LEVIS: "Bosta Delivery", // When Customer Select company(BOSTA) For Delivery
  PICKUP: "Store Pickup",
  PICKUP_POINTS: "Store Pickup",
  SALLAB_PRICE_PRECENTAGE: "Salab",
  CLICKNSHIP: "Clicknship",
  FIXED_FEE: "Special Shipping",
  FIXED_FEE_STRICT_SAME_CITY: "Special Shipping",
  FIXED_FEE_SELECTED_AREAS: "Home Delivery",
  FIXED_FEE_SELECTED_AREAS_MIN_ORDER: "Home Delivery",
  VAR_FEE_SELECTED_SUBAREAS_MIN_ORDER: "Special Delivery",
  MYLERZ: "Mylerz Shipping",
  MULTIPLE_PICKUP: "Pickup From Shops",
  PARTIAL_PICKUP: "Store Pickup", // When Customer Select Pickup From Store
});

export const paymentsTypeMap = Object.freeze({
  COD: { label: "Cash on delivery", color: "black" },
  DEFAULt: { label: "Online Payment", color: "#4AB272" },
  MCARD: { label: "payed online", color: "#4AB272" },
  UPG: { label: "payed online", color: "#4AB272" },
  DELIVERY: { label: "on delivery", color: "red" }, // It will change according payment operator
});

export const orderStatusMap = {
  CLIENT_CONFIRMED: { label: "confirmed", color: "#FB8C00", bg: "#FB8C0014" },
  CLIENT_CANCELLED: { label: "cancelled", color: "#F00", bg: "#FF000014" },
  FINALIZED: { label: "pending", color: "#1976D2", bg: "#F2F4F8" },
  STORE_CONFIRMED: { label: "accepted", color: "#FB8C00", bg: "#FB8C0014" },
  STORE_PREPARED: { label: "accepted", color: "#FB8C00", bg: "#FB8C0014" },
  DISPATCHED: { label: "shipped", color: "#37A4A7", bg: "#FB8C0014" },
  DELIVERED: { label: "delivered", color: "#009688", bg: "#00968814" },
  STORE_CANCELLED: { label: "rejected", color: "#F00", bg: "#FF000014" },
  RETURNED: { label: "returned", color: "#FB8C00", bg: "#FB8C0014" },
  PAID: { label: "paid", color: "#009688", bg: "#00968814" },
  UNPAID: { label: "Unpaid", color: "#F00", bg: "#FF000014" },
  FAILED: { label: "failed", color: "#F00", bg: "#FF000014" },
  PROCESSING: { label: "processing", color: "#1976D2", bg: "#F2F4F8" },
  NEW: { label: "new", color: "#009688", bg: "#00968814" },
  RECEIVED: { label: "received", color: "#FB8C00", bg: "#FB8C0014" },
  REJECTED: { label: "rejected", color: "#F00", bg: "#FF000014" },
  CONFIRMED: { label: "confirmed", color: "#FB8C00", bg: "#FB8C0014" },
  DISCARDED: { label: "cancelled", color: "#F00", bg: "#FF000014" },
};

export const finishedOrders = [
  "FINALIZED",
  "STORE_CONFIRMED",
  "STORE_PREPARED",
  "DISPATCHED",
  "DELIVERED",
];

export const unFinishedOrders = [
  "CLIENT_CANCELLED",
  "RETURNED",
  "DISCARDED",
  "CLIENT_CONFIRMED",
  "STORE_CANCELLED",
  "NEW"
];

const editableStatuses = new Set([
  "FINALIZED",
  "STORE_CONFIRMED",
  "STORE_PREPARED",
  "DISPATCHED",
  "DELIVERED",
]);

const achievableStatuses = [
  "FINALIZED",
  "STORE_CONFIRMED",
  "STORE_PREPARED",
  "DISPATCHED",
  "DELIVERED",
  "STORE_CANCELLED",
];

export const shippingTypesList = [
  { id: "BOSTA_LEVIS", label: "Bosta delivery" },
  { id: "PICKUP", label: "Store pickup" },
  { id: "PICKUP_POINTS", label: "Store pickup" },
  { id: "SALLAB_PRICE_PRECENTAGE", label: "Salab" },
  { id: "CLICKNSHIP", label: "Clicknship" },
  { id: "FIXED_FEE", label: "Special shipping" },
  { id: "FIXED_FEE_STRICT_SAME_CITY", label: "Special shipping" },
  { id: "FIXED_FEE_SELECTED_AREAS", label: "Home delivery" },
  { id: "FIXED_FEE_SELECTED_AREAS_MIN_ORDER", label: "Home delivery" },
  { id: "VAR_FEE_SELECTED_SUBAREAS_MIN_ORDER", label: "Special delivery" },
  { id: "MYLERZ", label: "Mylerz shipping" },
  { id: "MULTIPLE_PICKUP", label: "Pickup from shops" },
  { id: "PARTIAL_PICKUP", label: "Store pickup" }
];

export default { statusMap, editableStatuses, achievableStatuses };
