import React from "react";

const ProductsBoxIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M11.9998 13.3C11.8698 13.3 11.7398 13.27 11.6198 13.2L2.78985 8.09002C2.42985 7.88002 2.30983 7.41999 2.51983 7.05999C2.72983 6.69999 3.17983 6.57997 3.54983 6.78997L11.9998 11.68L20.3998 6.82C20.7598 6.61 21.2198 6.74002 21.4298 7.09002C21.6398 7.45002 21.5098 7.90999 21.1598 8.11999L12.3899 13.2C12.2599 13.26 12.1298 13.3 11.9998 13.3Z"
      fill="#4E5057"
    />
    <path
      d="M12 22.36C11.59 22.36 11.25 22.02 11.25 21.61V12.54C11.25 12.13 11.59 11.79 12 11.79C12.41 11.79 12.75 12.13 12.75 12.54V21.61C12.75 22.02 12.41 22.36 12 22.36Z"
      fill="#4E5057"
    />
    <path
      d="M12.0001 22.75C11.1201 22.75 10.2501 22.56 9.56012 22.18L4.22012 19.21C2.77012 18.41 1.64014 16.48 1.64014 14.82V9.16998C1.64014 7.50998 2.77012 5.59002 4.22012 4.78002L9.56012 1.82C10.9301 1.06 13.0701 1.06 14.4401 1.82L19.7801 4.78997C21.2301 5.58997 22.3601 7.51999 22.3601 9.17999V14.83C22.3601 16.49 21.2301 18.41 19.7801 19.22L14.4401 22.18C13.7501 22.56 12.8801 22.75 12.0001 22.75ZM12.0001 2.74999C11.3701 2.74999 10.7501 2.88 10.2901 3.13L4.95013 6.09997C3.99013 6.63997 3.14014 8.06999 3.14014 9.17999V14.83C3.14014 15.93 3.99013 17.37 4.95013 17.91L10.2901 20.88C11.2001 21.39 12.8001 21.39 13.7101 20.88L19.0501 17.91C20.0101 17.37 20.8601 15.94 20.8601 14.83V9.17999C20.8601 8.07999 20.0101 6.63997 19.0501 6.09997L13.7101 3.13C13.2501 2.88 12.6301 2.74999 12.0001 2.74999Z"
      fill="#4E5057"
    />
    <path
      d="M17.0002 13.99C16.5902 13.99 16.2502 13.65 16.2502 13.24V10.0201L7.13018 4.76007C6.77018 4.55007 6.65016 4.09005 6.86016 3.74005C7.07016 3.38005 7.52018 3.26003 7.88018 3.47003L17.3702 8.95007C17.6002 9.08007 17.7502 9.33003 17.7502 9.60003V13.2601C17.7502 13.6501 17.4102 13.99 17.0002 13.99Z"
      fill="#4E5057"
    />
  </svg>
);

export default ProductsBoxIcon;
