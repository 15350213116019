const styles = (theme) => ({
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 4,
    marginBottom: 8,
  },
  noMargin: {
    marginBottom: 0,
  },
  label: {
    color: "#8E8E8E",
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "-0.14px",
    "&:first-letter": {
      textTransform: "uppercase",
    },
  },
  infoIcon: {
    "& path": {
      fill: theme.palette.uniqueColor,
    },
  },
  errorMessage: {
    color: theme.palette.uniqueColor,
    fontSize: 12,
    fontWeight: 500,
  },
  tooltip: {
    background: "#F8F8F8",
    color: "#33383F",
    fontSize: 12,
    fontWeight: 500,
    padding: 6,
    borderRadius: 8,
  },
  fieldError: {
    "& div fieldset": {
      border: `1px solid ${theme.palette.uniqueColor} !important`,
    },
    "& .MuiInputBase-root": {
      border: "none !important",
    },
  },
  fieldErrorTextArea: {
    border: `1px solid ${theme.palette.uniqueColor} !important`,
    "& .MuiInputBase-root": {
      border: "none !important",
    },
  },
  // Start switch styles
  switchRoot: {
    width: 48,
    height: 24,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1.3,
    "&$checked": {
      transform: "translateX(24px)",
      color: "#FFF",
      boxShadow:
        "0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px -1px 1px 0px rgba(0, 0, 0, 0.10) inset, 0px 1px 2px 0px rgba(255, 255, 255, 0.10) inset",
      "& + $track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: theme.palette.primary.main,
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 20,
    height: 20,
  },
  track: {
    borderRadius: 24 / 2,
    backgroundColor: "#F4F4F4",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
  // End switch styles
  maxLength: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 13,
    fontWeight: 500,
    color: "#9A9FA5",
    "& span": {
      color: "#000",
    },
  },
  maxError: {
    "& span": {
      color: theme.palette.uniqueColor,
    },
  },
  placeholder: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    fontWeight: 500,
    color: "#9A9FA5",
  },
  formControlMultiple: {
    width: "100%",
    "& .MuiSelect-selectMenu": {
      paddingTop: "13px !important",
      paddingBottom: "13px !important",
      "& div": {
        overflow: "scroll",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      },
    },
  },
  changePadding: {
    "& .MuiSelect-selectMenu": {
      padding: "5.5px 4px !important",
    },
  },
  chipContainer: {
    width: "95%",
    display: "flex",
    gap: 8,
    [theme.breakpoints.down("sm")]: {
      width: "92%",
    },
  },
  chip: {
    height: 34,
    background: theme.palette.primary.main,
    color: "white",
    borderRadius: 8,
    padding: 8,
    fontFamily: theme.typography.fontFamily,
    fontSize: 13,
    fontWeight: 500,
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  deleteIcon: {
    width: 10,
    height: 10,
    flexShrink: 0,
  },
  menuItem: {
    color: "#8E8E8E",
    "& .MuiTypography-body1": {
      fontFamily: theme.typography.fontFamily,
      fontSize: 13,
      fontWeight: 500,
    },
  },
  avatar: {
    marginRight: 8,
    width: 32,
    height: 32,
    background: "#f6f6f6",
    color: "#999999",
    fontSize: 14,
    "& img": {
      objectFit: "contain !important",
    },
  },
  singleSelectionRender: {
    display: "flex",
    alignItems: "center",
    height: 21,
  },
  deleteIconSingle: {
    width: 10,
    height: 10,
    flexShrink: 0,
    marginLeft: 8,
    "& path": {
      fill: "#000",
    },
  },
  iconBlack: {
    "& path": {
      fill: "#000",
    },
  },
  toggleGroup: {
    border: "none",
    borderRadius: "0px",
    borderBottom: "2px solid #f8f8f8",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  toggleOption: {
    flex: "1",
    borderRadius: "0px !important",
    height: 48,
    width: "100%",
    border: "none",
    backgroundColor: "transparent",
    textTransform: "capitalize",
    color: "#8B909A",
    borderBottom: "3px solid transparent",
    borderImage: "none",
    borderLeft: "none",
    transition: "all 0.2s ease-in-out",
    "&.Mui-selected": {
      backgroundColor: "transparent",
      color: "#000",
      border: "none",
      borderLeft: "none",
      borderImage: theme.palette.gradientBorder,
      borderBottomWidth: "3px",
      borderBottomStyle: "solid",
      "&:hover": {
        backgroundColor: "transparent",
        color: "#000",
        border: "none",
        borderLeft: "none",
        borderImage: theme.palette.gradientBorder,
        borderBottomWidth: "3px",
        borderBottomStyle: "solid",
      },
    },
    "&:hover": {
      backgroundColor: "transparent",
      color: "#000",
      border: "none",
      borderLeft: "none",
      borderImage: theme.palette.gradientBorder,
      borderBottomWidth: "3px",
      borderBottomStyle: "solid",
    },
  },
});

export default styles;
