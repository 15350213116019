import React from "react";

const DotsVertical = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    {...props}
  >
    <circle
      cx="11"
      cy="11"
      r="0.916667"
      stroke="#4B465C"
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="11"
      cy="11"
      r="0.916667"
      stroke="white"
      strokeOpacity="0.5"
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="11"
      cy="17.4167"
      r="0.916667"
      stroke="#4B465C"
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="11"
      cy="17.4167"
      r="0.916667"
      stroke="white"
      strokeOpacity="0.5"
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse
      cx="11"
      cy="4.58334"
      rx="0.916667"
      ry="0.916667"
      stroke="#4B465C"
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse
      cx="11"
      cy="4.58334"
      rx="0.916667"
      ry="0.916667"
      stroke="white"
      strokeOpacity="0.5"
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DotsVertical;
