import { clampOneLine } from "utils/stylesJss";

const styles = (theme) => ({
  tagChild: {
    width: "100%",
    borderRadius: 8,
    padding: "10px 12px",
    border: "1px solid #938B9A59",
    display: "flex",
    alignItems: "center",
    gap: 20,
    height: 70,
    [theme.breakpoints.down("xs")]: {
      gap: 8,
    },
    "& svg": {
      flexShrink: 0,
    },
  },
  tagName: {
    fontSize: 20,
    fontWeight: 600,
    ...clampOneLine,
  },
  noChildren: {
    fontSize: 20,
    fontWeight: 600,
    paddingLeft: 68,
    ...clampOneLine,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 68 - 16,
    },
  },
});

export default styles;
