import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TagParent from "./TagParent/TagParent";
import styles from "./renderTree-jss";

const RenderTree = ({ classes, tagsTree }) => (
  <div className={classes.tree}>
    {tagsTree?.map((tag) => (
      <TagParent key={tag?.id} tagParent={tag} />
    ))}
  </div>
);

export default withStyles(styles)(RenderTree);
