import { Typography } from "@material-ui/core/index";
import withStyles from "@material-ui/core/styles/withStyles";
import DragAndDropIcon from "api/icons/DragAndDropIcon";
import React, { useState } from "react";
import ArrowUp from "api/icons/ArrowUp";
import TagChild from "./TagChild/TagChild";
import styles from "./TagParent-jss";

const TagParent = ({ tagParent, classes }) => {
  const [show, setShow] = useState(true);

  return (
    <ul
      style={{ "--gap": show ? "18px" : "0px" }}
      className={classes.tagParent}
      title={tagParent?.name || tagParent?.alias}
    >
      <div
        className={classes.tagParentContent}
        onClick={() => {
          setShow((prevState) => !prevState);
        }}
      >
        <div className={classes.iconAndName}>
          <DragAndDropIcon />
          <Typography
            className={classes.tagName}
          >
            {tagParent?.name || tagParent?.alias}
          </Typography>
        </div>
        <div style={{ "--rotate": show ? "0deg" : "180deg" }} className={classes.arrow} title={show ? "Close" : "Open"}><ArrowUp /></div>
      </div>
      <div
        style={{
          "--show": show ? "visible" : "hidden",
          "--pixels": show ? "100%" : "0px",
          "--opacity": show ? 1 : 0,
        }}
        className={classes.childrenContainer}
      >
        {tagParent?.children?.length > 0 ? (
          <>
            {tagParent?.children?.map((tagChild) => (
              <TagChild key={tagChild?.id} tagChild={tagChild} />
            ))}
          </>
        ) : (
          <TagChild noChildren />
        )}
      </div>
    </ul>
  );
};

export default withStyles(styles)(TagParent);
