import React from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import EllipseBlur from "components/EllipseBlur/index";
import styles from "./Outer-jss";

class Outer extends React.Component {
  render() {
    const {
      classes,
      children,
      gradient,
      decoration
    } = this.props;
    return (
      <div className={classNames(classes.outerContainer)}>
        <div className={classes.contentContainer}>
          <div className={classes.formContent}>
            {children}
          </div>
          <div className={classes.meetUsLogo}>
            {process.env.REACT_APP_DEVELOPMENT_MODE && process.env.REACT_APP_DEVELOPMENT_MODE === "development"
              ? <img src="./dashboard/images/loginPageLogo.png" alt="MeetusVR" />
              : <img src="./images/loginPageLogo.png" alt="MeetusVR" />}
          </div>
        </div>
        <div className={classes.ellipseOne}>
          <EllipseBlur width="807px" height="807px" borderRadius="807px" opacity="0.6" background="#9E77F6" blur="400px" />
        </div>
        <div className={classes.ellipseTwo}>
          <EllipseBlur width="667px" height="667px" borderRadius="667px" opacity="1" background="#E477F6" blur="200px" />
        </div>
        <div className={classes.ellipseThree}>
          <EllipseBlur width="813px" height="813px" borderRadius="813px" opacity="0.6" background="#B0D2E5" blur="400px" />
        </div>
        <div className={classes.ellipseFour}>
          <EllipseBlur width="667px" height="667px" borderRadius="667px" opacity="1" background="#9E77F6" blur="200px" />
        </div>
      </div>
    );
  }
}

Outer.propTypes = {
  classes: PropTypes.object.isRequired,
  gradient: PropTypes.bool.isRequired,
  decoration: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const reducer = "ui";
const mapStateToProps = state => ({
  gradient: state.getIn([reducer, "gradient"]),
  decoration: state.getIn([reducer, "decoration"]),
  ...state,
});

const OuterMaped = connect(
  mapStateToProps,
)(Outer);

export default (withStyles(styles)(OuterMaped));
