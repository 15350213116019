import React from "react";

const BrandShopsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M12 16.75C11.6 16.75 11.2 16.72 10.82 16.65C8.7 16.34 6.77 15.12 5.55 13.31C4.7 12.03 4.25 10.54 4.25 9C4.25 4.73 7.73 1.25 12 1.25C16.27 1.25 19.75 4.73 19.75 9C19.75 10.54 19.3 12.03 18.45 13.31C17.22 15.13 15.29 16.34 13.15 16.66C12.8 16.72 12.4 16.75 12 16.75ZM12 2.75C8.55 2.75 5.75 5.55 5.75 9C5.75 10.25 6.11 11.45 6.79 12.47C7.78 13.93 9.33 14.91 11.05 15.16C11.69 15.27 12.32 15.27 12.91 15.16C14.66 14.91 16.21 13.92 17.2 12.46C17.88 11.44 18.24 10.24 18.24 8.98999C18.25 5.54999 15.45 2.75 12 2.75Z"
      fill="#4E5057"
    />
    <path
      d="M6.46982 22.59C6.32982 22.59 6.19982 22.57 6.05982 22.54C5.40982 22.39 4.90982 21.89 4.75982 21.24L4.40982 19.77C4.38982 19.68 4.31982 19.61 4.21982 19.58L2.56982 19.19C1.94982 19.04 1.45982 18.58 1.28982 17.97C1.11982 17.36 1.28982 16.7 1.73982 16.25L5.63982 12.35C5.79982 12.19 6.01982 12.11 6.23982 12.13C6.45982 12.15 6.65982 12.27 6.78982 12.46C7.77982 13.92 9.32982 14.91 11.0598 15.16C11.6998 15.27 12.3298 15.27 12.9198 15.16C14.6698 14.91 16.2198 13.92 17.2098 12.46C17.3298 12.27 17.5398 12.15 17.7598 12.13C17.9798 12.11 18.1998 12.19 18.3598 12.35L22.2598 16.25C22.7098 16.7 22.8798 17.36 22.7098 17.97C22.5398 18.58 22.0398 19.05 21.4298 19.19L19.7798 19.58C19.6898 19.6 19.6198 19.67 19.5898 19.77L19.2398 21.24C19.0898 21.89 18.5898 22.39 17.9398 22.54C17.2898 22.7 16.6198 22.47 16.1998 21.96L11.9998 17.13L7.79982 21.97C7.45982 22.37 6.97982 22.59 6.46982 22.59ZM6.08982 14.03L2.79982 17.32C2.70982 17.41 2.71982 17.51 2.73982 17.57C2.74982 17.62 2.79982 17.72 2.91982 17.74L4.56982 18.13C5.21982 18.28 5.71982 18.78 5.86982 19.43L6.21982 20.9C6.24982 21.03 6.34982 21.07 6.40982 21.09C6.46982 21.1 6.56982 21.11 6.65982 21.01L10.4898 16.6C8.78982 16.27 7.22982 15.36 6.08982 14.03ZM13.5098 16.59L17.3398 20.99C17.4298 21.1 17.5398 21.1 17.5998 21.08C17.6598 21.07 17.7498 21.02 17.7898 20.89L18.1398 19.42C18.2898 18.77 18.7898 18.27 19.4398 18.12L21.0898 17.73C21.2098 17.7 21.2598 17.61 21.2698 17.56C21.2898 17.51 21.2998 17.4 21.2098 17.31L17.9198 14.02C16.7698 15.35 15.2198 16.26 13.5098 16.59Z"
      fill="#4E5057"
    />
    <path
      d="M13.8901 12.89C13.6301 12.89 13.3201 12.82 12.9501 12.6L12.0001 12.03L11.0501 12.59C10.1801 13.11 9.61014 12.81 9.40014 12.66C9.19014 12.51 8.74014 12.06 8.97014 11.07L9.21014 10.04L8.41014 9.29999C7.97014 8.85999 7.81014 8.33001 7.96014 7.85001C8.11014 7.37001 8.55014 7.02999 9.17014 6.92999L10.2401 6.75L10.7501 5.63C11.0401 5.06 11.4901 4.73999 12.0001 4.73999C12.5101 4.73999 12.9701 5.07001 13.2501 5.64001L13.8401 6.82001L14.8301 6.94C15.4401 7.04 15.8801 7.37999 16.0401 7.85999C16.1901 8.33999 16.0301 8.87 15.5901 9.31L14.7601 10.14L15.0201 11.07C15.2501 12.06 14.8001 12.51 14.5901 12.66C14.4801 12.75 14.2401 12.89 13.8901 12.89ZM9.61014 8.39001L10.3001 9.07999C10.6201 9.39999 10.7801 9.94 10.6801 10.38L10.4901 11.18L11.2901 10.71C11.7201 10.46 12.3001 10.46 12.7201 10.71L13.5201 11.18L13.3401 10.38C13.2401 9.93 13.3901 9.39999 13.7101 9.07999L14.4001 8.39001L13.5301 8.23999C13.1101 8.16999 12.6901 7.86001 12.5001 7.48001L12.0001 6.5L11.5001 7.5C11.3201 7.87 10.9001 8.19001 10.4801 8.26001L9.61014 8.39001Z"
      fill="#4E5057"
    />
  </svg>
);

export default BrandShopsIcon;
