import React from "react";
import PropTypes from "prop-types";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import URLUtils from "utils/url";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import DragAndDrop from "components/DragAndDrop/DragAndDrop";

const ReorderList = ({
  items,
  listId,
  onItemDrop,
  onItemHover,
  onDragEnd,
}) => (
  // context fixes an error of double providers when switching to mui-datatables.
  // found the fix in mui-datatables implementation not sure why it works
  <DndProvider backend={HTML5Backend} context={window}>
    <List>
      {items.map((item, index) => (
        <DragAndDrop
          key={item.id}
          id={item.id}
          type={listId}
          index={index}
          onDrop={(draggedItem) => {
            onItemDrop(draggedItem, index);
          }}
          onHover={(draggedItem) => {
            onItemHover(draggedItem, index);
          }}
          onEnd={onDragEnd}
        >
          {({ dragRef, previewRef, isDragging, dropRef }) => (
            <div ref={dropRef}>
              <ListItem ref={previewRef}>
                <div ref={dragRef}>
                  <DragHandleIcon
                    style={{ cursor: isDragging ? "grabbing" : "grab" }}
                  />
                </div>
                <ListItemAvatar>
                  <Avatar>
                    <img
                      src={URLUtils.getProperImageUrl(item.image_url)}
                      alt={item.name}
                    />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={item.name} secondary={item.id} />
              </ListItem>
            </div>
          )}
        </DragAndDrop>
      ))}
    </List>
  </DndProvider>
);

ReorderList.propTypes = {
  items: PropTypes.array,
  listId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onItemDrop: PropTypes.func,
  onItemHover: PropTypes.func,
  onDragEnd: PropTypes.func,
};

ReorderList.defaultProps = {
  items: [],
  onItemDrop: () => {},
  onItemHover: () => {},
  onDragEnd: () => {},
};

export default ReorderList;
