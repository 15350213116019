import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  useMemo,
} from "react";
import {
  WALLET_ADAPTERS,
  CHAIN_NAMESPACES,
  WEB3AUTH_NETWORK,
} from "@web3auth/base";
import { connect } from "react-redux";
import ApiConfig from "utils/services/API/index";
import appConfig from "config.json";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { bscTestnet, sepolia } from "wagmi/chains";
import {
  createSmartAccountClient,
  ENTRYPOINT_ADDRESS_V06,
  providerToSmartAccountSigner,
} from "permissionless";
import { Web3AuthNoModal } from "@web3auth/no-modal";
import { http, createPublicClient } from "viem";
import { signerToBiconomySmartAccount } from "permissionless/accounts";

export const web3AuthchainConfig = {
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  chainId: "0x" + sepolia.id.toString(16),
  rpcTarget: sepolia.rpcUrls.default.http[0],
  displayName: sepolia.name,
  blockExplorerUrl: sepolia.blockExplorers.default.url,
  ticker: sepolia.nativeCurrency.symbol,
  tickerName: sepolia.nativeCurrency.name,
  logo: "https://s3.coinmarketcap.com/static-gravity/image/b8db9a2ac5004c1685a39728cdf4e100.png",
};

/**
 * @typedef {Object} WalletContextValue
 * @property {() => void} disconnect - Function to disconnect from the wallet
 * @property {() => Promise<void>} connectWallet - Function to connect to the wallet
 * @property {GetWalletClientResult} [client] - Optional wallet client
 * @property {boolean} isConnecting - Indicates if the data is loading or not
 * @property {string} [account] - Optional account address
 */

const WalletContext = createContext({});

// eslint-disable-next-line import/no-mutable-exports
export let logoutWallet = null;

const WalletProvider = ({ children, jwtToken }) => {
  const [web3auth, setWeb3auth] = useState();
  const [walletClient, setClient] = useState();
  const [account, setAccount] = useState();
  const [isConnecting, setIsConnecting] = useState(false);
  const publicClient = useMemo(
    () =>
      createPublicClient({
        chain: sepolia,
        transport: http(),
      }),
    []
  );

  const initiWeb3Auth = async () => {
    const privateKeyProvider = new EthereumPrivateKeyProvider({
      config: { chainConfig: web3AuthchainConfig },
    });
    const _web3auth = new Web3AuthNoModal({
      clientId: appConfig.config.web3auth_project_id, // Web3Auth Client ID
      web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_DEVNET,
      privateKeyProvider,
      useCoreKitKey: false,
    });
    const openloginAdapter = new OpenloginAdapter({
      adapterSettings: {
        clientId: appConfig.config.web3auth_project_id, // Web3Auth Client ID
        network: "testnet",
        uxMode: "popup", // redirect or popup
        loginConfig: {
          jwt: {
            verifier: "meetus-jwk-private-verifier", // name of the verifier created on Web3Auth Dashboard
            typeOfLogin: "jwt",
            clientId: appConfig.config.web3auth_project_id, // Web3Auth Client ID
          },
        },
      },
      privateKeyProvider,
    });
    await _web3auth.configureAdapter(openloginAdapter);
    await _web3auth.init();

    setWeb3auth(_web3auth);
    if (_web3auth.connected) {
      await connectWallet(_web3auth);
    }
  };

  const connectWallet = async (_web3auth = web3auth) => {
    try {
      setIsConnecting(true);

      // Set up your Smart Account
      if (!_web3auth.connected) {
        await _web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
          loginProvider: "jwt",

          extraLoginOptions: {
            id_token: jwtToken,
            verifierIdField: "sub", // sub, email, or custom
          },
        });
      }
      const web3authProvider = _web3auth.provider;
      const [_address] = await web3authProvider.request({
        method: "eth_accounts",
      });

      const smartAccountSigner = await providerToSmartAccountSigner(
        web3authProvider,
        {
          signerAddress: _address,
        }
      );
      const smartAccount = await signerToBiconomySmartAccount(publicClient, {
        entryPoint: ENTRYPOINT_ADDRESS_V06,
        signer: smartAccountSigner,
      });
      const smartAccountClient = createSmartAccountClient({
        account: smartAccount,
        entryPoint: ENTRYPOINT_ADDRESS_V06,
        chain: sepolia,
        bundlerTransport: http("https://skandha-2spvbqz6ca-uc.a.run.app/97"),
        // middleware: isSponsored
        //   ? {
        //       // gasPrice: async () => {
        //       //   return (await bundlerClient.getUserOperationGasPrice()).fast;
        //       // },
        //       // sponsorUserOperation: paymasterClient.sponsorUserOperation,
        //       sponsorUserOperation,
        //       // sponsorUserOperation: (args) => stackupPaymasterPmOperation(args.userOperation, 0),
        //     }
        //   : undefined,
      });
      const smartAccountAddress = smartAccount.address;
      console.log("smartAccountAddress =>", smartAccountAddress);
      setClient(smartAccountClient);
      setAccount(smartAccountAddress);
      // eslint-disable-next-line no-useless-catch
    } catch (err) {
      throw err;
    } finally {
      setIsConnecting(false);
    }
  };

  // const getNewJwt = async () => {
  //   try {
  //     const res = await axios.get(`${appConfig.config.block_chain_rest_api}/jwt/${userId}`);
  //     setJwt(res.data.data);
  //     return res.data.data;
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const disconnect = async () => {
    setAccount(undefined);
    setClient(undefined);
    await web3auth.logout();
  };

  const postWalletAddress = async () => {
    if (account) {
      // Disable strings must use double-quote es-lint error
      // eslint-disable-next-line quotes
      const res = await ApiConfig.post(
        `/bank/assign-wallet-address?walletAddress=${account}`
      );
    }
  };

  useEffect(() => {
    logoutWallet = disconnect;
  }, [disconnect]);

  useEffect(() => {
    initiWeb3Auth();
  }, []);

  useEffect(() => {
    postWalletAddress();
  }, [account]);

  return (
    <WalletContext.Provider
      // Disable react/jsx-no-constructed-context-values rule for next line
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        walletClient,
        disconnect,
        connectWallet,
        publicClient,
        isConnecting,
        account,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export default connect((state) => ({
  jwtToken: state.getIn(["users", "user", "token"]),
}))(WalletProvider);

/**
 * A hook that returns the wallet context value.
 *
 * @return {WalletContextValue} The wallet context value.
 */
export const useWallet = () => useContext(WalletContext);
