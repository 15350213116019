import { call, fork, put, select, takeLatest } from "redux-saga/effects";
import { Set } from "immutable";
import { eventChannel } from "redux-saga";
import axios from "axios";
import {
  ADD_NOTIFICATION,
  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  LOGOUT_REQUEST,
  SAVE_USER_INFO,
  SAVE_USER_ROLES,
  SAVE_USER_TOKEN,
} from "../actions/actionConstants";
import { setConfirmNavigation } from "../actions/UiActions";
import { login, logout, removeChatJwtToken, removeToken, saveUser } from "../utils/users";
import Data from "../utils/data";

function transformObject(obj) {
  const { name, roles, sub, imageUrl, organizationId, shopId, isEmployee, id } = obj;

  return {
    id,
    roles,
    name: name ?? "",
    email: sub ?? "",
    image: imageUrl ?? "",
    organization_id: organizationId,
    shop_id: shopId ?? 0,
    isEmployee
  };
}

function* authorize(action) {
  const {
    resolve,
    reject,
    formValues: {
      email,
      password,
      remember_me: rememberMe,
      notification_token,
    },
  } = action;
  try {
    const { refresh: token, userInfo } = yield call(
      login,
      email,
      password,
      rememberMe,
      notification_token
    );
    const userData = transformObject(userInfo);

    yield put({ type: LOGIN_SUCCESS });
    yield put({ type: SAVE_USER_TOKEN, token });
    yield put({ type: SAVE_USER_INFO, userInfo: userData });
    yield put({ type: SAVE_USER_ROLES, userRoles: Set(userData.roles) });
    saveUser(token, rememberMe);
    resolve(`Welcome ${userData.name}`);
  } catch (error) {
    yield put({ type: LOGIN_ERROR, error });
    reject(error.response?.data);
  }
}

function* handleLogout(_, forced = false) {
  if (!forced) {
    const confirmNavigation = yield select((state) =>
      state.getIn(["ui", "confirmNavigation"])
    );
    const canLogout = !confirmNavigation || window.confirm("changes not saved, leave anyway?");
    if (!canLogout) return;
    try {
      yield call(logout);
    } catch (ex) {
      if (ex.response?.status === 401) return;
      if (!window.confirm("an error occured!\nwould you like to force logout?")) return;
    }
  }
  yield call(removeToken);
  yield call(removeChatJwtToken);
  yield put(setConfirmNavigation(false));
  yield put({ type: LOGOUT });
}

function* saveProfile() {
  const loggedIn = yield select((state) =>
    state.getIn(["users", "user", "loggedIn"])
  );

  if (loggedIn) {
    try {
      const userInfo = yield call(Data.getUserInfo);
      yield put({ type: SAVE_USER_INFO, userInfo });
      yield put({ type: SAVE_USER_ROLES, userRoles: Set(userInfo.roles) });
    } catch {
      yield put({ type: LOGOUT });
    }
  }
}

const createUnauthorizedChannel = () => eventChannel((emitter) => {
  const interceptor = axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        emitter(401);
      }
      return Promise.reject(error);
    }
  );

  return () => {
    axios.interceptors.response.eject(interceptor);
  };
});

function* dispatchLogout() {
  const loggedIn = yield select((state) =>
    state.getIn(["users", "user", "loggedIn"])
  );
  if (loggedIn) {
    yield call(handleLogout, {}, true);
    yield put({
      type: ADD_NOTIFICATION,
      message: "You were signed out, please sign-in again.",
    });
  }
}

function* handleUnauthorized() {
  const unauthorizedChannel = yield call(createUnauthorizedChannel);
  yield takeLatest(unauthorizedChannel, dispatchLogout);
}

export default function* loginSaga() {
  yield fork(handleUnauthorized);
  yield fork(saveProfile);
  yield takeLatest(LOGIN_REQUEST, authorize);
  yield takeLatest(LOGOUT_REQUEST, handleLogout);
}
