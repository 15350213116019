import React from "react";

const SignedUpIcon = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4146 2.23242H12.2479C9.58122 2.23242 7.91455 3.89909 7.91455 6.56575V9.94075H11.6146L9.88955 8.21576C9.76455 8.09076 9.70622 7.93242 9.70622 7.77409C9.70622 7.61575 9.76455 7.45742 9.88955 7.33242C10.1312 7.09075 10.5312 7.09075 10.7729 7.33242L13.5646 10.1241C13.8062 10.3658 13.8062 10.7658 13.5646 11.0074L10.7729 13.7991C10.5312 14.0408 10.1312 14.0408 9.88955 13.7991C9.64788 13.5574 9.64788 13.1574 9.88955 12.9158L11.6146 11.1908H7.91455V14.5658C7.91455 17.2324 9.58122 18.8991 12.2479 18.8991H14.4062C17.0729 18.8991 18.7396 17.2324 18.7396 14.5658V6.56575C18.7479 3.89909 17.0812 2.23242 14.4146 2.23242Z"
      fill="currentcolor"
    />
    <path
      d="M2.70605 9.94141C2.36439 9.94141 2.08105 10.2247 2.08105 10.5664C2.08105 10.9081 2.36439 11.1914 2.70605 11.1914H7.91439V9.94141H2.70605Z"
      fill="currentcolor"
    />
  </svg>
);

export default SignedUpIcon;
