import React from "react";

const DragAndDropIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    {...props}
  >
    <path
      d="M20.5 17C21.0523 17 21.5 16.5523 21.5 16C21.5 15.4477 21.0523 15 20.5 15C19.9477 15 19.5 15.4477 19.5 16C19.5 16.5523 19.9477 17 20.5 17Z"
      stroke="#344054"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.5 17C29.0523 17 29.5 16.5523 29.5 16C29.5 15.4477 29.0523 15 28.5 15C27.9477 15 27.5 15.4477 27.5 16C27.5 16.5523 27.9477 17 28.5 17Z"
      stroke="#344054"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5 25C21.0523 25 21.5 24.5523 21.5 24C21.5 23.4477 21.0523 23 20.5 23C19.9477 23 19.5 23.4477 19.5 24C19.5 24.5523 19.9477 25 20.5 25Z"
      stroke="#344054"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.5 25C29.0523 25 29.5 24.5523 29.5 24C29.5 23.4477 29.0523 23 28.5 23C27.9477 23 27.5 23.4477 27.5 24C27.5 24.5523 27.9477 25 28.5 25Z"
      stroke="#344054"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5 33C21.0523 33 21.5 32.5523 21.5 32C21.5 31.4477 21.0523 31 20.5 31C19.9477 31 19.5 31.4477 19.5 32C19.5 32.5523 19.9477 33 20.5 33Z"
      stroke="#344054"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.5 33C29.0523 33 29.5 32.5523 29.5 32C29.5 31.4477 29.0523 31 28.5 31C27.9477 31 27.5 31.4477 27.5 32C27.5 32.5523 27.9477 33 28.5 33Z"
      stroke="#344054"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DragAndDropIcon;
