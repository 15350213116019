import React from "react";

const ChevronDown = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    {...props}
  >
    <path
      d="M1.16699 1.50065L6.29322 6.62688C6.68374 7.0174 7.31691 7.0174 7.70743 6.62688L12.8337 1.50065"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </svg>
);
export default ChevronDown;
