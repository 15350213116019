const styles = (theme) => ({
  card: {
    boxShadow: "none",
    border: `1px solid ${theme.palette.borderLight}`,
    borderRadius: 8,
    background: "#F6F6F6",
    // background: theme.palette.type === "dark" ? "" : "",
    "& svg": {
      fill: "transparent",
    },
    "& .MuiCardHeader-root": {
      padding: "1rem",
    },
  },
  cardTitle: {
    fontSize: 17,
    textTransform: "Capitalize",
    fontWeight: 400,
    color: theme.palette.type === "dark" ? "#fff" : "#090A0A",
    marginBottom: 0,
  },
  cardActions: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    marginTop: 5,
    "& button": {
      background: "none",
      padding: 0,
    },
  },
  media: {
    height: 120,
    borderRadius: 8,
    position: "relative",
    "& > img": {
      width: "100%",
      height: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  img_rounded: {
    position: "absolute",
    width: 60,
    height: 60,
    borderRadius: "50%",
    bottom: 10,
    left: 20,

    "& > img": {
      width: "100%",
      height: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "50%",
    },
  },
});

export default styles;
