import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import ExitToApp from "@material-ui/icons/ExitToApp";
import Info from "@material-ui/icons/Info";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import link from "api/ui/link";
import messageStyles from "styles/components/Messages.module.scss";

import QUEUE_ACTIONS_TYPES from "containers/Pages/VideoCalls/ManageQueue/reducers/queueActionsTypes";
import { onMessageListener } from "utils/services/firebase/firebase";
import urlUtils, { paths } from "utils/url";

import UpgradeIcon from "api/icons/DashboardIcons/UpgradeIcon";
import NotificationIcon from "api/icons/NotificationIcon";
import QrCodeIcon from "api/icons/QrCodeIcon";
import { RequestGroup } from "utils/data";
import { logoutWallet } from "containers/App/WalletWrapper";
import { ADD_NOTIFICATION, LOGOUT_REQUEST } from "../../actions/actionConstants";
import styles from "./header-jss";
import Chat from "./Chat/Chat";

import audio from "../../assets/notification.wav";

const notificationAudio = new Audio(audio);
const dateOptions = { year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric" };

const showNotification = (title, options) => {
  // eslint-disable-next-line no-new
  new Notification(title, options);
};

class UserMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      openMenu: null,
      numberOfUnreadNotifications: 0,
      notifications: [],
      userData: {},
    };
  }

  requestGroup = new RequestGroup();

  handleNumberOfUnreadNotifications() {
    const numberOfUnreadNotifications = this.state.notifications.reduce((total, item) => {
      if (!item?.isRead) {
        return total + 1;
      }
      return total;
    }, 0);
    return numberOfUnreadNotifications;
  }

  componentDidMount() {
    const numberOfUnreadNotifications = this.handleNumberOfUnreadNotifications();
    this.setState({
      ...this.state,
      numberOfUnreadNotifications,
    });
    this.getUserData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.notifications !== this.state.notifications) {
      const numberOfUnreadNotifications = this.handleNumberOfUnreadNotifications();
      this.setState({
        ...this.state,
        numberOfUnreadNotifications,
      });
    }
  }

  handleMenu = (menu) => (event) => {
    const { openMenu } = this.state;
    this.setState({
      ...this.state,
      openMenu: openMenu === menu ? null : menu,
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, openMenu: null });
  };

  handleGoToProfilePage = () => {
    const { user, history } = this.props;
    history.push(`/my-profile/${user.id}/${user.name}/profile-page`);
    this.setState({ anchorEl: null, openMenu: null });
  };

  handleReadNotification = (messageId) => {
    const notifications = this.state.notifications?.filter((item) => item?.messageId !== messageId);
    this.setState({ ...this.state, notifications }, () => {
      this.props?.contextValue?.dispatch({
        type: QUEUE_ACTIONS_TYPES.SET_NOTIFICATIONS,
        payload: this.state.notifications,
      });
    });
  };

  getUserData = async () => {
    const { addNotification } = this.props;
    try {
      const userData = await this.requestGroup.getUser();
      this.setState({ userData });
    } catch (err) {
      addNotification(err?.response?.data?.message || "An error occurred");
    }
  };

  handleScanQrCode = () => {
    const { history } = this.props;
    history.push(paths?.scanQr);
  };

  render() {
    const { classes, dark, logout, user, contextValue, userImage } = this.props;
    const { anchorEl, openMenu, notifications, numberOfUnreadNotifications, userData } = this.state;
    onMessageListener(async (payload) => {
      if (payload?.data?.title === "Customer Joining the Queue") {
        notificationAudio.play().catch(() => {
          console.log("Chrome cannot play sound without user interaction first");
        });
        const body = await JSON.parse(payload?.data?.body);
        const title = payload?.data?.title;
        const messageId = payload?.messageId;
        showNotification(title, { body: `${body?.userName} has joined the queue`, direction: "ltr" });
        this.setState(
          {
            ...this.state,
            notifications: [{ body, title, messageId, isRead: false }, ...this.state.notifications],
          },
          () => {
            contextValue?.dispatch({
              type: QUEUE_ACTIONS_TYPES.SET_NOTIFICATIONS,
              payload: this.state.notifications,
            });
          }
        );
      } else if (payload?.data?.title === "Employee Accept the Call") {
        const body = await JSON.parse(payload?.data?.body);
        contextValue?.dispatch({
          type: QUEUE_ACTIONS_TYPES.SET_NEW_CALL,
          payload: body,
        });
      }
    });

    return (
      <div className={classes.infoContainer}>
        <IconButton
          aria-haspopup="true"
          onClick={this.handleMenu("notification")}
          color="inherit"
          className={classNames(
            classes.notifIcon,
            dark ? classes.dark : classes.light,
            // this.props.changeIconsColor ? classes.iconColor : ""
          )}
        >
          <Badge
            className={classNames(classes.badge, classes.badgeColor)}
            badgeContent={numberOfUnreadNotifications}
            color="red"
          >
            <NotificationIcon />
          </Badge>
        </IconButton>
        <Menu
          id="menu-notification"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          className={classes.notifMenu}
          PaperProps={{
            style: {
              width: 350,
            },
          }}
          open={openMenu === "notification"}
          onClose={this.handleClose}
        >
          {notifications?.length > 0 ? (
            // eslint-disable-next-line no-confusing-arrow
            notifications?.map((item, i) =>
              !item?.isRead ? (
                <>
                  <MenuItem
                    onClick={() => {
                      this.handleReadNotification(item?.messageId);
                      this.handleClose();
                    }}
                    style={{ background: !item?.isRead ? "#F9F9F9" : "" }}
                  >
                    <Link to="/manage-queue" className={classes.notificationLink}>
                      <div className={messageStyles.messageInfo}>
                        <ListItemAvatar>
                          <Avatar className={messageStyles.icon}>
                            <Info />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={`${
                            item?.body?.userName?.length > 6
                              ? `${item?.body?.userName?.substring(0, 6)}...`
                              : item?.body?.userName
                          } has joined the queue`}
                          secondary={new Date(item?.body?.joinsAt).toLocaleString("en-US", dateOptions)}
                        />
                      </div>
                    </Link>
                  </MenuItem>
                  {Number(i + 1) !== notifications?.length && <Divider variant="inset" />}
                </>
              ) : (
                ""
              )
            )
          ) : (
            <MenuItem
              onClick={() => {
                this.handleClose();
              }}
            >
              <ListItemText primary="There is no notifications yet" />
            </MenuItem>
          )}
        </Menu>
        {/* <Button
          variant="contained"
          color="#fff"
          startIcon={<QrCode />}
          className={classes.qrBtn}
        >
          Scan QR Code
        </Button>
        <Button
          variant="contained"
          className={classes.upBtn}
        >
          Upgrade
        </Button> */}
        <Chat
          anchorEl={anchorEl}
          openMenu={openMenu}
          dark={dark}
          handleMenu={this.handleMenu("chat")}
          handleClose={this.handleClose}
          changeIconsColor={this.props.changeIconsColor}
          iconClassName={classes.chatIcon}
        />
        <Button onClick={this.handleMenu("user-setting")} className={classes.userAvatarBar}>
          <Avatar
            alt={user.name}
            src={userImage ? urlUtils.getProperImageUrl(userImage) : "Placeholder"}
            className={classes.avatar}
          />
        </Button>
        {/* <div className={classes.welcomeUser}>
          <span>Hi</span>
          <span>{user?.name ? user?.name?.split(" ")?.[0] : ""}</span>
          <span>.</span>
        </div> */}
        <Menu
          id="menu-appbar"
          className={classes.userDropdownMenu}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openMenu === "user-setting"}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleGoToProfilePage} component={Link} to={link.profile}>
            <span>{`${user?.name?.split(" ")?.[0]}'s Profile`}</span>
          </MenuItem>
          {/* <MenuItem onClick={this.handleClose} component={Link} to={link.calendar}>My Calendar</MenuItem>
          <MenuItem onClick={this.handleClose} component={Link} to={link.email}>
            My Inbox
            <ListItemIcon>
              <Badge className={classNames(classes.badge, classes.badgeMenu)} badgeContent={2} color="secondary">&nbsp;</Badge>
            </ListItemIcon>
          </MenuItem> */}
          <Divider />
          <MenuItem
            onClick={() => {
              logout();
              logoutWallet();
              this.handleClose();
            }}
          >
            <div className={classes.logOutContainer}>
              <span>Log Out</span>
              <ExitToApp />
            </div>
          </MenuItem>
        </Menu>
        <div className={classes.btnGroup}>
          <Button
            variant="contained"
            color="primary"
            className={classes.scanBtn}
            startIcon={<QrCodeIcon />}
            onClick={this.handleScanQrCode}
          >
            Scan QR Code
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.upgradeBtn}
            startIcon={<UpgradeIcon />}
          >
            Upgrade
          </Button>
        </div>
      </div>
    );
  }
}

UserMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  dark: PropTypes.bool,
  logout: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

UserMenu.defaultProps = {
  dark: false,
};

const mapStateToProps = (state) => ({
  userImage: state.getIn(["users", "user", "info", "image"]),
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch({ type: LOGOUT_REQUEST }),
  addNotification: (message) => dispatch({ type: ADD_NOTIFICATION, message }),
});

const ReduxUserMenu = connect(mapStateToProps, mapDispatchToProps)(UserMenu);

export default withRouter(withStyles(styles)(ReduxUserMenu));
