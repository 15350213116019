import React from "react";

const DiamondIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.86 1.43335H5.1333C3.99997 1.43335 3.49997 2.00002 3.1933 2.69335L1.48664 6.53335C1.17997 7.22668 1.34663 8.26001 1.85997 8.82001L6.4333 13.8467C7.29997 14.7933 8.71331 14.7933 9.57331 13.8467L14.14 8.81335C14.6533 8.24668 14.82 7.22002 14.5066 6.52668L12.8 2.68668C12.4933 2.00001 11.9933 1.43335 10.86 1.43335Z"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
    />
    <path
      d="M2.33331 5.33325H13.6666"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
    />
  </svg>
);

export default DiamondIcon;
