import React from "react";

const VideoCallIcon = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.3535 13.6134H4.14016C2.0335 13.6134 1.3335 12.2134 1.3335 10.8067V5.19339C1.3335 3.08672 2.0335 2.38672 4.14016 2.38672H8.3535C10.4602 2.38672 11.1602 3.08672 11.1602 5.19339V10.8067C11.1602 12.9134 10.4535 13.6134 8.3535 13.6134Z"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
    />
    <path
      d="M13.0135 11.4001L11.1602 10.1001V5.89342L13.0135 4.59342C13.9202 3.96009 14.6668 4.34675 14.6668 5.46009V10.5401C14.6668 11.6534 13.9202 12.0401 13.0135 11.4001Z"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
    />
    <path
      d="M7.6665 7.33398C8.21879 7.33398 8.6665 6.88627 8.6665 6.33398C8.6665 5.7817 8.21879 5.33398 7.6665 5.33398C7.11422 5.33398 6.6665 5.7817 6.6665 6.33398C6.6665 6.88627 7.11422 7.33398 7.6665 7.33398Z"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
    />
  </svg>
);

export default VideoCallIcon;
