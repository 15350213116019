const styles = (theme) => ({
  card: {
    boxShadow: "none",
    border: `1px solid ${
      theme.palette.type === "dark" ? "#353232" : "#0000003b"
    }`,
  },
  cardHeader: {
    padding: "8px",
  },
  brandLogo: {
    borderRadius: "50%",
    width: 56,
    height: 56,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    overflow: "hidden"
  },
  brandImage: {
    objectFit: "contain",
  },
  avatarBg: {
    background: "none",
    color: "#444",
  },
  right: {
    float: "right",
  },
  sortSelectBrand: {
    width: 190,
    position: "absolute",
    right: 0,
    top: "-10px",
  },
  cardActions: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    marginTop: 25,
    marginRight: 8,
    "& button": {
      background: "none",
      padding: 0,
    },
    "& svg": {
      fill: "transparent",
    },
  },
});

export default styles;
