import { useContext } from "react";
import ConfirmContext from "../store/ConfirmContext";
import { HIDE_CONFIRM, SHOW_CONFIRM } from "../store/reducer";

let resolveCallback;
function useConfirm() {
  const { state, dispatch } = useContext(ConfirmContext);

  const onConfirm = () => {
    closeConfirm();
    resolveCallback(true);
  };

  const onCancel = () => {
    closeConfirm();
    resolveCallback(false);
  };
  const confirm = ({ title, text, confirmationLabel, cancelLabel }) => {
    dispatch({
      type: SHOW_CONFIRM,
      payload: {
        title,
        text,
        confirmationLabel,
        cancelLabel
      }
    });
    return new Promise((res, rej) => {
      resolveCallback = res;
    });
  };

  const closeConfirm = () => {
    dispatch({
      type: HIDE_CONFIRM
    });
  };

  return { confirm, onConfirm, onCancel, confirmState: state };
}

export default useConfirm;
