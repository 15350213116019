import { Typography } from "@material-ui/core/index";
import { withStyles } from "@material-ui/core/styles";
import DragAndDropIcon from "api/icons/DragAndDropIcon";
import React from "react";
import styles from "./TagChild-jss";

const TagChild = ({ tagChild, classes, noChildren }) => {
  const title = !noChildren ? (
    tagChild?.name || tagChild?.alias
  ) : (
    <Typography className={classes.noChildren}>No Children</Typography>
  );
  return (
    <li className={classes.tagChild}>
      {!noChildren && <DragAndDropIcon />}
      <Typography
        title={typeof title === "string" ? title : "No Children"}
        className={classes.tagName}
      >
        {title}
      </Typography>
    </li>
  );
};

export default withStyles(styles)(TagChild);
