import React from "react";

const ShareIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3066 4.11328C12.64 5.03995 13.56 6.51328 13.7466 8.21328"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.32666 8.24648C2.49999 6.55315 3.40666 5.07982 4.72666 4.14648"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.45996 13.9609C6.23329 14.3543 7.11329 14.5743 8.03996 14.5743C8.93329 14.5743 9.77329 14.3743 10.5266 14.0076"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.03998 5.13245C9.06355 5.13245 9.89331 4.30268 9.89331 3.27911C9.89331 2.25555 9.06355 1.42578 8.03998 1.42578C7.01641 1.42578 6.18665 2.25555 6.18665 3.27911C6.18665 4.30268 7.01641 5.13245 8.03998 5.13245Z"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.22003 13.2809C4.2436 13.2809 5.07337 12.4511 5.07337 11.4276C5.07337 10.404 4.2436 9.57422 3.22003 9.57422C2.19646 9.57422 1.3667 10.404 1.3667 11.4276C1.3667 12.4511 2.19646 13.2809 3.22003 13.2809Z"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.78 13.2809C13.8035 13.2809 14.6333 12.4511 14.6333 11.4276C14.6333 10.404 13.8035 9.57422 12.78 9.57422C11.7564 9.57422 10.9266 10.404 10.9266 11.4276C10.9266 12.4511 11.7564 13.2809 12.78 13.2809Z"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ShareIcon;
