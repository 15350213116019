export const SHOW_CONFIRM = "SHOW_CONFIRM";
export const HIDE_CONFIRM = "HIDE_CONFIRM";

export const initialState = {
  show: false,
  title: "",
  text: "",
  confirmationLabel: "",
  cancelLabel: "",
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_CONFIRM:
      return {
        show: true,
        title: action.payload.title,
        text: action.payload.text,
        confirmationLabel: action.payload.confirmationLabel,
        cancelLabel: action.payload.cancelLabel
      };
    case HIDE_CONFIRM:
      return initialState;
    default:
      return initialState;
  }
};
