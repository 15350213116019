import { darken } from "@material-ui/core/styles/colorManipulator";

const LoginBg = "./images/LoginBg.png";
const drawerWidth = 245;

export const gradientBgLight = (theme) =>
  `linear-gradient(-45deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 33%, ${theme.palette.secondary.main} 100%);`;
export const gradientBgDark = (theme) =>
  `linear-gradient(-45deg, ${darken(
    theme.palette.primary.main,
    0.4
  )} 0%, ${darken(theme.palette.primary.main, 0.4)} 33%, ${darken(
    theme.palette.secondary.main,
    0.4
  )} 100%);`;

const appFrame = {
  display: "flex",
  width: "100%",
  minHeight: "100%",
  zIndex: 1,
};

const styles = (theme) => ({
  mainContentContainer: {
    position: "relative",
    width: "100%",
    height: "100dvh",
    display: "flex",
    justifyContent: "space-between",
    overflow: "hidden",
  },
  sideBarContainer: {
    position: "relative",
    flex: "0.05",
    minWidth: "80px",
    transition: "all 0.3s ease",
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
      flex: "0",
      position: "fixed",
      zIndex: 1200,
      left: 0,
      top: 0,
    },
  },
  sideBarOpen: {
    minWidth: drawerWidth,
  },
  headerContainer: {
    position: "sticky",
    top: "0",
    right: "0",
    left: "0",
    zIndex: 2222,
    borderBottom: "1.5px solid rgba(188, 188, 188, 0.30)",
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease-in-out",
  },
  headerActive: {
    borderBottom: "1.5px solid #eeeff9",
    backgroundColor: "#ffffff",
    "-webkit-backdrop-filter": "blur(50px)",
    backdropFilter: "blur(50px)",
  },
  content: {
    flex: "0.95",
    backgroundColor: "#f5f7f9",
    overflowY: "scroll",
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    [theme.breakpoints.down("xs")]: {
      flex: "1",
    },
    position: "relative",
    zIndex: 5,
  },
  menuButton: {
    position: "absolute",
    right: -12,
    top: 25,
    zIndex: 6666,
    width: 24,
    height: 24,
    minHeight: 24,
    padding: 4,
    border: "1px solid #000",
    background: "#FFF",
    "& svg": {
      "& path": {
        fill: "#000",
      },
    },
    "&:hover": {
      backgroundColor: "#d5d5d5 !important",
    },
  },
  menuButtonClosed: {
    [theme.breakpoints.down("xs")]: {
      position: "fixed",
      top: 25,
      left: 8,
    },
  },
  hideMobile: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  showMobile: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  root: {
    width: "100%",
    marginTop: 0,
    zIndex: 1,
    overflow: "auto",
  },
  blogWrap: {
    position: "relative",
  },
  appFrameInner: {
    ...appFrame,
  },
  appFrameOuter: {
    ...appFrame,
  },
  appFrameLanding: {
    backgroundColor: theme.palette.background.default,
    minHeight: 1000,
  },
  appFrameSlider: {
    display: "flex",
    width: "100%",
    height: "100%",
    [theme.breakpoints.up("lg")]: {
      position: "absolute",
      overflow: "hidden",
    },
    backgroundColor: theme.palette.background.default,
  },
  topNav: {
    flexDirection: "column",
  },
  sideNav: {
    flexDirection: "row",
    background: theme.palette.type === "dark" ? "#2b364a" : "#FAFAFA",
  },
  petal: {
    background: `url(${LoginBg}) no-repeat`,
  },
  outerContent: {
    width: "100% !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: `url(${LoginBg}) no-repeat`,
    objectFit: "contain",
  },
  bgWrap: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
  },
  bgbar: {
    backgroundAttachment: "fixed",
    width: "100%",
    top: 0,
    left: 0,
    position: "absolute",
    "&$headerBg": {
      height: 64,
    },
    "&$halfBg": {
      height: 400,
    },
    "&$fullBg": {
      height: "100%",
    },
  },
  gradientBg: {
    background:
      theme.palette.type === "dark"
        ? "linear-gradient(to bottom, #0f0c29, #302b63, #24243e)"
        : `url(${LoginBg}) no-repeat`,
    backgroundAttachment: "fixed",
  },
  solidBg: {
    backgroundColor:
      theme.palette.type === "dark"
        ? darken(theme.palette.primary.main, 0.4)
        : theme.palette.primary.main,
  },
  decorationWave: {
    width: "100%",
    height: "auto",
    position: "absolute",
    left: -2,
    bottom: 0,
    transform: "scale(1.1,0.8)",
    transformOrigin: "bottom",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  decorationPetal: {
    width: "100%",
    height: "auto",
    position: "absolute",
    left: 0,
    top: 0,
  },
  preloader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 1000,
    background: "transparent",
    height: 3,
  },
  materialBg: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    opacity: 0.5,
  },
  contentPaddingLeftBig: {
    paddingLeft: theme.spacing(2),
  },
  contentPaddingRight: {
    paddingRight: theme.spacing(13),
  },
  hideApp: {
    display: "none",
  },
  circularProgress: {
    position: "fixed",
    top: "calc(50% - 45px)",
    left: "calc(50% - 45px)",
  },
  spinnerLeftSide: {
    width: "100%",
    height: "calc(100vh - 170px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  brand: {
    height: 54,
    display: "flex",
    padding: "10px 10px 5px",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: 20,
    },
    "& h3": {
      margin: 0,
      fontSize: 16,
      fontWeight: 500,
      paddingLeft: 10,
      color: theme.palette.common.white,
    },
  },
  pageTitle: {
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },
    "& h4": {
      fontWeight: 700,
      textTransform: "capitalize",
      [theme.breakpoints.down("md")]: {
        marginBottom: theme.spacing(3),
      },
    },
  },
  darkTitle: {
    color:
      theme.palette.type === "dark"
        ? theme.palette.primary.main
        : theme.palette.primary.dark,
  },
  lightTitle: {
    color: theme.palette.common.white,
  },
  loginImageBgContainer: {
    width: "100% !important",
    position: "absolute",
    height: "100vh",
  },
  loginImageBg: {
    width: "100%",
    height: "100vh",
    objectFit: "cover",
  },
  bgImg: {
    width: "100%",
    height: "100%",
    borderRadius: "0px 0px 0px 50px",
    overflow: "hidden",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    objectFit: "cover",
    zIndex: -1,
    [theme.breakpoints.down("xs")]: {
      borderRadius: "0px 0px 0px 0px",
    },
  },
});

export default styles;
