import { clampOneLine } from "utils/stylesJss";

const styles = (theme) => ({
  tagParent: {
    display: "flex",
    flexDirection: "column",
    transition: "gap 0.3s ease-in-out",
    gap: "var(--gap)",
  },
  tagParentContent: {
    width: "100%",
    borderRadius: 8,
    padding: "10px 12px",
    border: "1px solid #938B9A59",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    "& svg": {
      flexShrink: 0,
    },
  },
  iconAndName: {
    display: "flex",
    alignItems: "center",
    gap: 20,
    [theme.breakpoints.down("xs")]: {
      gap: 8,
    },
  },
  tagName: {
    fontSize: 20,
    fontWeight: 600,
    ...clampOneLine,
  },
  childrenContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
    paddingInline: 52,
    transition: "opacity 0.3s ease-in-out",
    visibility: "var(--show)",
    height: "var(--pixels)",
    width: "var(--pixels)",
    opacity: "var(--opacity)",
    [theme.breakpoints.down("md")]: {
      paddingInline: 32,
    },
    [theme.breakpoints.down("sm")]: {
      paddingInline: 24,
    },
    [theme.breakpoints.down("xs")]: {
      paddingInline: 12,
    },
  },
  arrow: {
    transition: "all 0.3s ease-in-out",
    transform: "rotate(var(--rotate))",
    flexShrink: 0,
  },
});

export default styles;
