import React, { useMemo, useReducer } from "react";

import ChatContext from "./ChatContext";
import chatReducer, { CHAT_INITIAL_STATE } from "../reducers/chatReducer";

const ChatContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(chatReducer, CHAT_INITIAL_STATE);

  const providerState = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <ChatContext.Provider value={providerState}>
      {children}
    </ChatContext.Provider>
  );
};

export default ChatContextProvider;
