import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Spinner from "components/Spinner/Spinner";
const styles = {
  circularProgress: {
    position: "fixed",
    top: "calc(50% - 45px)",
    left: "calc(50% - 45px)",
  },
};

function Loading({ classes }) {
  return (
    <div className={classes.circularProgress}>
      {/* <Spinner /> */}
    </div>
  );
}

export default withStyles(styles)(Loading);
