import React from "react";
import MomentUtils from "@date-io/moment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {
  Avatar,
  Box,
  Chip,
  Tooltip,
  Typography,
} from "@material-ui/core/index";
import ToggleButton from "@material-ui/lab/ToggleButton";
import withStyles from "@material-ui/core/styles/withStyles";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
import ArrowDown from "api/icons/ArrowDown";
import InfoLabelIcon from "api/icons/InfoLabelIcon";
import RoundedDelete from "api/icons/RoundedDelete";
import classNames from "classnames";
import PropTypes from "prop-types";
import urlUtils from "utils/url";
import styles from "./ReduxFormMUI-jss";

/* New Label style with (hit + error) */
export const LabelNewStyle = withStyles(styles)((props) => {
  const {
    label,
    isError,
    error,
    hintMessage,
    classes,
    noMargin,
    children,
    valueLength,
    maxLength,
    showLength,
    maxTitle,
    labelClassName,
    childFirst = true,
  } = props;

  return (
    <>
      {(label || isError) && (
        <Box className={classes.headerContainer}>
          <Box
            className={classNames(
              classes.labelContainer,
              noMargin ? classes.noMargin : ""
            )}
          >
            <>{childFirst && children}</>
            {label && (
              <label className={classNames(classes.label, labelClassName || "")} htmlFor={label}>
                {label}
              </label>
            )}
            {hintMessage && (
              <Tooltip
                enterTouchDelay={0}
                title={hintMessage}
                placement="right"
                classes={{ tooltip: classes.tooltip }}
              >
                <Box>
                  <InfoLabelIcon className={classes.infoIcon} />
                </Box>
              </Tooltip>
            )}
          </Box>
          {isError && !showLength && (
            <Typography className={classes.errorMessage}>{error}</Typography>
          )}
          {showLength && (
            <p
              className={classNames(
                classes.maxLength,
                isError ? classes.maxError : ""
              )}
            >
              <span>{valueLength}</span>
              {`/${maxLength} ${maxTitle || ""}`}
            </p>
          )}
          <>{!childFirst && children}</>
        </Box>
      )}
    </>
  );
});

LabelNewStyle.propTypes = {
  label: PropTypes.string,
  isError: PropTypes.bool,
  error: PropTypes.string,
  hintMessage: PropTypes.string,
  classes: PropTypes.object,
  noMargin: PropTypes.bool,
  children: PropTypes.node,
  labelClassName: PropTypes.string,
  childFirst: PropTypes.bool,
};
/* End */

/* LabelRedux */
export const LabelRedux = ({ input: { value }, ...rest }) => (
  <span {...rest}>{value}</span>
);

LabelRedux.propTypes = {
  input: PropTypes.object.isRequired,
};
/* End */

/* Textfield */
export const TextFieldRedux = withStyles(styles)(
  ({
    classes,
    meta: { touched, error },
    shrink = true,
    input,
    label,
    hintMessage,
    isErrorValue,
    className,
    labelClassName,
    ...rest
  }) => {
    const isError = isErrorValue || (touched && Boolean(error));
    return (
      <>
        <LabelNewStyle
          label={label}
          isError={isError}
          error={error}
          hintMessage={hintMessage}
          labelClassName={labelClassName}
        />
        <TextField
          {...input}
          {...rest}
          onWheel={(e) => e?.target?.blur()}
          className={classNames(
            className || "",
            rest.className ? rest.className : "",
            isError ? classes.fieldError : ""
          )}
          variant="outlined"
          InputLabelProps={{
            shrink,
          }}
        />
      </>
    );
  }
);

TextFieldRedux.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
};

TextFieldRedux.defaultProps = {
  meta: null,
};
/* End */

/* DateTimePicker */
export const DateTimePickerRedux = withStyles(styles)(
  ({
    classes,
    meta: { touched, error },
    input: { value, onChange, onBlur },
    label,
    hintMessage,
    ...rest
  }) => {
    const isError = touched && Boolean(error);
    return (
      <>
        <LabelNewStyle
          label={label}
          isError={isError}
          error={error}
          hintMessage={hintMessage}
        />
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DateTimePicker
            {...rest}
            className={classNames(
              rest.className ? rest.className : "",
              isError ? classes.fieldError : ""
            )}
            value={value || null}
            onChange={onChange}
            onClose={() => onBlur()}
            inputVariant="outlined"
          />
        </MuiPickersUtilsProvider>
      </>
    );
  }
);

DateTimePickerRedux.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
};

DateTimePickerRedux.defaultProps = {
  meta: null,
};
/* End */

/* DatePicker */
export const DatePickerRedux = withStyles(styles)(
  ({
    classes,
    meta: { touched, error },
    input: { value, onChange, onBlur },
    label,
    hintMessage,
    ...rest
  }) => {
    const isError = touched && Boolean(error);
    return (
      <>
        <LabelNewStyle
          label={label}
          isError={isError}
          error={error}
          hintMessage={hintMessage}
        />
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            {...rest}
            className={classNames(
              rest.className ? rest.className : "",
              isError ? classes.fieldError : ""
            )}
            value={value || null}
            onChange={onChange}
            onClose={() => onBlur()}
            inputVariant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </MuiPickersUtilsProvider>
      </>
    );
  }
);

DatePickerRedux.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
};

DatePickerRedux.defaultProps = {
  meta: { touched: null, error: null },
};
/* End */

/* TimePicker */
export const TimePickerRedux = withStyles(styles)(
  ({
    classes,
    meta: { touched, error },
    input: { value, onChange, onBlur },
    label,
    hintMessage,
    ...rest
  }) => {
    const isError = touched && Boolean(error);
    return (
      <>
        <LabelNewStyle
          label={label}
          isError={isError}
          error={error}
          hintMessage={hintMessage}
        />
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <TimePicker
            {...rest}
            className={classNames(
              rest.className ? rest.className : "",
              isError ? classes.fieldError : ""
            )}
            value={value || null}
            onChange={onChange}
            onClose={() => onBlur()}
            inputVariant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </MuiPickersUtilsProvider>
      </>
    );
  }
);

TimePickerRedux.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
};

TimePickerRedux.defaultProps = {
  meta: null,
};
/* End */

/* Select */
export const SelectRedux = withStyles(styles)(
  ({
    classes,
    meta: { touched, error },
    input: { value, onChange, ...input },
    children,
    label,
    hintMessage,
    className,
    multiple,
    renderValue,
    labelClassName,
    displayEmpty,
    ...rest
  }) => {
    const isError = touched && Boolean(error);
    return (
      <FormControl
        {...rest}
        variant="outlined"
        className={classNames(
          className || "",
          rest.className ? rest.className : "",
          isError ? classes.fieldError : ""
        )}
      >
        <LabelNewStyle
          label={label}
          isError={isError}
          error={error}
          hintMessage={hintMessage}
          labelClassName={labelClassName}
        />
        <Select
          multiple={multiple}
          displayEmpty={displayEmpty}
          renderValue={
            renderValue && ((selected) => renderValue(selected, onChange))
          }
          value={multiple ? value?.toJS?.() || value : value}
          onChange={onChange}
          {...input}
          IconComponent={ArrowDown}
        >
          {children}
        </Select>
      </FormControl>
    );
  }
);

SelectRedux.propTypes = {
  input: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  meta: PropTypes.object,
  label: PropTypes.string,
  className: PropTypes.string,
  multiple: PropTypes.bool,
  renderValue: PropTypes.func,
};

SelectRedux.defaultProps = {
  meta: null,
  label: null,
  className: null,
  multiple: false,
  renderValue: undefined,
};
/* End */

export const CheckboxMenuItem = withStyles(styles)(
  ({
    classes,
    value,
    text,
    selected,
    image,
    showImage,
    noCheckBox,
    ...props
  }) => (
    <MenuItem key={value} value={value} selected={selected} {...props}>
      {!noCheckBox && <Checkbox checked={selected} />}
      {showImage && (
        <Avatar
          className={classes.avatar}
          alt={text}
          src={image ? urlUtils.getProperImageUrl(image) : "placeholder"}
        />
      )}
      <ListItemText primary={text} />
    </MenuItem>
  )
);

CheckboxMenuItem.propTypes = {
  value: PropTypes.any.isRequired,
  text: PropTypes.string,
  selected: PropTypes.bool,
  image: PropTypes.string,
  showImage: PropTypes.bool,
  noCheckBox: PropTypes.bool,
};

CheckboxMenuItem.defaultProps = {
  text: null,
  selected: false,
};

/* Checkbox */
export const CheckboxRedux = withStyles(styles)(
  ({
    classes,
    meta: { touched, error },
    input,
    label,
    className,
    hintMessage,
    labelClassName,
    checkBoxClassName,
    checked,
    childFirst = true,
    ...rest
  }) => {
    const isError = touched && Boolean(error);
    return (
      <FormControl className={className} {...rest}>
        <LabelNewStyle
          label={label}
          isError={isError}
          error={error}
          hintMessage={hintMessage}
          labelClassName={labelClassName}
          noMargin
          childFirst={childFirst}
        >
          <Checkbox className={checkBoxClassName || ""} id={label} checked={input.checked} {...input} />
        </LabelNewStyle>
      </FormControl>
    );
  }
);

CheckboxRedux.propTypes = {
  input: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  meta: PropTypes.object,
  label: PropTypes.string,
  className: PropTypes.string,
  checkBoxClassName: PropTypes.string,
};

CheckboxRedux.defaultProps = {
  meta: { touched: null, error: null },
  label: null,
  className: null,
};
/* End */

/* Radio */
export const RadioRedux = withStyles(styles)(
  ({
    classes,
    meta: { touched, error },
    input,
    label,
    hintMessage,
    className,
    ...rest
  }) => {
    const isError = touched && Boolean(error);
    return (
      <FormControl className={className} {...rest}>
        <LabelNewStyle
          label={label}
          isError={isError}
          error={error}
          hintMessage={hintMessage}
        >
          <Radio id={label} checked={input.checked} {...input} />
        </LabelNewStyle>
      </FormControl>
    );
  }
);

RadioRedux.propTypes = {
  input: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  meta: PropTypes.object,
  label: PropTypes.string,
  className: PropTypes.string,
};

RadioRedux.defaultProps = {
  meta: null,
  label: null,
  className: null,
};
/* End */

/* Switch */
export const SwitchRedux = withStyles(styles)(
  ({
    classes,
    meta: { touched, error },
    label,
    hintMessage,
    input,
    onChange,
    ...rest
  }) => {
    const isError = touched && Boolean(error);
    return (
      <FormControl>
        <LabelNewStyle
          label={label}
          isError={isError}
          error={error}
          hintMessage={hintMessage}
        >
          <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
              root: classes.switchRoot,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked,
            }}
            checked={input.value === "" ? false : input.value}
            onChange={onChange}
            {...input}
            {...rest}
          />
        </LabelNewStyle>
      </FormControl>
    );
  }
);

SwitchRedux.propTypes = {
  input: PropTypes.object.isRequired,
};
/* End */

/* ToggleButtonGroup */
export const ToggleButtonGroupRedux = withStyles(styles)(({
  meta: { touched, error },
  input: { value, onChange, onBlur, ...input },
  children,
  label,
  className,
  exclusive,
  orientation,
  labelClassName,
  hintMessage,
  data,
  btnKey,
  btnValue,
  btnLabel,
  classes,
  ...rest
}) => {
  const isError = touched && Boolean(error);
  return (
    <FormControl component="fieldset" className={className} {...rest}>
      <LabelNewStyle
        label={label}
        isError={isError}
        error={error}
        hintMessage={hintMessage}
      />
      <ToggleButtonGroup
        {...input}
        exclusive={exclusive}
        orientation={orientation}
        value={exclusive ? value : value?.toJS?.() || value}
        onChange={(ev, val) => onChange?.(val)}
        onBlur={() => onBlur?.()}
        className={classes.toggleGroup}
      >
        {data?.map((type) => (
          <ToggleButton
            key={type[btnKey]}
            value={btnValue ? type[btnValue] : type}
            className={classes.toggleOption}
          >
            {type[btnLabel]}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </FormControl>
  );
});

ToggleButtonGroupRedux.propTypes = {
  input: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  meta: PropTypes.object,
  label: PropTypes.string,
  className: PropTypes.string,
  multiple: PropTypes.bool,
  renderValue: PropTypes.func,
};

ToggleButtonGroupRedux.defaultProps = {
  meta: { touched: null, error: null },
  label: null,
  className: null,
  multiple: false,
  renderValue: undefined,
};
/* End */

/* Text Area field */
export const TextAreaRedux = withStyles(styles)(
  ({
    meta: { touched, error },
    input: { value, onChange, ...input },
    label,
    hintMessage,
    classes,
    ...rest
  }) => {
    const isError = touched && Boolean(error);
    return (
      <>
        <LabelNewStyle
          label={label}
          isError={isError}
          error={error}
          hintMessage={hintMessage}
        />
        <TextareaAutosize
          maxRows={3}
          onChange={(event) => onChange(event.target.value)}
          value={value}
          {...input}
          {...rest}
          className={classNames(
            rest.className ? rest.className : "",
            isError ? classes.fieldErrorTextArea : ""
          )}
        />
      </>
    );
  }
);

TextareaAutosize.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
};

TextareaAutosize.defaultProps = {
  meta: null,
};
/* End */

/* Number field */
export const NumberFieldRedux = withStyles(styles)(
  ({
    classes,
    meta: { touched, error },
    shrink = true,
    input: { value, onChange, onBlur, ...input },
    label,
    hintMessage,
    ...rest
  }) => {
    const isError = touched && Boolean(error);
    return (
      <>
        <LabelNewStyle
          label={label}
          isError={isError}
          error={error}
          hintMessage={hintMessage}
        />
        <TextField
          type="number"
          variant="outlined"
          value={value}
          onChange={(event) => {
            onChange(+event.target.value || null);
          }}
          InputLabelProps={{
            shrink,
          }}
          {...input}
          {...rest}
          className={classNames(
            rest.className ? rest.className : "",
            isError ? classes.fieldError : ""
          )}
        />
      </>
    );
  }
);

NumberFieldRedux.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
};

NumberFieldRedux.defaultProps = {
  meta: null,
};
/* End */

/* Multiple Selection */
export const MultipleSelection = withStyles(styles)(
  ({
    classes,
    meta: { touched, error },
    input: { value, onChange, ...input },
    label,
    hintMessage,
    className,
    placeholder,
    showPlaceholderAtList,
    data,
    menuKey,
    menuValue,
    menuName,
    menuImage,
    menuBg,
    menuColor,
    blackIcon,
    showLength,
    maxTitle,
    onChangeSelected,
    isPrimitiveKey,
    isErrorValue,
    notReduxForm,
    labelClassName,
    disabled,
    ...rest
  }) => {
    const isError = isErrorValue || (touched && Boolean(error));
    const handleChange = (e) => {
      if (notReduxForm) {
        onChange(e.target.value);
      } else {
        onChange(e);
        if (onChangeSelected) {
          onChangeSelected(e.target.value);
        }
      }
    };

    const handleDelete = (e, newToDelete) => {
      e.stopPropagation();
      const valuesAfterDelete = value?.filter((old) => old !== newToDelete);
      onChange(valuesAfterDelete);
      if (onChangeSelected) {
        onChangeSelected(valuesAfterDelete);
      }
    };

    const handleGetValue = (idf, selectedElement) =>
      data?.find((element) => element[idf] === selectedElement);

    const val = value?.toJS?.() || value;

    return (
      <FormControl
        variant="outlined"
        className={classNames(
          className || "",
          isError ? classes.fieldError : "",
          classes.formControlMultiple,
          val?.length > 0 ? classes.changePadding : ""
        )}
        {...rest}
      >
        <LabelNewStyle
          label={label}
          isError={isError}
          error={error}
          hintMessage={hintMessage}
          valueLength={val?.length}
          maxLength={data.length}
          showLength={showLength}
          maxTitle={maxTitle}
          labelClassName={labelClassName}
        />
        <Select
          type="select-multiple"
          multiple
          displayEmpty
          value={val || []}
          onChange={handleChange}
          disabled={disabled}
          {...input}
          renderValue={(selected) => {
            if (selected.length === 0 && placeholder) {
              return <p className={classes.placeholder}>{placeholder}</p>;
            }

            return (
              <div className={classes.chipContainer}>
                {selected.map((select) => {
                  const selectedImage = menuImage
                    ? handleGetValue(menuValue, select)?.[menuImage]
                    : null;
                  const selectedName = handleGetValue(menuValue, select)?.[
                    menuName
                  ];
                  const selectedBg = handleGetValue(menuValue, select)?.[menuBg];
                  const selectedColor = handleGetValue(menuValue, select)?.[menuColor];

                  return (
                    <Chip
                      key={select}
                      label={isPrimitiveKey ? select : selectedName}
                      onDelete={(e) => handleDelete(e, select)}
                      deleteIcon={
                        <RoundedDelete className={classNames(classes.deleteIcon, blackIcon ? classes.iconBlack : "")} />
                      }
                      avatar={
                        menuImage ? (
                          <Avatar
                            alt={selectedName}
                            src={
                              selectedImage
                                ? urlUtils.getProperImageUrl(selectedImage)
                                : "placeholder"
                            }
                          />
                        ) : null
                      }
                      className={classes.chip}
                      style={selectedBg || selectedColor ? { background: selectedBg, color: selectedColor } : {}}
                      onMouseDown={(event) => {
                        event.stopPropagation();
                      }}
                    />
                  );
                })}
              </div>
            );
          }}
          inputProps={{ "aria-label": "Without label" }}
          IconComponent={ArrowDown}
        >
          {showPlaceholderAtList && (
            <MenuItem disabled value="">
              <Typography>{placeholder}</Typography>
            </MenuItem>
          )}
          {data?.map((element) => (
            <CheckboxMenuItem
              key={isPrimitiveKey ? element : element[menuKey]}
              value={isPrimitiveKey ? element : element[menuValue]}
              text={isPrimitiveKey ? element : element[menuName]}
              image={menuImage ? element[menuImage] : false}
              showImage={menuImage}
              className={classes.menuItem}
            />
          ))}
        </Select>
      </FormControl>
    );
  }
);

MultipleSelection.propTypes = {
  classes: PropTypes.object.isRequired,
  meta: PropTypes.object,
  input: PropTypes.object,
  label: PropTypes.string,
  hintMessage: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  showPlaceholderAtList: PropTypes.bool,
  data: PropTypes.array.isRequired,
  menuKey: PropTypes.string,
  menuValue: PropTypes.string,
  menuName: PropTypes.string,
  menuImage: PropTypes.string,
  menuBg: PropTypes.string,
  menuColor: PropTypes.string,
  blackIcon: PropTypes.bool,
  showLength: PropTypes.bool,
  maxTitle: PropTypes.string,
  onChangeSelected: PropTypes.func,
  isPrimitiveKey: PropTypes.bool,
  isErrorValue: PropTypes.bool,
  notReduxForm: PropTypes.bool,
  labelClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

MultipleSelection.defaultProps = {
  meta: { touched: false, error: false },
};
/* End */

/* Single Selection */
export const SingleSelection = withStyles(styles)(
  ({
    classes,
    meta: { touched, error },
    input: { value, onChange, ...input },
    label,
    hintMessage,
    className,
    placeholder,
    showPlaceholderAtList,
    data,
    menuKey,
    menuValue,
    menuName,
    menuImage,
    onChangeSelected,
    isPrimitiveKey,
    isErrorValue,
    notReduxForm,
    canDelete,
    ...rest
  }) => {
    const isError = isErrorValue || (touched && Boolean(error));

    const handleChange = (e) => {
      if (notReduxForm) {
        onChange(e.target.value);
      } else {
        onChange(e);
        if (onChangeSelected) {
          onChangeSelected(e.target.value);
        }
      }
    };

    const handleDelete = () => {
      onChange(null);
      if (onChangeSelected) {
        onChangeSelected(null);
      }
    };

    const handleGetValue = (idf, selectedElement) =>
      data?.find((element) => element[idf] === selectedElement);
    return (
      <FormControl
        variant="outlined"
        className={classNames(
          className || "",
          isError ? classes.fieldError : ""
        )}
        {...rest}
      >
        <LabelNewStyle
          label={label}
          isError={isError}
          error={error}
          hintMessage={hintMessage}
        />
        <Select
          displayEmpty
          value={value}
          onChange={handleChange}
          {...input}
          renderValue={(selected) => {
            const selectedImage = menuImage
              ? handleGetValue(menuValue, selected)?.[menuImage]
              : null;
            const selectedName = handleGetValue(menuValue, selected)?.[
              menuName
            ];

            if (!selected && placeholder) {
              return (
                <div className={classes.singleSelectionRender}>
                  <p className={classes.placeholder}>{placeholder}</p>
                </div>
              );
            }

            return (
              <div className={classes.singleSelectionRender}>
                {menuImage && (
                  <Avatar
                    className={classes.avatar}
                    alt={selectedName}
                    src={
                      selectedImage
                        ? urlUtils.getProperImageUrl(selectedImage)
                        : "placeholder"
                    }
                  />
                )}
                {isPrimitiveKey ? selected : selectedName}
                {canDelete && (
                  <RoundedDelete
                    onClick={handleDelete}
                    onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                    className={classes.deleteIconSingle}
                  />
                )}
              </div>
            );
          }}
          inputProps={{ "aria-label": "Without label" }}
          IconComponent={ArrowDown}
        >
          {showPlaceholderAtList && (
            <MenuItem disabled value="">
              <Typography>{placeholder}</Typography>
            </MenuItem>
          )}
          {data?.map((element) => (
            <CheckboxMenuItem
              key={isPrimitiveKey ? element : element[menuKey]}
              value={isPrimitiveKey ? element : element[menuValue]}
              text={isPrimitiveKey ? element : element[menuName]}
              image={menuImage ? element[menuImage] : false}
              showImage={menuImage}
              noCheckBox
              className={classes.menuItem}
            />
          ))}
        </Select>
      </FormControl>
    );
  }
);

SingleSelection.propTypes = {
  classes: PropTypes.object.isRequired,
  meta: PropTypes.object,
  input: PropTypes.object,
  label: PropTypes.string,
  hintMessage: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  showPlaceholderAtList: PropTypes.bool,
  data: PropTypes.array.isRequired,
  menuKey: PropTypes.string,
  menuValue: PropTypes.string,
  menuName: PropTypes.string,
  menuImage: PropTypes.string,
  onChangeSelected: PropTypes.func,
  isPrimitiveKey: PropTypes.bool,
  isErrorValue: PropTypes.bool,
  notReduxForm: PropTypes.bool,
};

SingleSelection.defaultProps = {
  meta: { touched: false, error: false },
  input: null,
};
/* End */
