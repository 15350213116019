import React from "react";

const UpgradeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    {...props}
  >
    <path
      d="M10.75 17.5C10.75 17.9142 11.0858 18.25 11.5 18.25C11.9142 18.25 12.25 17.9142 12.25 17.5L10.75 17.5ZM12.0303 5.96967C11.7374 5.67678 11.2626 5.67678 10.9697 5.96967L6.1967 10.7426C5.90381 11.0355 5.90381 11.5104 6.1967 11.8033C6.48959 12.0962 6.96447 12.0962 7.25736 11.8033L11.5 7.56066L15.7426 11.8033C16.0355 12.0962 16.5104 12.0962 16.8033 11.8033C17.0962 11.5104 17.0962 11.0355 16.8033 10.7426L12.0303 5.96967ZM12.25 17.5L12.25 6.5L10.75 6.5L10.75 17.5L12.25 17.5Z"
      fill="white"
    />
    <path
      d="M1.5 9.25C1.09 9.25 0.75 8.91 0.75 8.5V6C0.75 3.1 3.11 0.75 6 0.75H8.5C8.91 0.75 9.25 1.09 9.25 1.5C9.25 1.91 8.91 2.25 8.5 2.25H6C3.93 2.25 2.25 3.93 2.25 6V8.5C2.25 8.91 1.91 9.25 1.5 9.25Z"
      fill="white"
    />
    <path
      d="M21.5 9.25C21.09 9.25 20.75 8.91 20.75 8.5V6C20.75 3.93 19.07 2.25 17 2.25H14.5C14.09 2.25 13.75 1.91 13.75 1.5C13.75 1.09 14.09 0.75 14.5 0.75H17C19.89 0.75 22.25 3.1 22.25 6V8.5C22.25 8.91 21.91 9.25 21.5 9.25Z"
      fill="white"
    />
    <path
      d="M17 22.25H15.5C15.09 22.25 14.75 21.91 14.75 21.5C14.75 21.09 15.09 20.75 15.5 20.75H17C19.07 20.75 20.75 19.07 20.75 17V15.5C20.75 15.09 21.09 14.75 21.5 14.75C21.91 14.75 22.25 15.09 22.25 15.5V17C22.25 19.9 19.89 22.25 17 22.25Z"
      fill="white"
    />
    <path
      d="M8.5 22.25H6C3.11 22.25 0.75 19.9 0.75 17V14.5C0.75 14.09 1.09 13.75 1.5 13.75C1.91 13.75 2.25 14.09 2.25 14.5V17C2.25 19.07 3.93 20.75 6 20.75H8.5C8.91 20.75 9.25 21.09 9.25 21.5C9.25 21.91 8.91 22.25 8.5 22.25Z"
      fill="white"
    />
  </svg>
);

export default UpgradeIcon;
