import axios from "axios";
import urlUtils from "../../url";
import { getCookie } from "../../../containers/Pages/Chat/utils/cookiesHelper";

const ApiConfig = axios.create({
  baseURL: urlUtils.getProperApiUrl(),
});

ApiConfig.interceptors.request.use((req) => {
  req.headers.Authorization = `Bearer ${getCookie("X-User-Token")}`;
  req.headers["User-Token"] = "7ceee72b-76c2-4294-bde3-deafc1ef46b2";
  return req;
});

export default ApiConfig;
