function pad(number) {
  if (number < 10) {
    return "0" + number;
  }
  return number;
}

const toBackendDateString = (date) =>
  date.getUTCFullYear()
  + "-"
  + pad(date.getUTCMonth() + 1)
  + "-"
  + pad(date.getUTCDate())
  + ":"
  + pad(date.getUTCHours())
  + ":"
  + pad(date.getUTCMinutes())
  + ":"
  + pad(date.getUTCSeconds());

const toDate = (value, fallback) => {
  if (value instanceof Date) {
    return value;
  }
  if (Number.isFinite(value)) {
    const date = new Date();
    date.setTime(value);
    return date;
  }
  return fallback;
};

const toISOStringWithoutMillis = (date) =>
  date
    .toISOString()
    .split(/\.[0-9]{3}/, 2)
    .join("");

const mapMonths = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

const convertMonthNumberToText = (monthInNumber) => {
  const modifiedMonth = monthInNumber.toString()[0] === "0"
    ? monthInNumber.toString()[1]
    : monthInNumber;
  return mapMonths[modifiedMonth];
};

const amOrpmHour = (hour) => (hour >= 12 && hour !== 24 ? "pm" : "am");

const formatDateForTable = (startDate, endDate) => {
  const start = startDate.split("-");
  const end = endDate.split("-");
  const day = start[2] === end[2] ? start[2] : start[2] + "-" + end[2];
  const month = mapMonths[
    start[1].toString()[0] === "0" ? start[1].toString()[1] : start[1]
  ];
  return month + " " + day + ", " + start[0];
};

const separateDate = (date) => {
  const newDate = new Date(date);
  const year = newDate.getFullYear();
  const month = newDate.getMonth();
  const day = newDate.getDate();
  const hour = newDate.getHours();
  const minute = newDate.getMinutes();
  const seconds = newDate.getSeconds();
  return { year, month, day, hour, minute, seconds };
};

const formatDayMonthYear = (day, month, year) =>
  `${day} ${mapMonths[month + 1]} ${year}`;

const formatHourMinute = (hour, minute) =>
  `${hour}:${minute} ${amOrpmHour(hour)}`;

const formatDateToString = (date) => {
  const separatedDate = separateDate(date);
  const { year, month, day, hour, minute } = separatedDate;
  return `${formatDayMonthYear(day, month, year)} ${formatHourMinute(
    hour,
    minute
  )}`;
};

const formatDateDayFirst = (dateValue) => {
  // date will be like this --> 27 Oct 2022 1:17 AM
  const date = new Date(dateValue);
  const day = date.getDate();
  const month = date.toLocaleString("en-US", { month: "short" });
  const year = date.getFullYear();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const period = hour >= 12 ? "PM" : "AM";
  const formattedDate = `${day} ${month} ${year} ${hour}:${minute
    .toString()
    .padStart(2, "0")} ${period}`;

  return formattedDate;
};

const formatMonthDayYear = (dateValue) => {
  // date will be like this --> June 24, 2020
  // used for user info page
  const date = new Date(dateValue);
  const day = date.getDate();
  const month = date.toLocaleString("en-US", { month: "short" });
  const year = date.getFullYear();
  const formattedDate = `${month} ${day}, ${year}`;

  return formattedDate;
};

const dayMonthYearDate = (inputDate) => {
  // date will be like this --> 4 June 2020
  const inputDateTime = new Date(inputDate);
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const day = inputDateTime.getDate();
  const month = monthNames[inputDateTime.getMonth()];
  const year = inputDateTime.getFullYear();

  const outputDate = `${day} ${month} ${year}`;

  return outputDate;
};

function convertDateForStats(inputDate) {
  // date will be like this --> Oct 16, 03 :30 PM
  const date = new Date(inputDate);
  const month = date.toLocaleString("en-US", { month: "short" });
  const day = date.getDate();
  const hours = date.getHours() % 12 || 12;
  const minutes = date.getMinutes();
  const amOrPm = date.getHours() < 12 ? "AM" : "PM";

  const formattedDate = `${month} ${day}, ${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")} ${amOrPm}`;

  return formattedDate;
}

export default {
  toBackendDateString,
  toDate,
  toISOStringWithoutMillis,
  convertMonthNumberToText,
  formatDateForTable,
  separateDate,
  formatDateToString,
  formatDayMonthYear,
  formatHourMinute,
  amOrpmHour,
  formatDateDayFirst,
  formatMonthDayYear,
  dayMonthYearDate,
  convertDateForStats
};
