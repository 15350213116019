import React from "react";

const EllipseBlur = ({
  width,
  height,
  borderRadius,
  opacity,
  background,
  blur,
}) => (
  <div
    style={{
      width,
      height,
      borderRadius,
      opacity,
      background,
      filter: `blur(${blur})`,
      WebkitFilter: `blur(${blur})`,
    }}
  />
);

EllipseBlur.defaultProps = {
  width: "813px",
  height: "813px",
  borderRadius: "813px",
  opacity: "0.33",
  background: "#FF005B",
  blur: "350px",
};

export default EllipseBlur;
