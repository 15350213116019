import React from "react";

const ArrowUp = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      d="M16.5998 13.1667C16.4414 13.1667 16.2831 13.1083 16.1581 12.9833L10.7248 7.54998C10.3248 7.14998 9.67477 7.14998 9.27477 7.54998L3.84144 12.9833C3.59977 13.225 3.19977 13.225 2.95811 12.9833C2.71644 12.7417 2.71644 12.3417 2.95811 12.1L8.39144 6.66665C9.27477 5.78332 10.7164 5.78332 11.6081 6.66665L17.0414 12.1C17.2831 12.3417 17.2831 12.7417 17.0414 12.9833C16.9164 13.1 16.7581 13.1667 16.5998 13.1667Z"
      fill="#4E5057"
    />
  </svg>
);

export default ArrowUp;
