import React from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./ChatAvatarStyles-jss";

const ChatAvatar = (props) => {
  const { classes, name, imgUrl } = props;

  const content = () => {
    if (imgUrl) {
      return <img alt={name} src={imgUrl} className={classes.img} />;
    }
    const capitalizedLetters = name
      ?.split(" ")
      ?.filter((word) => word.length > 0)
      ?.slice(0, 2)?.map((word) => word[0]?.toUpperCase())
      ?.join("");
    return capitalizedLetters;
  };

  return <div className={classes.container}>{content()}</div>;
};

export default withStyles(styles)(ChatAvatar);
