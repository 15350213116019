import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import { Button, InputBase, Select, styled } from "@material-ui/core/";
import MenuItem from "@material-ui/core/MenuItem";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { usePagination } from "@material-ui/lab/Pagination";
import ChevronDown from "../../../../../api/icons/GlobalIcon/ChevronDown";

import styles from "./BottomPagination-jss";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiSelect-icon": {
    display: "none",
  },
  "& .MuiInputBase-input": {
    borderRadius: "6px",
    position: "relative",
    backgroundColor: "#FFFFFF",
    border: "1px solid #E9E7FD",
    fontSize: "15px",
    fontWeight: "500",
    padding: "10px 20px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const PerPage = ({ classes, count, setCount, totalElements, initShowingValue }) => (
  <div>
    <div className={classes.selectContainer}>
      <p className={classes.selectLabel}>Showing</p>
      <FormControl variant="standard" className={classes.formControl}>
        <Select
          value={count}
          onChange={(e) => setCount(e.target.value)}
          input={<BootstrapInput />}
        >
          <MenuItem value={initShowingValue || 6}>{initShowingValue || 6}</MenuItem>
          <MenuItem value={12}>12</MenuItem>
          <MenuItem value={24}>24</MenuItem>
          <MenuItem value={48}>48</MenuItem>
          <MenuItem value={96}>96</MenuItem>
        </Select>
        <ChevronDown
          width={10}
          height={6}
          color="#000"
          className={classes.dropdownIcon}
        />
      </FormControl>
      <p className={classes.selectLabel}>{`of ${totalElements}`}</p>
    </div>
  </div>
);

PerPage.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  setCount: PropTypes.func.isRequired,
  totalElements: PropTypes.number.isRequired,
  initShowingValue: PropTypes.number,
};

const Pages = ({
  classes,
  pages,
  count,
  start,
  setStart,
  pageItemClassName,
  withLeftArrow,
  withRightArrow,
}) => {
  const { items } = usePagination({
    count: pages,
    siblingCount: 0,
    boundaryCount: 1,
    page: Math.floor(start / count) + 1,
    onChange: (_, page) => {
      setStart((page - 1) * count);
    },
  });

  return (
    <ul className={classes.ul}>
      {items.map(({ page, type, selected, ...item }, index) => {
        let children = null;

        if (type === "start-ellipsis" || type === "end-ellipsis") {
          children = (
            <div className={`${classes.pagButton} ${pageItemClassName}`}>
              <p>...</p>
            </div>
          );
        } else if (type === "page") {
          children = (
            <Button
              className={`${classes.pagButton} ${
                page === Math.floor(start / count) + 1 ?
                  classes.activePagButton : ""
              } ${pageItemClassName}`}
              disabled={page === Math.floor(start / count) + 1}
              {...item}
            >
              <p>{page}</p>
            </Button>
          );
        } else if (type === "previous" && withLeftArrow) {
          children = (
            <Button className={`${classes.pagButton} ${pageItemClassName}`} type="button" {...item}>
              <ChevronLeft width="16px" height="16px" color="#8B909A" />
            </Button>
          );
        } else if (type === "next" && withRightArrow) {
          children = (
            <Button className={`${classes.pagButton} ${pageItemClassName}`} type="button" {...item}>
              <ChevronRight width="16px" height="16px" color="#8B909A" />
            </Button>
          );
        }

        return <li key={index}>{children}</li>;
      })}
    </ul>
  );
};

Pages.propTypes = {
  classes: PropTypes.object.isRequired,
  pages: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  start: PropTypes.number.isRequired,
  setStart: PropTypes.func.isRequired,
  pageItemClassName: PropTypes.string,
};

const Pagination = ({
  pages,
  classes,
  count,
  start,
  setStart,
  withLeftArrow,
  withRightArrow,
  pageItemClassName,
}) => (
  <div className={classes.paginationContainer}>
    <Pages
      classes={classes}
      pages={pages}
      count={count}
      start={start}
      setStart={setStart}
      pageItemClassName={pageItemClassName}
      withRightArrow={withRightArrow}
      withLeftArrow={withLeftArrow}
    />
  </div>
);

Pagination.propTypes = {
  pages: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  start: PropTypes.number.isRequired,
  setStart: PropTypes.func.isRequired,
  withLeftArrow: PropTypes.bool,
  withRightArrow: PropTypes.bool,
  pageItemClassName: PropTypes.string,
};

const BottomPagination = ({
  classes,
  pages,
  count,
  setCount,
  totalElements,
  initShowingValue,
  start,
  setStart,
  withPerPage,
  withLeftArrow,
  withRightArrow,
  containerClassName,
  pageItemClassName,
}) => (
  <div className={`${classes.pagination} ${containerClassName || ""} ${!withPerPage ? classes.flexEnd : ""}`}>
    {withPerPage ? (
      <PerPage
        classes={classes}
        count={count}
        setCount={setCount}
        totalElements={totalElements}
        initShowingValue={initShowingValue}
      />
    ) : null}
    <Pagination
      start={start}
      setStart={setStart}
      classes={classes}
      pages={pages}
      count={count}
      withLeftArrow={withLeftArrow}
      withRightArrow={withRightArrow}
      pageItemClassName={pageItemClassName}
    />
  </div>
);

BottomPagination.propTypes = {
  pages: PropTypes.number,
  classes: PropTypes.object.isRequired,
  count: PropTypes.number,
  setCount: PropTypes.func,
  totalElements: PropTypes.number,
  initShowingValue: PropTypes.number,
  start: PropTypes.number,
  setStart: PropTypes.func,
  withPerPage: PropTypes.bool,
  withLeftArrow: PropTypes.bool,
  withRightArrow: PropTypes.bool,
  pageItemClassName: PropTypes.string,
  containerClassName: PropTypes.string,
};
BottomPagination.defaultProps = {
  withPerPage: true,
  withLeftArrow: true,
  withRightArrow: true,
};

const StyledBottomPagination = withStyles(styles)(BottomPagination);

export default StyledBottomPagination;
