import React from "react";

const CakeIcon = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.823 11.4434V12.2767H17.098C16.4813 12.2767 15.9813 12.7767 15.9813 13.4017V13.6517C15.9813 14.2767 15.4813 14.7767 14.8563 14.7767C14.2397 14.7767 13.7397 14.2767 13.7397 13.6517V13.4017C13.7397 12.7767 13.2313 12.2767 12.6147 12.2767C11.998 12.2767 11.498 12.7767 11.498 13.4017V13.6517C11.498 14.2767 10.9897 14.7767 10.373 14.7767C9.75635 14.7767 9.24801 14.2767 9.24801 13.6517V13.4017C9.24801 12.7767 8.74801 12.2767 8.13135 12.2767C7.51468 12.2767 7.00635 12.7767 7.00635 13.4017V13.6517C7.00635 14.2767 6.50635 14.7767 5.88968 14.7767C5.26468 14.7767 4.76468 14.2767 4.76468 13.6517V13.385C4.76468 12.7684 4.27301 12.2684 3.66468 12.26H3.00635V11.4434C3.00635 10.2934 3.87301 9.31836 5.04801 9.03503C5.28135 8.97669 5.52301 8.94336 5.78135 8.94336H15.048C15.3063 8.94336 15.548 8.97669 15.7813 9.03503C16.9563 9.31836 17.823 10.2934 17.823 11.4434Z"
      fill="currentcolor"
    />
    <path
      d="M15.7812 6.58372V7.75872C15.5395 7.70872 15.2979 7.69206 15.0479 7.69206H5.78119C5.53119 7.69206 5.28952 7.71706 5.04785 7.76706V6.58372C5.04785 5.58372 5.94785 4.77539 7.06452 4.77539H13.7645C14.8812 4.77539 15.7812 5.58372 15.7812 6.58372Z"
      fill="currentcolor"
    />
    <path
      d="M7.70605 3.56706V4.78372H7.06439C6.84772 4.78372 6.64772 4.80872 6.45605 4.85872V3.56706C6.45605 3.27539 6.73939 3.02539 7.08105 3.02539C7.42272 3.02539 7.70605 3.27539 7.70605 3.56706Z"
      fill="currentcolor"
    />
    <path
      d="M14.373 3.38477V4.85977C14.1814 4.80143 13.9814 4.77643 13.7647 4.77643H13.123V3.38477C13.123 3.0431 13.4064 2.75977 13.748 2.75977C14.0897 2.75977 14.373 3.0431 14.373 3.38477Z"
      fill="currentcolor"
    />
    <path
      d="M11.0396 2.95872V4.77539H9.78955V2.95872C9.78955 2.58372 10.0729 2.27539 10.4146 2.27539C10.7562 2.27539 11.0396 2.58372 11.0396 2.95872Z"
      fill="currentcolor"
    />
    <path
      d="M18.7477 18.3181C18.7477 18.6598 18.4644 18.9431 18.1227 18.9431H2.70605C2.36439 18.9431 2.08105 18.6598 2.08105 18.3181C2.08105 17.9764 2.36439 17.6931 2.70605 17.6931H3.00605V13.5098H3.51439V13.5681C3.51439 14.6848 4.24772 15.7181 5.33939 15.9598C6.18939 16.1598 6.99772 15.8848 7.54772 15.3431C7.86439 15.0264 8.38105 15.0181 8.69772 15.3348C9.13105 15.7598 9.72272 16.0264 10.3727 16.0264C11.0227 16.0264 11.6144 15.7681 12.0477 15.3348C12.3644 15.0264 12.8727 15.0264 13.1977 15.3431C13.7394 15.8848 14.5477 16.1598 15.4061 15.9598C16.4977 15.7181 17.2311 14.6848 17.2311 13.5681V13.5264H17.8227V17.6931H18.1227C18.4644 17.6931 18.7477 17.9764 18.7477 18.3181Z"
      fill="currentcolor"
    />
  </svg>
);

export default CakeIcon;
