import React from "react";

const InfoLabelIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6668 8.00004C14.6668 11.6819 11.6821 14.6667 8.00016 14.6667C4.31826 14.6667 1.3335 11.6819 1.3335 8.00004C1.3335 4.31814 4.31826 1.33337 8.00016 1.33337C11.6821 1.33337 14.6668 4.31814 14.6668 8.00004ZM8.00016 7.33337C8.36835 7.33337 8.66683 7.63185 8.66683 8.00004V11.334C8.66683 11.7022 8.36835 12.0006 8.00016 12.0006C7.63197 12.0006 7.3335 11.7022 7.3335 11.334V8.00004C7.3335 7.63185 7.63197 7.33337 8.00016 7.33337ZM8.00016 6.00004C8.36835 6.00004 8.66683 5.70156 8.66683 5.33337C8.66683 4.96518 8.36835 4.66671 8.00016 4.66671C7.63197 4.66671 7.3335 4.96518 7.3335 5.33337C7.3335 5.70156 7.63197 6.00004 8.00016 6.00004Z"
      fill="#9A9FA5"
    />
  </svg>
);

export default InfoLabelIcon;
