import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import CakeIcon from "api/icons/GlobalIcon/CakeIcon";
import EmailIcon from "api/icons/GlobalIcon/EmailIcon";
import GenderIcon from "api/icons/GlobalIcon/GenderIcon";
import Mobile from "api/icons/GlobalIcon/Mobile";
import SignedUpIcon from "api/icons/GlobalIcon/SignedUpIcon";
import TickCircleIcon from "api/icons/GlobalIcon/TickCircleIcon";
import UserIcon from "api/icons/GlobalIcon/UserIcon";
import IconTitleData from "components/IconTitleData/IconTitleData";
import urlUtils from "../../utils/url";
import styles from "./UserProfileInfo-jss";

const dateOptions = { year: "numeric", month: "short", day: "numeric" };
const UserProfileInfo = (props) => {
  const { classes, img, user_name } = props;

  const data = {
    user_name: UserIcon,
    email: EmailIcon,
    phone: Mobile,
    dob: CakeIcon,
    gender: GenderIcon,
    signed_up: SignedUpIcon,
    last_login: TickCircleIcon,
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2} className={classes.imgGrid}>
          <div className={classes.imgContainer}>
            <img
              src={
                img
                  ? urlUtils.getProperImageUrl(img)
                  : "https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"
              }
              alt={user_name}
              title={user_name}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={10}>
          {Object.entries(data).map((item) => {
            const text = item[0];
            const Icon = item[1];
            return (
              <IconTitleData
                key={text}
                icon={<Icon />}
                title={text.replace("_", " ")}
                data={
                  text === "last_login" || text === "signed_up"
                    ? new Date(props[text]).toLocaleDateString(
                      "en-US",
                      dateOptions
                    )
                    : props[text]
                }
              />
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(UserProfileInfo);
