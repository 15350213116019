const styles = (theme) => ({
  card: {
    boxShadow: "none",
    border: `1px solid ${theme.palette.borderLight}`,
    borderRadius: 8,
    // background: theme.palette.type === "dark" ? "" : "",
    "& svg": {
      fill: "transparent",
    },
    "& .MuiCardHeader-root": {
      marginTop: 8,
      padding: "1rem 1rem 0.5rem 1rem",
    },
  },
  cardTitle: {
    fontSize: 15,
    textTransform: "Capitalize",
    fontWeight: 400,
    color: theme.palette.type === "dark" ? "#fff" : "#000000DE",
    marginBottom: 0,
  },
  cardActions: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    "& button": {
      background: "none",
      padding: 0,
    },
  },
  media: {
    height: 120,
    margin: "0 16px 10px ",
    borderRadius: 6,
    "& img": {
      borderRadius: "inherit",
      height: "100%",
    },
  },
});

export default styles;
