import React from "react";

const StarIcon = (props) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.99992 10.8466L11.1199 13.3333L10.0266 8.64663L13.6666 5.49329L8.87325 5.08663L6.99992 0.666626L5.12659 5.08663L0.333252 5.49329L3.97325 8.64663L2.87992 13.3333L6.99992 10.8466Z"
      fill="currentColor"
      fillOpacity="1"
    />
  </svg>
);

export default StarIcon;
