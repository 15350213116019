const styles = (theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(5),
  },
  icon: {
    display: "inline-block",
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },
  title: {
    minWidth: "120px",
    marginRight: theme.spacing(3),
    fontSize: "15px",
    fontWeight: "500",
    textTransform: "capitalize",
    color:
      theme.palette.type === "dark"
        ? theme.whiteColorGradients.almostWhite
        : theme.blackColorGradients.almostBlack,
  },
  data: {
    fontSize: "14px",
    fontWeight: "400",
    color:
      theme.palette.type === "dark"
        ? theme.whiteColorGradients.almostWhite
        : theme.blackColorGradients.almostBlack,
  },
});
export default styles;
