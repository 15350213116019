import React from "react";

const AdvertisementsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M14.2209 21.6298C13.0409 21.6298 11.3709 20.7998 10.0509 16.8298L9.33086 14.6698L7.17086 13.9498C3.21086 12.6298 2.38086 10.9598 2.38086 9.77983C2.38086 8.60983 3.21086 6.92983 7.17086 5.59983L15.6609 2.76983C17.7809 2.05983 19.5509 2.26983 20.6409 3.34983C21.7309 4.42983 21.9409 6.20983 21.2309 8.32983L18.4009 16.8198C17.0709 20.7998 15.4009 21.6298 14.2209 21.6298ZM7.64086 7.02983C4.86086 7.95983 3.87086 9.05983 3.87086 9.77983C3.87086 10.4998 4.86086 11.5998 7.64086 12.5198L10.1609 13.3598C10.3809 13.4298 10.5609 13.6098 10.6309 13.8298L11.4709 16.3498C12.3909 19.1298 13.5009 20.1198 14.2209 20.1198C14.9409 20.1198 16.0409 19.1298 16.9709 16.3498L19.8009 7.85983C20.3109 6.31983 20.2209 5.05983 19.5709 4.40983C18.9209 3.75983 17.6609 3.67983 16.1309 4.18983L7.64086 7.02983Z"
      fill="#4E5057"
    />
    <path
      d="M10.1108 14.4C9.92078 14.4 9.73078 14.33 9.58078 14.18C9.29078 13.89 9.29078 13.41 9.58078 13.12L13.1608 9.53C13.4508 9.24 13.9308 9.24 14.2208 9.53C14.5108 9.82 14.5108 10.3 14.2208 10.59L10.6408 14.18C10.5008 14.33 10.3008 14.4 10.1108 14.4Z"
      fill="#4E5057"
    />
  </svg>
);

export default AdvertisementsIcon;
