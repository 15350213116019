import React from "react";

const CategoriesIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M8 22.75C4.28 22.75 1.25 19.72 1.25 16C1.25 12.96 3.29 10.28 6.22 9.49001C6.59 9.39001 6.97 9.58001 7.11 9.94001C7.72 11.47 9.02 12.64 10.61 13.07C11.49 13.31 12.51 13.31 13.37 13.07C13.74 12.97 14.13 13.16 14.27 13.52C14.58 14.31 14.74 15.15 14.74 16.01C14.74 17.93 13.92 19.76 12.49 21.03C11.27 22.13 9.67 22.75 8 22.75ZM6.02 11.13C4.07 11.93 2.75 13.85 2.75 16C2.75 18.89 5.11 21.25 8 21.25C9.3 21.25 10.54 20.77 11.5 19.9C12.62 18.91 13.25 17.49 13.25 16C13.25 15.54 13.19 15.1 13.08 14.67C12.14 14.82 11.12 14.77 10.22 14.52C8.41 14.02 6.89 12.78 6.02 11.13Z"
      fill="#4E5057"
    />
    <path
      d="M12 14.75C11.38 14.75 10.78 14.67 10.22 14.51C8.19 13.95 6.5 12.45 5.72 10.48C5.41 9.7 5.25 8.86 5.25 8C5.25 4.28 8.28 1.25 12 1.25C15.72 1.25 18.75 4.28 18.75 8C18.75 8.86 18.59 9.7 18.28 10.49C17.5 12.45 15.82 13.96 13.78 14.52C13.22 14.67 12.62 14.75 12 14.75ZM12 2.75C9.11 2.75 6.75 5.11 6.75 8C6.75 8.67 6.87 9.32 7.12 9.93C7.73 11.46 9.03 12.63 10.62 13.06C11.5 13.3 12.52 13.3 13.38 13.06C14.97 12.63 16.28 11.45 16.88 9.93C17.12 9.32 17.25 8.67 17.25 8C17.25 5.11 14.89 2.75 12 2.75Z"
      fill="#4E5057"
    />
    <path
      d="M16 22.75C14.33 22.75 12.73 22.13 11.5 21.02C11.34 20.88 11.25 20.67 11.25 20.46C11.25 20.25 11.34 20.04 11.5 19.9C12.61 18.92 13.25 17.5 13.25 16C13.25 15.33 13.13 14.68 12.88 14.07C12.8 13.87 12.81 13.65 12.91 13.46C13.01 13.27 13.18 13.13 13.38 13.07C14.97 12.64 16.27 11.47 16.88 9.93998C17.02 9.57998 17.41 9.39998 17.77 9.48998C20.7 10.29 22.74 12.96 22.74 16C22.75 19.72 19.72 22.75 16 22.75ZM13.12 20.39C13.97 20.95 14.96 21.25 16 21.25C18.89 21.25 21.25 18.89 21.25 16C21.25 13.85 19.93 11.93 17.98 11.13C17.24 12.54 16 13.66 14.52 14.26C14.67 14.82 14.75 15.41 14.75 16C14.75 17.62 14.17 19.18 13.12 20.39Z"
      fill="#4E5057"
    />
  </svg>
);

export default CategoriesIcon;
