import { Iterable, Map } from "immutable";
import url from "./url";

/**
 * @typedef {Object} DifferenceResult
 * @property {boolean} isDifferent - Whether there's a difference
 * @property {any} [difference] - the difference (if any).
 */

const required = value => (
  value == null || (Array.isArray(value) && !value.length)
    ? "Required"
    : undefined
);
const email = value =>
  (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email"
    : undefined);

const phone = (value) =>
  (value && !/^[+]?[0-9]{5}/i.test(value) ? "Invalid phone" : undefined);

const name = value =>
  (value && !/^[a-zA-z ']+$/i.test(value) ? "Inavlid Name" : undefined);

const percentageNumber = value => {
  const numberValue = Number(value);
  return value && (!Number.isFinite(numberValue) || numberValue < 0 || numberValue > 100)
    ? "Invalid Percentage"
    : undefined;
};

const verifyNumber = value => {
  const numberValue = Number(value);
  return value && !Number.isFinite(numberValue)
    ? "Invalid Number"
    : undefined;
};

const verifyPositiveNumber = value => {
  const numberValue = Number(value);
  return value && (!Number.isFinite(numberValue) || numberValue <= 0)
    ? "Invalid Positive Number"
    : undefined;
};

const json = value => {
  try {
    JSON.parse(value);
  } catch (ex) {
    return ex.message;
  }
  return undefined;
};

const getChildImagesProperties = (properties, key, parentIsArray) => {
  if (parentIsArray) {
    return properties;
  }
  const childProperties = properties[key] || {};
  const childDefault = childProperties._default || {};
  return {
    ...childProperties,
    _default: { ...properties._default, ...childDefault },
  };
};

export class FileLike extends File {
  // eslint-disable-next-line constructor-super
  constructor({ url: imgUrl, thumb }) {
    super([], imgUrl);
    this.preview = url.getProperImageUrl(thumb?.url || imgUrl);
  }
}

const identifyImages = (formValues, imagesProperties) => {
  const images = [];
  // BFS without visited check as thereshould be no cycles
  const queue = [];
  queue.push({ cur: formValues, properties: imagesProperties });
  while (queue.length) {
    const { cur, properties } = queue.shift();
    Object.keys(cur).forEach(key => {
      const childImagesProperties = getChildImagesProperties(properties, key, Array.isArray(cur));
      const val = cur[key];
      if (val instanceof FileLike) {
        cur[key] = val.path;
      } else if (val instanceof File) {
        images.push({ parent: cur, key, imageProperties: childImagesProperties._default });
      } else if (val && val === Object(val)) { // not primitive
        queue.push({ cur: val, properties: childImagesProperties });
      }
    });
  }
  return images;
};

const toFileLikeHelper = (value) =>
  (typeof value === "string" && value ? new FileLike({ url: value }) : value);

const urlToFileLike = value => {
  if (Array.isArray(value) || Iterable.isIterable(value)) {
    return value.map(toFileLikeHelper);
  }
  return toFileLikeHelper(value);
};

const imageToFileLike = image => (image ? new FileLike(image) : null);

const immutableToArray = (value) => (Iterable.isIterable(value) ? value.toJS() : value);

const emptyArrayToNull = (value) =>
  (Array.isArray(value) && !value.length ? null : value);

const nullToEmptyArray = (value) => (!value ? [] : value);

const toArray = (value) => {
  if (!value) return [];
  if (Iterable.isIterable(value)) return value.toJS();
  return Array.isArray(value) ? value : [value];
};

const minifyJSON = (value) => {
  try {
    return JSON.stringify(JSON.parse(value));
  } catch {
    return value;
  }
};

const prettifyJSON = (value) => {
  try {
    return JSON.stringify(JSON.parse(value), null, 2);
  } catch {
    return value;
  }
};

/**
 * returns the deep asymmetric difference between 2 immutable Iterables
 * (stops at non "Iterable" is found)
 * @param {any} newValue Minuend of the difference operation
 * @param {any} oldValue Subtrahend of the differnece operation
 * @returns {DifferenceResult} Result of the difference operation
 */
const getImmutableDifference = (newValue, oldValue) => {
  if (!Iterable.isIterable(newValue)) {
    return newValue !== oldValue
      ? { isDifferent: true, difference: newValue }
      : { isDifferent: false };
  }
  let result = Map();
  newValue.forEach((value, key) => {
    const { isDifferent, difference } = getImmutableDifference(
      value,
      oldValue.get(key)
    );
    if (isDifferent) {
      result = result.set(key, difference);
    }
  });
  return result.size
    ? { isDifferent: true, difference: result }
    : { isDifferent: false };
};

export default {
  validation: {
    required,
    email,
    phone,
    name,
    percentageNumber,
    verifyNumber,
    verifyPositiveNumber,
    json,
  },
  format: {
    urlToFileLike,
    imageToFileLike,
    identifyImages,
    immutableToArray,
    emptyArrayToNull,
    nullToEmptyArray,
    toArray,
    minifyJSON,
    prettifyJSON
  },
  helper: {
    getImmutableDifference
  }
};
