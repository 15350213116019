import React from "react";

const VisitIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.33325 8.13281H9.99992"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33325 10.8008H8.25325"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66659 4.00065H9.33325C10.6666 4.00065 10.6666 3.33398 10.6666 2.66732C10.6666 1.33398 9.99992 1.33398 9.33325 1.33398H6.66659C5.99992 1.33398 5.33325 1.33398 5.33325 2.66732C5.33325 4.00065 5.99992 4.00065 6.66659 4.00065Z"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6667 2.67969C12.8867 2.79969 14 3.61969 14 6.66635V10.6664C14 13.333 13.3333 14.6664 10 14.6664H6C2.66667 14.6664 2 13.333 2 10.6664V6.66635C2 3.62635 3.11333 2.79969 5.33333 2.67969"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default VisitIcon;
