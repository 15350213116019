import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import { StarIcon } from "api/icons/GlobalIcon";
import styles from "./CustomBreadcrumbs-jss";

const CustomBreadcrumbs = ({ path, classes }) => {
  const paths = path.split("/");
  return (
    <Box
      aria-label="breadcrumb"
      display="flex"
      alignItems="center"
      className={classes.breadcrumbs}
    >
      {paths?.map((link, index) => (
        <>
          <StarIcon />
          <>
            {index + 1 !== paths.length ? (
              <>
                <Link underline="hover" color="inherit" href="/">
                  {link}
                </Link>
                <div className={classes.separated}>
                  /
                  {" "}
                  <span>... </span>
                  {" "}
                  /
                </div>
              </>
            ) : (
              <Typography color="text.primary">{link}</Typography>
            )}
          </>
        </>
      ))}
    </Box>
  );
};

export default withStyles(styles)(CustomBreadcrumbs);
