import React from "react";

const VideoCallIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M12.88 20.86H6.81C3.26 20.86 2 18.37 2 16.05V7.95001C2 4.49001 3.35 3.14001 6.81 3.14001H12.88C16.34 3.14001 17.69 4.49001 17.69 7.95001V16.05C17.69 19.51 16.34 20.86 12.88 20.86ZM6.81 4.66001C4.2 4.66001 3.52 5.34001 3.52 7.95001V16.05C3.52 17.28 3.95 19.34 6.81 19.34H12.88C15.49 19.34 16.17 18.66 16.17 16.05V7.95001C16.17 5.34001 15.49 4.66001 12.88 4.66001H6.81V4.66001Z"
      fill="#4E5057"
    />
    <path
      d="M20.7799 18.11C20.3499 18.11 19.7999 17.97 19.1699 17.53L16.4999 15.66C16.2999 15.52 16.1799 15.29 16.1799 15.04V8.95999C16.1799 8.70999 16.2999 8.47999 16.4999 8.33999L19.1699 6.46999C20.3599 5.63999 21.2299 5.87999 21.6399 6.08999C22.0499 6.30999 22.7499 6.87999 22.7499 8.32999V15.66C22.7499 17.11 22.0499 17.69 21.6399 17.9C21.4499 18.01 21.1499 18.11 20.7799 18.11ZM17.6899 14.64L20.0399 16.28C20.4899 16.59 20.8099 16.62 20.9399 16.55C21.0799 16.48 21.2299 16.2 21.2299 15.66V8.33999C21.2299 7.78999 21.0699 7.51999 20.9399 7.44999C20.8099 7.37999 20.4899 7.40999 20.0399 7.71999L17.6899 9.35999V14.64Z"
      fill="#4E5057"
    />
    <path
      d="M11.5 11.75C10.26 11.75 9.25 10.74 9.25 9.5C9.25 8.26 10.26 7.25 11.5 7.25C12.74 7.25 13.75 8.26 13.75 9.5C13.75 10.74 12.74 11.75 11.5 11.75ZM11.5 8.75C11.09 8.75 10.75 9.09 10.75 9.5C10.75 9.91 11.09 10.25 11.5 10.25C11.91 10.25 12.25 9.91 12.25 9.5C12.25 9.09 11.91 8.75 11.5 8.75Z"
      fill="#4E5057"
    />
  </svg>
);

export default VideoCallIcon;
