const styles = (theme) => ({
  tree: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 30,
    padding: 30,
    borderRadius: 16,
    border: "1px solid #938B9A59",
    [theme.breakpoints.down("sm")]: {
      padding: 24,
    },
    [theme.breakpoints.down("xs")]: {
      padding: 16,
    },
  },
});

export default styles;
