import React from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { EditIcon, DeleteIcon } from "api/icons/GlobalIcon";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styles from "./ManageCard-jss";

const ManageCard = ({ name, handelDelete, path, state, content, classes }) => (
  <Card className={classes.card}>
    <CardHeader
      className={classes.cardHeader}
      title={(
        <p className={classes.cardTitle}>
          {name}
        </p>
      )}
      action={(
        <div className={classes.cardActions}>
          <Link
            to={{
              pathname: path,
              state: { state },
            }}
          >
            <EditIcon />
          </Link>
          <button type="button" onClick={handelDelete}>
            <DeleteIcon />
          </button>
        </div>
      )}
    />
    <CardContent className={classes.cardContent}>
      {content}
    </CardContent>
  </Card>
);

export default withStyles(styles)(ManageCard);
