import React from "react";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { ADD_NOTIFICATION } from "actions/actionConstants";
import LockOutline from "api/icons/LockOutline";
import MeetUsLogo from "api/icons/MeetUsLogo";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Field, reduxForm } from "redux-form/immutable";
import formUtils from "utils/form";
import { TextFieldRedux } from "./ReduxFormMUI";
import styles from "./user-jss";

const {
  validation: { required }
} = formUtils;

const passwordsMatch = (value, allValues) => {
  if (value !== allValues.get("password")) {
    return "Passwords dont match";
  }
  return undefined;
};

class PasswordRecoveryForm extends React.Component {
  state = {
    showPassword: false
  };

  handleClickShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  };

  render() {
    const {
      classes,
      handleSubmit,
      pristine,
      submitting,
      deco,
    } = this.props;
    const { showPassword } = this.state;

    return (
      <div className={classNames(classes.paperWrap)}>
        <div className={classes.titleContainer}>
          <Typography variant="h6" className={classes.welcomeBackTitle}>
            Recover Password
          </Typography>
          <Typography variant="h6" className={classes.subTitle}>
            Enter your new password
          </Typography>
        </div>
        <form onSubmit={handleSubmit}>
          <FormControl className={classNames(classes.formControl, classes.spaceMargin)}>
            <Field
              name="password"
              component={TextFieldRedux}
              type={showPassword ? "text" : "password"}
              placeholder="New Password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutline />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                    className={classes.eyeIcon}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                )
              }}
              required
              validate={[required, passwordsMatch]}
              className={classes.field}
            />
          </FormControl>
          <FormControl className={classNames(classes.formControl, classes.spaceMargin)}>
            <Field
              name="passwordConfirm"
              component={TextFieldRedux}
              type="password"
              placeholder="Re-type Password"
              required
              validate={[required, passwordsMatch]}
              className={classes.field}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutline />
                  </InputAdornment>
                )
              }}
            />
          </FormControl>
          <div className={classes.btnArea}>
            <Button disabled={submitting || pristine} variant="contained" color="primary" type="submit" className={classes.signInInput}>
              Change Password
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

PasswordRecoveryForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  deco: PropTypes.bool.isRequired,
};

const PasswordRecoveryFormReduxed = reduxForm({
  form: "immutableEResetFrm",
  enableReinitialize: true,
})(PasswordRecoveryForm);

const reducer = "ui";
const PasswordRecoveryFormMapped = connect(
  state => ({
    deco: state.getIn([reducer, "decoration"])
  }),
  (dispatch, ownProps) => ({
    onSubmitSuccess: message => {
      dispatch({ type: ADD_NOTIFICATION, message });
      ownProps.onSubmitSuccess(message);
    },
    onSubmitFail: (...[, , submitError]) =>
      submitError && dispatch({ type: ADD_NOTIFICATION, message: submitError?.message })
  })
)(PasswordRecoveryFormReduxed);

PasswordRecoveryFormMapped.propTypes = {
  onSubmitSuccess: PropTypes.func
};

PasswordRecoveryFormMapped.defaultProps = {
  onSubmitSuccess: () => {}
};

export default withStyles(styles)(PasswordRecoveryFormMapped);
