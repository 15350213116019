import React from "react";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Slider from "@material-ui/core/Slider";
import DateUtils from "utils/date";

const dayMillis = 24 * 60 * 60 * 1000;

const ValueLabelComponent = ({ children, open, value }) => (
  <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
    {children}
  </Tooltip>
);

const FilterSlider = ({
  title,
  min,
  max,
  step,
  scale,
  currentValue: [start, end],
  onChange,
  valueLabelFormat
}) => (
  <div>
    <Typography>{title}</Typography>
    <Slider
      min={min}
      max={max}
      step={step}
      scale={scale}
      value={start && end ? [start, end] : [min, max]}
      onChange={(ev, value) => onChange(value)}
      valueLabelDisplay="auto"
      aria-labelledby="range-slider"
      ValueLabelComponent={ValueLabelComponent}
      valueLabelFormat={valueLabelFormat}
      style={{ width: "90%", left: "5%" }}
    />
  </div>
);

const millisRangeToDateRange = ([start, end]) => [
  DateUtils.toDate(start),
  DateUtils.toDate(end)
];

export default (title, customBodyRender = x => x) => ({
  filterType: "custom",
  customFilterListOptions: {
    render: ([start, end]) => (
      <>
        {title}
        :&nbsp;
        {customBodyRender(start)}
        &nbsp;-&nbsp;
        {customBodyRender(end)}
      </>
    ),
    update: (filterValues, filterPos, index) => {
      // eslint-disable-next-line no-param-reassign
      filterValues[index] = [];
      return filterValues;
    }
  },
  filterOptions: {
    logic: (value, [min, max]) => value < min || value > max,
    display: (options, [start, end] = [], onChange) => {
      const isDate = options[0] instanceof Date;
      let min;
      let max;
      let step;
      if (options && options.length) {
        const filteredOptions = options.filter(v => Number.isFinite(Number(v)));
        min = Math.min(...filteredOptions);
        max = Math.max(...filteredOptions);
        if (isDate) {
          step = dayMillis;
          min = Math.floor(min / dayMillis) * dayMillis;
          max = Math.floor(max / dayMillis) * dayMillis + dayMillis;
        }
      } else {
        min = -Infinity;
        max = Infinity;
      }
      return (
        <FilterSlider
          title={title}
          min={min}
          max={max}
          step={step}
          // scale={millisToDate}
          currentValue={start && end ? [Number(start), Number(end)] : []}
          onChange={
            isDate
              ? value => onChange(millisRangeToDateRange(value))
              : onChange
          }
          valueLabelFormat={customBodyRender}
        />
      );
    }
  }
});
