import React from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./IconTitleData-jss";

const IconTitleData = ({ classes, icon, title, data }) => (
  <div className={classes.container}>
    <span className={classes.icon}>{icon}</span>
    <span className={classes.title}>{title}</span>
    <span className={classes.data}>{data}</span>
  </div>
);

export default withStyles(styles)(IconTitleData);
