import amber from "@material-ui/core/colors/amber";
import grey from "@material-ui/core/colors/grey";
import lightGreen from "@material-ui/core/colors/lightGreen";
import red from "@material-ui/core/colors/red";
import { fade } from "@material-ui/core/styles/colorManipulator";

const drawerWidth = 245;
const contentWidth = 245;
const sidebarCollapsedWidth = 80;
const styles = (theme) => ({
  user: {
    justifyContent: "center",
  },
  drawerPaper: {
    position: "relative",
    height: "100%",
    overflow: "hidden",
    border: "none",
    color: "#fff",
    width: drawerWidth,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  swipeDrawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  drawerInner: {
    overflow: "hidden",
    transition: "width 0.2s ease",
    height: "100%",
    position: "fixed",
    background:
      theme.palette.type === "dark"
        ? "linear-gradient(1deg , #1c2b45, #141d2e, #902a8c)"
        : theme.palette.mainSideBar,
    "&::-webkit-scrollbar": {
      width: 8,
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 12,
      backgroundColor: "rgba(0,0,0,0)",
    },
    [theme.breakpoints.up("xs")]: {
      paddingBottom: "3.5rem",
    },
  },
  drawerPaperClose: {
    [theme.breakpoints.up("xs")]: {
      width: sidebarCollapsedWidth,
    },
    [theme.breakpoints.down("xs")]: {
      width: "0",
      paddingBottom: "0px",
      height: "100%",
      borderBottom: "none",
    },
  },
  drawerPaperOpen: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingBottom: "0",
      height: "100%",
      borderBottom: "1.5px solid rgba(255, 255, 255, 0.30)",
      background: "rgba(0, 0, 0, 0.6)",
    },
  },
  drawerInnerMobile: {
    // Make the items inside not wrap when transitioning:
    height: "100%",
    backgroundColor:
      theme.palette.type === "dark"
        ? fade(theme.palette.background.paper, 0.75)
        : fade(theme.palette.background.paper, 0.95),
  },
  contentHolder: {
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      position: "relative",
      width: "75%",
      background: "#fff",
    },
  },
  drawerHeader: {
    padding: "0",
    zIndex: 1,
    position: "relative",
    ...theme.mixins.toolbar,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  avatar: {
    width: 48,
    height: 48,
    borderRadius: 6,
  },
  profileContainer: {
    borderRadius: 8,
    border: "1px solid rgba(255, 255, 255, 0.50)",
    padding: 8,
    height: 64,
    marginInline: 16,
    display: "flex",
    gap: 8,
    alignItems: "center",
    marginBottom: 28,
    marginTop: 39,
    transition: "all 0.5s ease-in-out",
  },
  profileContainerCollapsed: {
    border: "1px solid transparent",
    background: "transparent",
    padding: 0,
    gap: 25,
  },
  profileData: {
    margin: 0,
    "& :first-child": {
      color: "black",
      fontSize: 14,
      fontWeight: 600,
      fontFamily: theme.typography.fontFamily,
      margin: 0,
      marginBottom: 4,
    },
    "& :last-child": {
      color: "#4E5057",
      fontSize: 13,
      fontWeight: 400,
      fontFamily: theme.typography.fontFamily,
      margin: 0,
    },
  },
  bigAvatar: {
    width: 80,
    height: 80,
    boxShadow: theme.glow.light,
  },
  brandBar: {
    transition: theme.transitions.create(["width", "margin", "background"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "&:after": {
      transition: theme.transitions.create(["box-shadow"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  darker: {
    background: "none",
  },
  title: {
    fontSize: 10,
    textTransform: "uppercase",
    paddingLeft: theme.spacing(10),
    marginTop: theme.spacing(3),
    display: "block",
    color: theme.palette.secondary.main,
    lineHeight: "28px",
    fontWeight: "bold",
  },
  dense: {
    marginLeft: -15,
    "& > $title:first-child": {
      margin: "0",
    },
    "& $head": {
      paddingLeft: theme.spacing(10),
    },
  },
  listItemCollapsedActive: {
    position: "relative",
    "& $groupListItemIcon": {
      "&::before": {
        content: "",
        width: "57px",
        height: "40px",
        position: "absolute",
        left: 4,
        top: 0,
        zIndex: "-1",
        backgroundColor:
          theme.palette.type === "dark"
            ? theme.palette.borderDark
            : theme.palette.primary.light,
      },
    },
  },
  icon: {
    minWidth: theme.spacing(5),
    fill:
      theme.palette.type === "dark"
        ? theme.palette.primary.light
        : theme.palette.primary.dark,
  },
  head: {
    padding: `${theme.spacing(1)}px 0`,
    margin: `${theme.spacing(1)}px 0`,
    borderRadius: `0 ${theme.spacing(3)}px ${theme.spacing(3)}px 0`,
    paddingLeft: theme.spacing(3),
    "&$iconed": {
      paddingLeft: theme.spacing(3),
    },
    "& svg[class^=\"MuiSvgIcon\"]": {
      left: -10,
      position: "relative",
    },
  },
  headCapital: {
    padding: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px ${theme.spacing(
      9
    )}px`,
    left: theme.spacing(1) * -2,
    position: "relative",
    textTransform: "uppercase",
    borderRadius: `0 ${theme.spacing(3)}px ${theme.spacing(3)}px 0`,
    margin: `${theme.spacing(1)}px`,
    "& span": {
      fontSize: 14,
    },
  },
  copyright: {
    color: theme.palette.text.secondary,
    background: theme.palette.background.paper,
    padding: theme.spacing(2),
    position: "fixed",
    [theme.breakpoints.up("lg")]: {
      background: "none",
      position: "absolute",
    },
    bottom: 0,
    left: theme.spacing(3),
    lineHeight: "24px",
  },
  brand: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 10px 5px",
    height: 64,
    position: "relative",
    textDecoration: "none",
    fontSize: 16,
    margin: 0,
    fontWeight: 500,
    color: theme.palette.text.primary,
    "& svg": {
      width: 100,
    },
  },
  brandBig: {
    paddingTop: theme.spacing(4),
    position: "relative",
    textAlign: "center",
    "& img": {
      width: 68,
    },
    "& h3": {
      fontSize: 18,
      marginTop: theme.spacing(2),
      fontWeight: 500,
      color: theme.palette.text.primary,
    },
  },
  profile: {
    height: 120,
    width: "100%",
    display: "flex",
    fontSize: 14,
    padding: 10,
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
    position: "absolute",
    margin: `${theme.spacing(2)}px 0`,
    zIndex: 0,
    "& h4": {
      fontSize: 18,
      marginBottom: 0,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      width: 110,
    },
    "& button": {
      fontSize: 12,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: 110,
      display: "block",
      overflow: "hidden",
      textTransform: "capitalize",
      padding: 0,
      minHeight: 20,
      marginTop: 4,
    },
  },
  statusMenu: {
    "& li": {
      width: 100,
    },
  },
  dotStatus: {
    width: theme.spacing(1),
    height: theme.spacing(1),
    display: "inline-block",
    borderRadius: "50%",
    marginRight: theme.spacing(0.5),
  },
  online: {
    backgroundColor: lightGreen[500],
  },
  bussy: {
    backgroundColor: red[500],
  },
  idle: {
    backgroundColor: amber[500],
  },
  offline: {
    backgroundColor: grey[500],
  },
  rounded: {},
  landingNav: {},
  withProfile: {},
  menuContainer: {
    overflow: "auto",
    height: "calc(100% - 120px)",
    width: contentWidth,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    position: "relative",
    display: "block",
    "&$landingNav": {
      [theme.breakpoints.down("lg")]: {
        height: "calc(100% - 164px)",
        paddingTop: theme.spacing(5),
      },
    },
    "&$rounded": {
      "& $opened": {
        "&:before": {
          background: theme.palette.primary.main,
        },
      },
    },
    scrollBarWidth: "none",
    "&-ms-overflow-style": "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  collapsedMenu: {
    width: sidebarCollapsedWidth,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  divider: {
    marginTop: theme.spacing(1),
  },
  orgLogo: {
    width: 60,
    height: 60,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: ".5rem",
    marginTop: 12,
    backgroundColor: "#fff",
    border: "1px solid #999999",
    [theme.breakpoints.down("xs")]: {
      borderRadius: "50%",
      width: "75px",
      height: "75px",
      border: "1px solid #CCC",
    },
  },
  orgLogoName: {
    fontWeight: "bold",
    fontSize: "18px",
  },
  orgLogoImage: {
    width: 85,
    maxWidth: "75%",
  },
  orgName: {
    textAlign: "center",
    fontWeight: 300,
    fontSize: "16px",
    color: "#000",
  },
  drawerFooter: {
    bottom: 0,
    paddingBottom: 0,
    zIndex: 1,
    position: "absolute",
    width: "100%",
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("xs")]: {
      background: "#fff",
    },
  },
  meetusLogo: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 180,
  },
  collapsedLogoHeight: {
    height: 50,
  },
  drawer: {
    background: `${theme.palette.mainSideBar} !important`,
  },
  menuButton: {
    width: 24,
    height: 24,
    minHeight: 24,
    padding: 4,
    border: "1px solid #000",
    position: "absolute",
    zIndex: 9999,
    top: 25,
    right: 0,
    background: "#FFF",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  closeIcon: {
    display: "none",
    position: "absolute",
    right: "8px",
    top: "12px",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      transition: "none",
    },
  },
  lineContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 32,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  line: {
    width: "60%",
    height: "2px",
    background: "#999999",
  },
});

export default styles;
