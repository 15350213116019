import React from "react";

const FollowerIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.10671 7.24732C6.04004 7.24065 5.96004 7.24065 5.88671 7.24732C4.30004 7.19398 3.04004 5.89398 3.04004 4.29398C3.04004 2.66065 4.36004 1.33398 6.00004 1.33398C7.63337 1.33398 8.96004 2.66065 8.96004 4.29398C8.95337 5.89398 7.69337 7.19398 6.10671 7.24732Z"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9402 2.66602C12.2335 2.66602 13.2735 3.71268 13.2735 4.99935C13.2735 6.25935 12.2735 7.28602 11.0268 7.33268C10.9735 7.32602 10.9135 7.32602 10.8535 7.33268"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.77348 9.70602C1.16014 10.786 1.16014 12.546 2.77348 13.6193C4.60681 14.846 7.61348 14.846 9.44681 13.6193C11.0601 12.5393 11.0601 10.7793 9.44681 9.70602C7.62014 8.48602 4.61348 8.48602 2.77348 9.70602Z"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.2266 13.334C12.7066 13.234 13.1599 13.0407 13.5332 12.754C14.5732 11.974 14.5732 10.6873 13.5332 9.90732C13.1666 9.62732 12.7199 9.44065 12.2466 9.33398"
      stroke="currentcolor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FollowerIcon;
