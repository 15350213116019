import React from "react";

const Mobile = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    {...props}
  >
    <path
      d="M12.0982 2.06055H6.01205C4.03118 2.06055 3.31348 2.77825 3.31348 4.79501V13.6802C3.31348 15.697 4.03118 16.4147 6.01205 16.4147H12.091C14.0791 16.4147 14.7968 15.697 14.7968 13.6802V4.79501C14.7968 2.77825 14.0791 2.06055 12.0982 2.06055ZM9.05512 14.4769C8.36612 14.4769 7.79914 13.9099 7.79914 13.2209C7.79914 12.5319 8.36612 11.9649 9.05512 11.9649C9.74412 11.9649 10.3111 12.5319 10.3111 13.2209C10.3111 13.9099 9.74412 14.4769 9.05512 14.4769ZM10.4905 5.1108H7.61971C7.32545 5.1108 7.08143 4.86678 7.08143 4.57252C7.08143 4.27826 7.32545 4.03424 7.61971 4.03424H10.4905C10.7848 4.03424 11.0288 4.27826 11.0288 4.57252C11.0288 4.86678 10.7848 5.1108 10.4905 5.1108Z"
      fill="currentColor"
    />
  </svg>
);

export default Mobile;
