import axios from "axios";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import appConfig from "../../../config.json";

let firebaseConfig;
let messaging = {};

const getFirebaseConfig = async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_RESTAPI}/frontend/setting?frontend_id=${appConfig.identifier}`,
    );

    firebaseConfig = {
      apiKey: data.firebase_api_key,
      authDomain: data.firebase_auth_domain,
      projectId: data.firebase_project_id ?? "",
      storageBucket: data.firebase_storage_bucket,
      messagingSenderId: data.firebase_messaging_sender_id,
      appId: data.firebase_app_id,
      measurementId: data.firebase_measurement_id,
      firebase_vap_id_key: data.firebase_vap_id_key,
    };

    const firebaseApp = initializeApp(firebaseConfig);
    messaging = getMessaging(firebaseApp);
  } catch (error) {
    updateState({
      errorMessage:
        "An error occurred while setting up the application config. Please reload the page.",
    });
  }
};

getFirebaseConfig();

export const onMessageListener = (fn) => {
  onMessage(messaging, fn);
};

const firebaseServiceWorkerFile = "firebase-messaging-sw.js";
export const getOrRegisterServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    return window.navigator.serviceWorker.register(firebaseServiceWorkerFile);
  }
  throw new Error("The browser doesn`t support service worker.");
};

export const requestForToken = (updateState) =>
  getOrRegisterServiceWorker()
    .then((registration) => {
      if (firebaseConfig.firebase_vap_id_key) {
        getToken(messaging, {
          vapidKey: firebaseConfig.firebase_vap_id_key,
          serviceWorkerRegistration: registration,
        })
          .then((currentToken) => {
            if (currentToken) {
              updateState({ deviceToken: currentToken, errorMessage: "" });
            } else {
              updateState({
                errorMessage: "Unexpected error",
              });
            }
          })
          .catch((err) => {
            updateState({
              errorMessage:
                "To Stay Informed, Enable Notifications, and logged in again",
            });
          });
      } else {
        updateState({
          errorMessage:
            "Regretfully, Notification system is currently disabled!",
        });
        return "";
      }
    })
    .catch((err) => {
      updateState({
        errorMessage: "Unexpected error",
      });
    });
