import moment from "moment";
import { toast } from "react-toastify";
import { getCookie } from "../utils/cookiesHelper";
import CHAT_ACTIONS_TYPES from "./actionTypes/chatActionTypes";

export const CHAT_INITIAL_STATE = {
  orgUserToken: getCookie("X-User-Token"),
  jwtToken: getCookie("chatJwtToken") || null,
  userAgentData: {},
  isTyping: false,
  chatWidgetSettings: {},
  rooms: [],
  roomChat: [],
  customerId: null,
  selectedRoom: {},
  joinedRoomBefore: false,
  loadingRooms: true,
  loadingChat: true,
  searchQueryForRoom: null,
  // searchKey is used for handle CTRL+F for search
  searchKey: "rooms",
  searchQueryForMessage: null,
  searchMessagesResult: [],
  images: [],
  imagesUploadProgress: {},
  attachments: {},
  sendPromoModal: false,
  promoCodeSelected: null,
  sendProductsModal: false,
  filterQueryForRooms: null,
};

const chatReducer = (state = CHAT_INITIAL_STATE, action) => {
  switch (action.type) {
    case CHAT_ACTIONS_TYPES.SET_JWT_TOKEN: {
      return {
        ...state,
        jwtToken: action?.payload?.jwtToken,
      };
    }

    case CHAT_ACTIONS_TYPES.SET_AGENT_USER_DATA: {
      return {
        ...state,
        userAgentData: action?.payload?.userAgentData,
      };
    }

    case CHAT_ACTIONS_TYPES.SET_ROOMS: {
      const roomsSorted = action?.payload?.rooms?.sort((a, b) => (moment(a.updatedAt)?.isAfter(b.updatedAt) ? -1 : 1));
      return {
        ...state,
        rooms: roomsSorted,
      };
    }

    case CHAT_ACTIONS_TYPES.SET_ROOM_CHAT: {
      return {
        ...state,
        roomChat: action?.payload?.roomChat,
      };
    }

    case CHAT_ACTIONS_TYPES.SET_CHAT_WIDGET_SETTINGS: {
      return {
        ...state,
        chatWidgetSettings: action?.payload?.chatWidgetSettings,
      };
    }

    case CHAT_ACTIONS_TYPES.SET_TYPING: {
      if ((action?.payload?.roomId === state?.selectedRoom?._id) || action?.payload?.force) {
        return {
          ...state,
          isTyping: action?.payload?.isTyping,
        };
      }
      return {
        ...state,
      };
    }

    case CHAT_ACTIONS_TYPES.SET_SELECTED_ROOM: {
      return {
        ...state,
        selectedRoom: action?.payload?.selectedRoom,
      };
    }

    case CHAT_ACTIONS_TYPES.SET_CUSTOMER_ID: {
      return {
        ...state,
        customerId: action?.payload?.customerId,
      };
    }

    case CHAT_ACTIONS_TYPES.ADD_NEW_MESSAGE_TO_ROOM: {
      if (action?.payload?.newMessage?.chat?._id === state?.selectedRoom?._id) {
        return {
          ...state,
          roomChat: [...state.roomChat, action?.payload?.newMessage],
        };
      }
      return { ...state };
    }

    case CHAT_ACTIONS_TYPES.SET_LOADING_ROOMS: {
      return {
        ...state,
        loadingRooms: action?.payload?.loadingRooms,
      };
    }

    case CHAT_ACTIONS_TYPES.SET_LOADING_CHAT: {
      return {
        ...state,
        loadingChat: action?.payload?.loadingChat,
      };
    }

    case CHAT_ACTIONS_TYPES.SET_MESSAGE_SEEN: {
      return {
        ...state,
        roomChat: state.roomChat.map((message) =>
          (message._id === action?.payload?.messageId
            ? { ...message, seen: true }
            : message)
        ),
      };
    }

    case CHAT_ACTIONS_TYPES.SET_JOINED_ROOM_BEFORE: {
      return {
        ...state,
        joinedRoomBefore: action?.payload?.joinedRoomBefore,
      };
    }

    case CHAT_ACTIONS_TYPES.SEARCH_FOR_ROOM: {
      return {
        ...state,
        searchQueryForRoom: action?.payload?.searchQueryForRoom,
      };
    }

    case CHAT_ACTIONS_TYPES.SET_SEARCH_KEY: {
      return {
        ...state,
        searchKey: action?.payload?.searchKey,
      };
    }

    case CHAT_ACTIONS_TYPES.SEARCH_FOR_MESSAGE: {
      return {
        ...state,
        searchQueryForMessage: action?.payload?.searchQueryForMessage,
      };
    }

    case CHAT_ACTIONS_TYPES.SET_SEARCH_MESSAGES_RESULT: {
      return {
        ...state,
        searchMessagesResult: action?.payload?.searchMessagesResult,
      };
    }

    case CHAT_ACTIONS_TYPES.APPEND_NEW_ROOM: {
      return {
        ...state,
        rooms: [action?.payload?.newRoom, ...state.rooms]
      };
    }

    case CHAT_ACTIONS_TYPES.REMOVE_JWT_TOKEN: {
      return {
        ...state,
        jwtToken: null,
      };
    }

    case CHAT_ACTIONS_TYPES.SET_IMAGES_FOR_UPLOAD: {
      return {
        ...state,
        images: [...state.images, ...action.payload.images],
      };
    }

    case CHAT_ACTIONS_TYPES.SET_IMAGES_UPLOAD_PROGRESS: {
      return {
        ...state,
        imagesUploadProgress: {
          ...state.imagesUploadProgress,
          ...action.payload.imagesUploadProgress,
        },
      };
    }

    case CHAT_ACTIONS_TYPES.SET_ATTACHMENTS: {
      return {
        ...state,
        attachments: { ...state.attachments, ...action.payload.attachments },
      };
    }

    case CHAT_ACTIONS_TYPES.REMOVE_IMAGE_ATTACHMENT: {
      const fileNameToRemove = action.payload.fileName;

      const imagesAfterRemove = state.images.filter(
        (file) => file.name !== fileNameToRemove
      );

      const imagesUploadProgressAfterRemove = { ...state.imagesUploadProgress };
      delete imagesUploadProgressAfterRemove[fileNameToRemove];

      const attachmentsAfterRemove = { ...state.attachments };
      delete attachmentsAfterRemove[fileNameToRemove];

      return {
        ...state,
        images: imagesAfterRemove,
        imagesUploadProgress: imagesUploadProgressAfterRemove,
        attachments: attachmentsAfterRemove,
      };
    }

    case CHAT_ACTIONS_TYPES.REMOVE_ALL_ATTACHMENTS: {
      return {
        ...state,
        attachments: [],
        imagesUploadProgress: {},
        filesUploadProgress: {},
        images: [],
        files: [],
      };
    }

    case CHAT_ACTIONS_TYPES.SET_PROMO_CODE_MODAL: {
      return {
        ...state,
        sendPromoModal: action?.payload?.sendPromoModal
      };
    }

    case CHAT_ACTIONS_TYPES.SET_PROMO_CODE: {
      return {
        ...state,
        promoCodeSelected: action?.payload?.promoCodeSelected
      };
    }

    case CHAT_ACTIONS_TYPES.SET_PRODUCTS_MODAL: {
      return {
        ...state,
        sendProductsModal: action?.payload?.sendProductsModal
      };
    }

    case CHAT_ACTIONS_TYPES.FILTER_QUERY_FOR_ROOMS: {
      return {
        ...state,
        filterQueryForRooms: action?.payload?.filterQueryForRooms,
      };
    }

    case CHAT_ACTIONS_TYPES.UPDATE_ROOM_CONTENT: {
      const newRooms = state.rooms.map((room) => (room._id === action.payload.roomUpdated._id ? action.payload.roomUpdated : room));
      return {
        ...state,
        rooms: newRooms,
      };
    }

    case CHAT_ACTIONS_TYPES.UPDATE_LATEST_MESSAGE_AND_SORT: {
      const roomIsReplaced = state.rooms.map((room) => (room._id === action.payload.roomUpdated._id ? action.payload.roomUpdated : room));
      const roomsSorted = roomIsReplaced.sort((a, b) => (moment(a.updatedAt)?.isAfter(b.updatedAt) ? -1 : 1));
      return {
        ...state,
        rooms: roomsSorted,
      };
    }

    case CHAT_ACTIONS_TYPES.FIRE_ERROR_MESSAGE: {
      if (action?.payload?.roomId === state?.selectedRoom?._id) {
        toast.error(action.payload.message);
        return {
          ...state,
        };
      }
      return {
        ...state,
      };
    }

    case CHAT_ACTIONS_TYPES.FIRE_NOTIFY_MESSAGE: {
      if (action?.payload?.roomId === state?.selectedRoom?._id) {
        toast(action.payload.message);
        return {
          ...state,
        };
      }
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};

export default chatReducer;
