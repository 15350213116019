import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import UrlUtils from "utils/url";
import messageStyles from "styles/components/Messages.module.scss";
import classNames from "classnames";
import Chip from "@material-ui/core/Chip";
import Popup from "components/Popup/Popup";
import EnhancedDataTable from "components/Tables/EnhancedDataTable";
import { connect } from "react-redux";
import { capitalizeFirstLetter } from "utils/strings";
import TagsIcon from "api/icons/DashboardIcons/TagsIcon";
import { getCurrencyFormatter } from "utils/currency";
import BottomPagination from "containers/Pages/Metaverse/ManageEvents/BottomPagination/index";
import Spinner from "components/Spinner/Spinner";
import { goToTop } from "utils/helpers";
import EmptyData from "./EmptyData";
import styles from "./ProductPicker-jss";

class ProductsPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      products: [],
      rowsPerPage: 12,
      page: 0,
      loading: true,
      totalPages: 1,
      filters: {},
      availableFilters: undefined,
      showingAllProducts: false,
    };
  }

  componentDidMount() {
    this.getProducts();
  }

  componentDidUpdate(prevProps) {
    const {
      getAddedProducts,
      getAllProducts,
    } = this.props;
    const {
      getAddedProducts: oldGetAddedProducts,
      getAllProducts: oldGetAllProducts,
    } = prevProps;
    const { showingAllProducts } = this.state;
    if (
      (!showingAllProducts && getAddedProducts !== oldGetAddedProducts)
      || (showingAllProducts && getAllProducts !== oldGetAllProducts)
    ) {
      this.getProducts(true);
    }
  }

  getColumns = () => {
    const {
      classes,
      getProductLink,
      addButtonLabel,
      removeButtonLabel,
      showExtraColumns,
      brandsMap,
      currency,
      removeProduct,
      addProduct,
    } = this.props;
    const { availableFilters } = this.state;
    const { prices = {} } = availableFilters || {};
    const formatMoney = getCurrencyFormatter(currency);
    return [
      {
        name: "id",
        label: "ID",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "image_url",
        label: "Image",
        options: {
          filter: false,
          sort: false,
          renderValue: (value, row) => (
            <div className={classes.productImage}>
              <img src={UrlUtils.getProperImageUrl(value)} alt={row.name} />
            </div>
          ),
        },
      },
      {
        name: "name",
        label: "Product name",
        options: {
          filter: false,
          sort: false,
          renderValue: (value, row) => (
            <>
              <p>{value}</p>
              {Array.isArray(row.tags) && (
                <p>
                  {row.tags
                    .slice(0, 3)
                    .map((tag) => tag.alias || tag.name)
                    .join(", ")}
                </p>
              )}
            </>
          ),
        },
      },
      {
        name: "tags",
        label: "Tags",
        options: {
          display: "excluded",
          filter: true,
          filterType: "multiselect",
          filterOptions: {
            names: availableFilters?.tags || [],
            renderValue: (tag) => tag.alias || tag.name
          },
          customFilterListOptions: {
            render: (tag) => tag.alias || tag.name
          }
        }
      },
      {
        name: "brand_id",
        label: "Brand",
        options: {
          display: showExtraColumns || "excluded",
          filter: true,
          sort: false,
          renderValue: (value) => {
            const logo = brandsMap[value]?.banner;
            const brand = brandsMap[value]?.name || value;
            return (
              <div className={classes.brand}>
                <div className={classes.brandImgContainer}>
                  <img
                    className={classes.brandImg}
                    src={UrlUtils.getProperImageUrl(logo)}
                    alt={brand}
                    title={brand}
                  />
                </div>
                <p>{brand}</p>
              </div>
            );
          },
          filterOptions: {
            names: Object.keys(brandsMap),
            renderValue: (value) => brandsMap[value]?.name || value,
          },
          customFilterListOptions: {
            render: (value) => brandsMap[value]?.name || value
          }
        },
      },
      {
        name: "price",
        label: "Price",
        options: {
          renderValue: (value) => (
            <p>
              {value}
              <span className={classes.currency}>{currency}</span>
            </p>
          )
        },
      },
      {
        name: "quantity",
        label: "Availability",
        options: {
          display: showExtraColumns || "excluded",
          filter: true,
          sort: false,
          renderValue: (value) => (
            <Chip
              label={value ? "In stock" : "Out of Stock"}
              className={classNames(
                classes.chip,
                value ? messageStyles.bgSuccess : messageStyles.bgError
              )}
            />
          ),
        },
      },
      {
        name: "added",
        title:
          capitalizeFirstLetter(addButtonLabel)
          + "/"
          + capitalizeFirstLetter(removeButtonLabel),
        options: {
          filter: false,
          display: Boolean(addProduct || removeProduct) || "excluded",
          renderValue: (value, row) => (
            <Chip
              label={value ? removeButtonLabel : addButtonLabel}
              onClick={() => {
                if (value) this.handleRemoveProduct(row.id, row.name);
                else this.handleAddProduct(row.id);
              }}
              className={classNames(
                classes.chip,
                value ? messageStyles.bgError : messageStyles.bgSuccess
              )}
            />
          ),
        },
      },
    ];
  };

  getProducts = async (resetRows = true) => {
    this.setState({ loading: true, ...(resetRows ? { products: [] } : {}) });
    const {
      getAddedProducts,
      getAllProducts
    } = this.props;
    const { page, rowsPerPage, searchText, showingAllProducts, filters } = this.state;
    const getProducts = showingAllProducts ? getAllProducts : getAddedProducts;
    const productsPromise = getProducts(
      {
        ...filters,
        name: searchText,
        start: page,
        count: rowsPerPage
      }
    );
    this.getFilters();
    const { products, total } = await productsPromise;
    this.setState({ loading: false, products, total, totalPages: Math.ceil(total / rowsPerPage)
    });
  };

  getFilters = async () => {
    const { getAllFilters, getAddedFilters } = this.props;
    let { availableFilters } = this.state;
    const { filters, searchText, showingAllProducts, page } = this.state;
    const getFilters = showingAllProducts ? getAllFilters : getAddedFilters;
    const isFiltered = searchText || Object.values(filters).some((filter) =>
      (Array.isArray(filter) ? filter.length : filter)
    );
    if (getFilters && !page && (!availableFilters || !isFiltered)) {
      availableFilters = await getFilters(filters);
      this.setState({ availableFilters });
    }
    goToTop();
  };

  handleSearchTextChange = (event) => {
    const searchText = event.target.value;
    this.setState({ searchText, page: 0 }, this.getProducts);
  };

  handleAddProduct = async (productId) => {
    const { addProduct } = this.props;
    if (!addProduct) return;
    try {
      await addProduct(productId);
    } finally {
      this.getProducts(false);
    }
  };

  handleRemoveProduct = async (productId, productName) => {
    const { removeProduct } = this.props;
    if (!removeProduct) return;
    try {
      await removeProduct(productId, productName);
    } finally {
      this.getProducts(false);
    }
  };

  handleChangePage = (_, page) => {
    this.setState({ page }, this.getProducts);
  };

  handleChangeRowsPerPage = ({ target: { value } }) => {
    this.setState({ rowsPerPage: value, page: 0 }, this.getProducts);
  };

  showHideAllProducts = (show) => {
    this.setState({
      showingAllProducts: show,
      searchText: "",
      products: [],
      rowsPerPage: 12,
      page: 0,
      filters: {},
    }, this.getProducts);
  };

  showAllProducts = () => this.showHideAllProducts(true);

  hideAllProducts = () => this.showHideAllProducts(false);

  render() {
    const {
      addedProductsTitle,
      allProductsTitle,
      getAllProducts,
      classes,
      getTableOptions,
      tableName
    } = this.props;
    const {
      products,
      total,
      page,
      rowsPerPage,
      showingAllProducts,
      loading,
      searchText,
      availableFilters,
      totalPages,
    } = this.state;

    const popupProps = {
      open: true,
      title: "Add Product",
      fullWidthfullScreen: true,
      maxWidth: "xl",
      onClose: this.hideAllProducts,
      showCloseButton: true,
    };

    const Wrapper = showingAllProducts
      ? Popup
      : React.Fragment;

    const wrapperProps = showingAllProducts ? popupProps : {};

    const options = {
      filterType: "dropdown",
      responsive: "standard",
      sort: false,
      pagination: false,
      filter: false,
      search: false,
      viewColumns: false,
      download: false,
      print: false,
      count: total,
      rowsPerPage,
      page,
      textLabels: {
        body: {
          noMatch: loading ? "Loading ..." : "Sorry, no matching records found",
        },
      },
      serverSide: true,
      confirmFilters: true,
      sort: false,
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => (
        <div style={{ marginTop: "40px" }}>
          <Button variant="contained" onClick={applyNewFilters}>
            Filter
          </Button>
        </div>
      ),
      onConfirmedFiltersChange: ({
        prices: [minPrice, maxPrice] = [],
        brand_id: brandId,
        tags = []
      }) => {
        this.setState(
          {
            filters: {
              minPrice,
              maxPrice,
              brand_id: brandId,
              tags: tags.map((tag) => tag.id),
            },
            page: 0,
          },
          this.getProducts
        );
      },
      onChangePage: (pageIndex) => {
        this.setState({ page: pageIndex }, this.getProducts);
      },
      onChangeRowsPerPage: (numberOfRows) => {
        this.setState(
          { rowsPerPage: numberOfRows, page: 0 },
          this.getProducts
        );
      },
      searchText,
      onSearchChange: (newSearchText) => {
        this.setState({ searchText: newSearchText }, this.getProducts);
      },
      downloadOptions: {
        filterOptions: {
          useDisplayedColumnsOnly: true
        }
      },
      ...getTableOptions(this.getProducts),
      selectableRows: "none",
      sortOrder: {
        name: "id",
        direction: "desc",
      },
    };

    const getContent = () => {
      if (loading) {
        return <div className={classes.spinner}><Spinner /></div>;
      }

      if (!loading && products?.length) {
        return (
          <div className={classes.wrapperContainer}>
            <EnhancedDataTable
              data={products}
              columns={this.getColumns()}
              options={options}
              className={classes.tableLong}
            />
            <BottomPagination
              start={page}
              pages={totalPages}
              count={rowsPerPage}
              setCount={(count) => {
                this.setState({ rowsPerPage: count, page: 0 }, this.getProducts);
              }}
              setStart={(start) => {
                this.setState({ page: start }, this.getProducts);
              }}
              totalElements={total}
            />
          </div>
        );
      }

      if (!loading && !products?.length) {
        return (
          <EmptyData
            icon={<TagsIcon />}
            msg="No Products"
            description="There are no products have been added yet"
          />
        );
      }
    };

    return (
      <Wrapper {...wrapperProps}>
        {getContent()}
      </Wrapper>
    );
  }
}

ProductsPicker.propTypes = {
  classes: PropTypes.object.isRequired,
  getAllProducts: PropTypes.func,
  allProductsTitle: PropTypes.node,
  addedProductsTitle: PropTypes.node.isRequired,
  getAddedProducts: PropTypes.func.isRequired,
  addProduct: PropTypes.func,
  removeProduct: PropTypes.func,
  getProductLink: PropTypes.func,
  addedProductsFilters: PropTypes.object,
  allProductsFilters: PropTypes.object,
  getAddedFilters: PropTypes.func,
  getAllFilters: PropTypes.func,
  showExtraColumns: PropTypes.bool,
  brandsMap: PropTypes.object,
  currency: PropTypes.string.isRequired,
  addButtonLabel: PropTypes.node,
  removeButtonLabel: PropTypes.node,
  getTableOptions: PropTypes.func,
};

ProductsPicker.defaultProps = {
  getAllProducts: undefined,
  allProductsTitle: null,
  addProduct: undefined,
  removeProduct: undefined,
  getProductLink: undefined,
  addedProductsFilters: {},
  allProductsFilters: {},
  getAddedFilters: undefined,
  getAllFilters: undefined,
  showExtraColumns: false,
  brandsMap: {},
  addButtonLabel: "add",
  removeButtonLabel: "remove",
  getTableOptions: () => ({}),
};

const ReduxedProductsPicker = connect(
  (state) => ({
    currency: state.getIn([
      "cachedData",
      "cache",
      "orgData",
      "value",
      "currency",
    ]),
  }),
  null,
  null,
  { forwardRef: true }
)(ProductsPicker);

export default withStyles(styles)(ReduxedProductsPicker);
