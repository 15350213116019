const styles = (theme) => ({
  emptyDataContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 20,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    "& p": {
      marginBottom: 0,
    },
  },
  iconContainer: {
    width: 112,
    height: 112,
    padding: 16,
    borderRadius: "50%",
    background: "rgba(255, 84, 0, 0.10)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      width: 80,
      height: 80,
      flexShrink: 0,
      "& path": {
        fill: "#FF5400",
      },
    },
  },
  emptyDataContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: 12,
  },
  emptyDataTitle: {
    fontSize: 32,
    fontWeight: 600,
    textTransform: "capitalize",
  },
  emptyDataDescription: {
    maxWidth: 275,
    fontSize: 14,
    fontWeight: 400,
    color: "#4E5057",
  },
});

export default styles;
