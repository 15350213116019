export const clampOneLine = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  wordBreak: "break-all",
  maxWidth: "100%",
};

export const positionCenter = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export const flexCenter = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
