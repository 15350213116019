import React from "react";

const NotificationIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    {...props}
  >
    <path
      d="M10.0167 17.6083C8.07505 17.6083 6.13339 17.3 4.29172 16.6833C3.59172 16.4417 3.05839 15.95 2.82505 15.3083C2.58339 14.6667 2.66672 13.9583 3.05005 13.325L4.00839 11.7333C4.20839 11.4 4.39172 10.7333 4.39172 10.3417V7.93335C4.39172 4.83335 6.91672 2.30835 10.0167 2.30835C13.1167 2.30835 15.6417 4.83335 15.6417 7.93335V10.3417C15.6417 10.725 15.8251 11.4 16.0251 11.7417L16.9751 13.325C17.3334 13.925 17.4001 14.65 17.1584 15.3083C16.9167 15.9667 16.3917 16.4667 15.7334 16.6833C13.9001 17.3 11.9584 17.6083 10.0167 17.6083ZM10.0167 3.55835C7.60839 3.55835 5.64172 5.51668 5.64172 7.93335V10.3417C5.64172 10.95 5.39172 11.85 5.08339 12.375L4.12505 13.9667C3.94172 14.275 3.89172 14.6 4.00005 14.875C4.10005 15.1583 4.35005 15.375 4.69172 15.4917C8.17505 16.6583 11.8667 16.6583 15.3501 15.4917C15.6501 15.3917 15.8834 15.1667 15.9917 14.8667C16.1001 14.5667 16.0751 14.2417 15.9084 13.9667L14.9501 12.375C14.6334 11.8333 14.3917 10.9417 14.3917 10.3333V7.93335C14.3917 5.51668 12.4334 3.55835 10.0167 3.55835Z"
      fill="#0B0B0B"
    />
    <path
      d="M11.5667 3.78328C11.5084 3.78328 11.4501 3.77494 11.3917 3.75828C11.1501 3.69161 10.9167 3.64161 10.6917 3.60828C9.98339 3.51661 9.30005 3.56661 8.65839 3.75828C8.42505 3.83328 8.17505 3.75828 8.01672 3.58328C7.85839 3.40828 7.80839 3.15828 7.90005 2.93328C8.24172 2.05828 9.07505 1.48328 10.0251 1.48328C10.9751 1.48328 11.8084 2.04994 12.1501 2.93328C12.2334 3.15828 12.1917 3.40828 12.0334 3.58328C11.9084 3.71661 11.7334 3.78328 11.5667 3.78328Z"
      fill="#0B0B0B"
    />
    <path
      d="M10.0167 19.5083C9.19172 19.5083 8.39172 19.175 7.80839 18.5916C7.22506 18.0083 6.89172 17.2083 6.89172 16.3833H8.14172C8.14172 16.875 8.34172 17.3583 8.69172 17.7083C9.04172 18.0583 9.52506 18.2583 10.0167 18.2583C11.0501 18.2583 11.8917 17.4166 11.8917 16.3833H13.1417C13.1417 18.1083 11.7417 19.5083 10.0167 19.5083Z"
      fill="#0B0B0B"
    />
  </svg>
);

export default NotificationIcon;
