import React from "react";

const ArrowIcon = () => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2349 15.0434L18.6419 8.0394L11.2349 1.03613"
      stroke="#5D5FEF"
      strokeWidth="1.4036"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.828125 8.03931H15.6414"
      stroke="#5D5FEF"
      strokeWidth="1.4036"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowIcon;
