import React, { useState } from "react";
import {
  Chip,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  withStyles,
} from "@material-ui/core";
import ArrowDown from "api/icons/ArrowDown";
import ArrowUp from "api/icons/ArrowUp";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router";
import styles from "./MainMenu-jss";

const LinkBtn = React.forwardRef((props, ref) => (
  <NavLink to={props.to} {...props} innerRef={ref} />
));

const MainMenu = ({
  classes,
  openSubMenu,
  open,
  dataMenu,
  isCollapsed,
  loadTransition,
  mobileView,
  toggleDrawerOpen
}) => {
  const [selectedGroup, setSelectedGroup] = useState({});
  const pagePathname = window.location.pathname.split("/")[2];
  const history = useHistory();

  const handleClick = () => {
    loadTransition(false);
    if (mobileView) {
      toggleDrawerOpen();
    }
  };

  const onMouseEnter = (item) => {
    setSelectedGroup(item);
  };

  const onMouseLeave = () => {
    setSelectedGroup({});
  };

  const renderCollapsedMenuItems = (menuArray) =>
    menuArray.map((item) => {
      const isActive = selectedGroup.groupKey === item.groupKey;

      function isPathInGroupList(historyPath, groupList) {
        const splitPath = historyPath.slice(1)?.split("/")?.[0];
        for (const group of groupList) {
          if (group.link && splitPath === group.link.slice(1)) {
            return true;
          }

          if (group.child) {
            for (const child of group.child) {
              if (child.link && splitPath === child.link.slice(1)) {
                return true;
              }
            }
          }
        }

        return false;
      }

      const isActivePath = isPathInGroupList(history.location.pathname, item.groupList);

      return (
        <div key={item.groupKey} className={classes.sidebarGroupContainer}>
          <div
            onMouseEnter={() => onMouseEnter(item)}
            onMouseLeave={onMouseLeave}
            className={classNames(classes.groupMenuWidth, isActive ? classes.menuActive : "", isActivePath ? classes.mainIconActive : "")}
          >
            <div>{item.mainIcon}</div>
            {/* <p>{item.groupTitle}</p> */}
          </div>
          <div
            onMouseEnter={() => onMouseEnter(item)}
            onMouseLeave={onMouseLeave}
            className={`${classes.sidebarGroupMenu} ${isActive
              ? classes.showSidebarGroupMenu
              : classes.hideSidebarGroupMenu
            }`}
          >
            <p className={classes.mainGroupTitle}>{item.groupTitle}</p>
            {renderMenuItems(item.groupList)}
          </div>
        </div>
      );
    });

  const renderMenuItems = (menuArray) =>
    menuArray.map((item) => {
      const key = item.key ?? item.groupKey;

      if (item?.groupTitle) {
        return renderGroup(item, key);
      }
      if (item.child) {
        return renderChild(item, key);
      }
      return renderItem(item, key);
    });

  const renderGroup = (item, key) => (
    <div
      key={key}
      className={classes.groupContainer}
    >
      <List
        className={classNames(
          classes.groupList,
          isCollapsed && !mobileView ? "collapsed" : ""
        )}
        component="ul"
        aria-labelledby="nested-list-subheader"
        subheader={(
          <ListSubheader
            className={classNames(
              classes.groupTitle,
              isCollapsed && !mobileView ? classes.hide : ""
            )}
            component="div"
            id="nested-list-subheader"
          >
            {item?.groupTitle}
          </ListSubheader>
        )}
      >
        {renderMenuItems(item.groupList)}
      </List>
    </div>
  );

  const renderChild = (item, key) => {
    let activeIcon = false;
    item.child.forEach((element) => {
      if (element.link === `/${pagePathname}`) {
        activeIcon = true;
        return true;
      }
      return false;
    });

    return (
      <div key={key}>
        <ListItem
          button
          className={classNames(classes.groupListItem, activeIcon ? classes.listItemWithIconActive : "")}
          onClick={() => openSubMenu(item.key, item.keyParent)}
        >
          <div className={classes.iconWithTextContainer}>
            {item.icon && (
              <ListItemIcon
                className={`${classes.groupListItemIcon} ${activeIcon ? classes.iconWithColorActive : ""}`}
              >
                {item?.icon}
              </ListItemIcon>
            )}
            <ListItemText
              className={`${classes.groupListItemText} ${activeIcon ? classes.iconWithColorActive : ""
              }`}
              classes={{ primary: classes.primary }}
              variant="inset"
              primary={item.name}
            />
          </div>
          {open.indexOf(item.key) > -1 ? (
            <ArrowUp className={activeIcon ? classes.arrowColored : ""} />
          ) : (
            <ArrowDown className={activeIcon ? classes.arrowColored : ""} />
          )}
        </ListItem>
        <Collapse
          component="div"
          className={classNames(
            classes.noList,
            item.keyParent ? classes.child : ""
          )}
          in={open.indexOf(item.key) > -1}
          timeout="auto"
          unmountOnExit
        >
          <List component="nav" dense className={classes.noPaddingNav}>
            {renderMenuItems(item.child)}
          </List>
        </Collapse>
      </div>
    );
  };

  const renderItem = (item, key) => (
    <ListItem
      key={key}
      button
      exact
      className={classNames(
        classes.groupListItem,
        item.icon ? classes.gapIcon : classes.paddingLeft
      )}
      activeClassName={classNames(
        classes.active,
        "active_menu_link",
        item.icon ? classes.listItemWithIconActive : classes.listItemActive
      )}
      component={LinkBtn}
      to={item.link}
      onClick={handleClick}
    >
      {item.icon && (
        <ListItemIcon
          className={classes.groupListItemIcon}
        >
          {item?.icon}
        </ListItemIcon>
      )}
      <ListItemText
        classes={{ primary: classes.primary }}
        primary={item.name}
      />
      {item.badge && (
        <Chip color="primary" label={item.badge} className={classes.badge} />
      )}
    </ListItem>
  );

  return (
    <div className={isCollapsed && !mobileView ? classes.sidebarCollapsed : ""}>
      {isCollapsed && !mobileView ? renderCollapsedMenuItems(dataMenu) : renderMenuItems(dataMenu)}
    </div>
  );
};

MainMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.object.isRequired,
  openSubMenu: PropTypes.func.isRequired,
  loadTransition: PropTypes.func.isRequired,
  dataMenu: PropTypes.array.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  mobileView: PropTypes.bool.isRequired,
  toggleDrawerOpen: PropTypes.func.isRequired,
};

LinkBtn.propTypes = {
  to: PropTypes.string.isRequired,
};

const OPEN_SUBMENU_ACTION = "OPEN_SUBMENU";

const openAction = (key, keyParent) => ({
  type: OPEN_SUBMENU_ACTION,
  key,
  keyParent,
});

const reducer = "ui";

const mapStateToProps = (state) => ({
  open: state.getIn([reducer, "subMenuOpen"]),
});

const mapDispatchToProps = (dispatch) => ({
  openSubMenu: bindActionCreators(openAction, dispatch),
});

const MainMenuMapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MainMenu));

export default MainMenuMapped;
