const style = (theme) => ({
  userInfoBar: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
    // overflow: "hidden",
    marginTop: "-20px",
  },
  arrowLeft: {
    transform: "scale(1.4) rotate(-180deg)",
    paddingLeft: 8,
    paddingRight: 8,
    minWidth: 40,
    "& svg": {
      fill: "transparent",
      "& path": {
        fill: "rgba(0, 0, 0, 0.54)",
      },
    },
  },
  arrowRight: {
    transform: "scale(1.4)",
    paddingLeft: 8,
    paddingRight: 8,
    minWidth: 40,
    "& svg": {
      fill: "transparent",
      "& path": {
        fill: "rgba(0, 0, 0, 0.54)",
      },
    },
  },
  listRowContainer: {
    flex: 1,
    overflow: "hidden",
  },
  listRow: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    flex: "1",
  },
  listRowItem: {
    cursor: "pointer",
    textTransform: "uppercase",
    width: "fit-content",
    padding: 8,
    fontWeight: 500,
    fontSize: 14,
    "& .MuiListItemIcon-root": {
      minWidth: "fit-content",
      marginRight: 12,
      "& svg": {
        fill: "transparent",
      },
    },
  },
  active: {
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    "& svg path": {
      stroke: theme.palette.primary.main,
    },
  },
});

export default style;
