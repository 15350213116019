import React from "react";

const PointsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M12.15 19.35C11.74 19.35 11.4 19.01 11.4 18.6V16.5C11.4 16.09 11.74 15.75 12.15 15.75C12.56 15.75 12.9 16.09 12.9 16.5V18.6C12.9 19.01 12.56 19.35 12.15 19.35Z"
      fill="#4E5057"
    />
    <path
      d="M17.9 22.75H6.40002V21C6.40002 19.48 7.63002 18.25 9.15002 18.25H15.15C16.67 18.25 17.9 19.48 17.9 21V22.75ZM7.90002 21.25H16.4V21C16.4 20.31 15.84 19.75 15.15 19.75H9.15002C8.46002 19.75 7.90002 20.31 7.90002 21V21.25V21.25Z"
      fill="#4E5057"
    />
    <path
      d="M18.15 22.75H6.15002C5.74002 22.75 5.40002 22.41 5.40002 22C5.40002 21.59 5.74002 21.25 6.15002 21.25H18.15C18.56 21.25 18.9 21.59 18.9 22C18.9 22.41 18.56 22.75 18.15 22.75Z"
      fill="#4E5057"
    />
    <path
      d="M18.43 12.44C18.22 12.44 18.01 12.35 17.86 12.18C17.67 11.96 17.62 11.65 17.74 11.39C18.08 10.61 18.25 9.78 18.25 8.91V5.91C18.25 5.56 18.19 5.22 18.07 4.86C18.06 4.83 18.05 4.79 18.04 4.75C18.01 4.6 18 4.45 18 4.31C18 3.9 18.34 3.56 18.75 3.56H19.35C21.14 3.56 22.6 5.06 22.6 6.91C22.6 8.44 21.97 9.95 20.88 11.04C20.86 11.06 20.8 11.11 20.79 11.12C20.2 11.61 19.53 12.16 18.63 12.41C18.56 12.43 18.5 12.44 18.43 12.44ZM19.68 5.09C19.73 5.36 19.75 5.64 19.75 5.91V8.91C19.75 9.32 19.72 9.71 19.66 10.11C19.72 10.06 19.77 10.02 19.83 9.97C20.63 9.17 21.1 8.05 21.1 6.91C21.1 6.01 20.49 5.25 19.68 5.09Z"
      fill="#4E5057"
    />
    <path
      d="M5.58002 12.4C5.50002 12.4 5.43002 12.39 5.35002 12.36C4.53002 12.1 3.76002 11.62 3.12002 10.98C1.97002 9.70998 1.40002 8.31998 1.40002 6.84998C1.40002 5.02998 2.83002 3.59998 4.65002 3.59998H5.30002C5.55002 3.59998 5.79002 3.72998 5.93002 3.93998C6.07002 4.14998 6.09002 4.41998 5.99002 4.64998C5.83002 5.00998 5.75002 5.41998 5.75002 5.84998V8.84998C5.75002 9.70998 5.92002 10.55 6.27002 11.35C6.39002 11.62 6.33002 11.93 6.14002 12.15C5.99002 12.31 5.79002 12.4 5.58002 12.4ZM4.30002 5.12998C3.49002 5.28998 2.90002 5.98998 2.90002 6.84998C2.90002 7.93998 3.34002 8.98998 4.21002 9.94998C4.25002 9.99998 4.30002 10.04 4.35002 10.08C4.28002 9.66998 4.25002 9.25998 4.25002 8.84998V5.84998C4.25002 5.60998 4.27002 5.36998 4.30002 5.12998Z"
      fill="#4E5057"
    />
    <path
      d="M12 16.75C7.73 16.75 4.25 13.27 4.25 9V6C4.25 3.38 6.38 1.25 9 1.25H15C17.62 1.25 19.75 3.38 19.75 6V9C19.75 13.27 16.27 16.75 12 16.75ZM9 2.75C7.21 2.75 5.75 4.21 5.75 6V9C5.75 12.45 8.55 15.25 12 15.25C15.45 15.25 18.25 12.45 18.25 9V6C18.25 4.21 16.79 2.75 15 2.75H9Z"
      fill="#4E5057"
    />
  </svg>
);

export default PointsIcon;
