import React from "react";

const InfluencerIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M17.66 22.67C17.13 22.67 16.45 22.5 15.6 22L12.61 20.23C12.3 20.05 11.7 20.05 11.4 20.23L8.4 22C6.63 23.05 5.59 22.63 5.12 22.29C4.66 21.95 3.94 21.08 4.41 19.08L5.12 16.01C5.2 15.69 5.04 15.14 4.8 14.9L2.32 12.42C1.08 11.18 1.18 10.12 1.35 9.60001C1.52 9.08001 2.06 8.16001 3.78 7.87001L6.97 7.34001C7.27 7.29001 7.7 6.97001 7.83 6.70001L9.6 3.17001C10.4 1.56001 11.45 1.32001 12 1.32001C12.55 1.32001 13.6 1.56001 14.4 3.17001L16.16 6.69001C16.3 6.96001 16.73 7.28001 17.03 7.33001L20.22 7.86001C21.95 8.15001 22.49 9.07001 22.65 9.59001C22.81 10.11 22.91 11.17 21.68 12.41L19.2 14.9C18.96 15.14 18.81 15.68 18.88 16.01L19.59 19.08C20.05 21.08 19.34 21.95 18.88 22.29C18.63 22.47 18.23 22.67 17.66 22.67ZM12 18.59C12.49 18.59 12.98 18.71 13.37 18.94L16.36 20.71C17.23 21.23 17.78 21.23 17.99 21.08C18.2 20.93 18.35 20.4 18.13 19.42L17.42 16.35C17.23 15.52 17.54 14.45 18.14 13.84L20.62 11.36C21.11 10.87 21.33 10.39 21.23 10.06C21.12 9.73001 20.66 9.46001 19.98 9.35001L16.79 8.82001C16.02 8.69001 15.18 8.07001 14.83 7.37001L13.07 3.85001C12.75 3.21001 12.35 2.83001 12 2.83001C11.65 2.83001 11.25 3.21001 10.94 3.85001L9.17 7.37001C8.82 8.07001 7.98 8.69001 7.21 8.82001L4.03 9.35001C3.35 9.46001 2.89 9.73001 2.78 10.06C2.67 10.39 2.9 10.88 3.39 11.36L5.87 13.84C6.47 14.44 6.78 15.52 6.59 16.35L5.88 19.42C5.65 20.41 5.81 20.93 6.02 21.08C6.23 21.23 6.77 21.22 7.65 20.71L10.64 18.94C11.02 18.71 11.51 18.59 12 18.59Z"
      fill="#4E5057"
    />
  </svg>
);

export default InfluencerIcon;
