import React from "react";

const EyeIcon = (props) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.8031 3.75195C6.71683 3.75195 3.22719 6.29359 1.81335 9.8813C3.22719 13.469 6.71683 16.0106 10.8031 16.0106C14.8893 16.0106 18.3789 13.469 19.7928 9.8813C18.3789 6.29359 14.8893 3.75195 10.8031 3.75195ZM10.8031 13.9675C8.54746 13.9675 6.71683 12.1369 6.71683 9.8813C6.71683 7.6257 8.54746 5.79507 10.8031 5.79507C13.0587 5.79507 14.8893 7.6257 14.8893 9.8813C14.8893 12.1369 13.0587 13.9675 10.8031 13.9675ZM10.8031 7.42956C9.44643 7.42956 8.35132 8.52467 8.35132 9.8813C8.35132 11.2379 9.44643 12.333 10.8031 12.333C12.1597 12.333 13.2548 11.2379 13.2548 9.8813C13.2548 8.52467 12.1597 7.42956 10.8031 7.42956Z"
      fill="black"
      fillOpacity="0.54"
    />
  </svg>
);

export default EyeIcon;
