import React, { useEffect, useRef, useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import { ChevronIcon } from "api/icons/GlobalIcon";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import styles from "./UserInfoBar-jss";

const UserInfoBar = ({ classes, selectedTab, setSelectedTab, list }) => {
  const userBarRef = useRef(null);
  const fullWidthRef = useRef(0);
  const leftPosRef = useRef(0);
  const calculateFullWidth = () => {
    const items = [...userBarRef?.current?.children];
    fullWidthRef.current = items?.reduce((result, cur) => (result += cur?.getClientRects()[0]?.width), 0);
  };
  useEffect(() => {
    calculateFullWidth();
  }, [userBarRef]);

  const onScroll = (direction) => {
    const left = parseInt(
      window
        .getComputedStyle(userBarRef?.current)
        .getPropertyValue("margin-left")
    );
    const width = parseInt(
      window.getComputedStyle(userBarRef?.current).getPropertyValue("width")
    );
    if (direction === "right") {
      // need to enhancement
      if (fullWidthRef?.current - leftPosRef.current > 80) {
        leftPosRef.current += 50;
        userBarRef.current.style.marginLeft = `-${leftPosRef?.current}px`;
      }
    } else if (direction === "left") {
      if (!fullWidthRef?.current - leftPosRef?.current < 80) {
        leftPosRef.current -= 50;
        userBarRef.current.style.marginLeft = `-${leftPosRef?.current - 50}px`;
      }
    }
  };

  return (
    <div className={classes.userInfoBar}>
      <Button className={classes.arrowLeft} onClick={() => onScroll("left")}>
        <ChevronIcon />
      </Button>
      <div className={classes.listRowContainer}>
        <List className={classes.listRow} ref={userBarRef}>
          {Object.values(list)?.map((item) => (
            <ListItem
              key={item?.key}
              className={classNames(
                classes.listRowItem,
                selectedTab === item?.key ? classes.active : ""
              )}
              onClick={() => setSelectedTab(item?.key)}
            >
              <ListItemIcon>{item?.icon}</ListItemIcon>
              <ListItemText>{item?.name}</ListItemText>
            </ListItem>
          ))}
        </List>
      </div>
      <Button className={classes.arrowRight} onClick={() => onScroll("right")}>
        <ChevronIcon />
      </Button>
    </div>
  );
};

export default withStyles(styles)(UserInfoBar);
