import React, { useState, useCallback } from "react";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { ArrowRightIcon } from "api/icons/GlobalIcon";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import styles from "./TagsTreeCard-jss.js";
const TagsTreeCard = ({ tag, classes }) => {
  const [dropDownList, setDropDownList] = useState();
  const toggleDropDown = (item) => {
    if (dropDownList === item) setDropDownList("");
    else setDropDownList(item);
  };
  return (
    <Box className={classes.tagsTreeCard}>
      <Typography variant="h3" className="">
        {tag?.name}
      </Typography>
      {tag?.children ? (
        <List className={classes.tagsTreeList}>
          {tag?.children?.map((child) => (
            <>
              <ListItem
                onClick={() =>
                  (child?.children?.length > 0
                    ? toggleDropDown(child?.name)
                    : undefined)}
                className={classNames(
                  classes.tagsTreeItem,
                  child?.children?.length > 0 ? classes.tagsTreeItemActive : ""
                )}
              >
                {child?.children?.length > 0 ? (
                  <>
                    <ListItemIcon
                      style={{
                        transform:
                          dropDownList === child?.name ? "rotate(90deg)" : "",
                      }}
                    >
                      <ArrowRightIcon />
                    </ListItemIcon>
                    <ListItemText primary={child?.name} />
                  </>
                ) : (
                  <>
                    <ListItemIcon />
                    <ListItemText primary={child?.name} />
                  </>
                )}
              </ListItem>
              {dropDownList === child?.name ? (
                <div className="">
                  <List>
                    {child?.children?.map((subChild) => (
                      <ListItem className={classes.tagsTreeItem}>
                        {subChild?.children?.length > 0 ? (
                          <ListItemIcon>
                            <ArrowRightIcon />
                          </ListItemIcon>
                        ) : (
                          <ListItemIcon />
                        )}
                        <ListItemText primary={subChild?.name} />
                      </ListItem>
                    ))}
                  </List>
                </div>
              ) : null}
            </>
          ))}
        </List>
      ) : (
        <Typography>No Children</Typography>
      )}
    </Box>
  );
};

export default withStyles(styles)(TagsTreeCard);
