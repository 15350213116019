import React, { useState, useEffect, useRef } from "react";
import Fade from "@material-ui/core/Fade";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Header, Sidebar } from "components";
import Spinner from "components/Spinner/Spinner";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import urlUtils from "utils/url";
import styles from "../appStyles-jss";

const meetusBg = process.env.REACT_APP_DEVELOPMENT_MODE && process.env.REACT_APP_DEVELOPMENT_MODE === "development"
  ? "./dashboard/images/meetusBg.png"
  : "./images/meetusBg.png";

const LeftSidebarLayout = (props) => {
  const {
    classes,
    children,
    toggleDrawer,
    sidebarOpen,
    loadTransition,
    mode,
    gradient,
    history,
    changeMode,
    place,
    handleOpenGuide,
    user,
    dataMenu,
    orgImages,
  } = props;

  const [pageLoaded, setPageLoaded] = useState(props.pageLoaded);
  const [activeHeader, setActiveHeader] = useState(true);
  const mainContent = useRef(null);
  const mobileView = window.innerWidth < 600;

  useEffect(() => {
    setPageLoaded(props.pageLoaded);
  }, [props.pageLoaded]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = mainContent.current.scrollTop;
      if (scrollPosition >= (mobileView ? 25 : 95)) {
        setActiveHeader(true);
      } else {
        setActiveHeader(false);
      }
    };

    mainContent?.current?.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      mainContent?.current?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const coverUploaded = orgImages?.toJS()?.find((image) => image?.type === 111)?.url;

  return (
    <div className={classes.mainContentContainer}>
      <div
        className={`${classes.sideBarContainer} ${sidebarOpen ? classes.sideBarOpen : ""}`}
      >
        <Sidebar
          open={sidebarOpen}
          toggleDrawerOpen={toggleDrawer}
          loadTransition={loadTransition}
          dataMenu={dataMenu}
          leftSidebar
          user={user}
        />
      </div>
      <main ref={mainContent} className={classNames(classes.content, !sidebarOpen ? classes.contentPaddingLeft : "")} id="mainContent">
        <img src={coverUploaded ? urlUtils.getProperImageUrl(coverUploaded) : meetusBg} alt="dashboard-background" className={classes.bgImg} />
        <div className={classNames(classes.headerContainer, activeHeader ? classes.headerActive : classes.headerActive)}>
          <Header
            toggleDrawerOpen={toggleDrawer}
            margin={sidebarOpen}
            gradient={gradient}
            position="left-sidebar"
            changeMode={changeMode}
            mode={mode}
            title={place}
            history={history}
            openGuide={handleOpenGuide}
            user={user}
            sidebarOpen={sidebarOpen}
            activeHeader={activeHeader}
          />
        </div>
        <section className={classNames(classes.mainWrap, classes.sidebarLayout)}>
          {!pageLoaded && (<div className={classes.spinnerLeftSide}><div><Spinner /></div></div>)}
          <Fade
            in={pageLoaded}
            mountOnEnter
            unmountOnExit
            {...(pageLoaded ? { timeout: 700 } : {})}
          >
            <div className={!pageLoaded ? classes.hideApp : ""}>
              {/* Application content will load here */}
              {children}
            </div>
          </Fade>
        </section>
      </main>
    </div>
  );
};

LeftSidebarLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  loadTransition: PropTypes.func.isRequired,
  changeMode: PropTypes.func.isRequired,
  sidebarOpen: PropTypes.bool.isRequired,
  pageLoaded: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  gradient: PropTypes.bool.isRequired,
  deco: PropTypes.bool.isRequired,
  bgPosition: PropTypes.string.isRequired,
  place: PropTypes.string.isRequired,
  titleException: PropTypes.array,
  handleOpenGuide: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  dataMenu: PropTypes.array.isRequired
};

LeftSidebarLayout.defaultProps = {
  titleException: [/^\/?$/]
};

export default connect(
  (state) => ({
    orgImages: state.getIn([
      "cachedData",
      "cache",
      "orgData",
      "value",
      "images",
    ]),
  }),
  null
)(withStyles(styles)(LeftSidebarLayout));
