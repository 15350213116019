const styles = (theme) => ({
  breadcrumbs: {
    gap: ".25rem",
    "& a": {
      color: "inherit",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
        color: theme.palette.primary.main,
      },
    },
  },
  separated: {
    margin: "0 .5rem",
    display: "flex",
    alignItems: "center",
    gap: ".75rem",
    "& span": {
      background: "#F5F5F5",
      borderRadius: 2,
      lineHeight: 0,
      display: "inline",
      height: 20,
      width: 35,
      fontSize: 37,
      textAlign: "center",
      color: "#0000008A",
    },
  },
});

export default styles;
