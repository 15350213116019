import cyan from "@material-ui/core/colors/cyan";
import indigo from "@material-ui/core/colors/indigo";
import red from "@material-ui/core/colors/red";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { gradientBgLight } from "containers/Templates/appStyles-jss";

const rootWraper = {
  display: "flex",
  width: "100%",
  zIndex: 1,
  position: "relative",
};

const wrapper = (theme, opacity) => ({
  padding: theme.spacing(3),
  textAlign: "center",
  backgroundColor: fade(theme.palette.background.paper, opacity),
  backgroundRepeat: "no-repeat",
  color: theme.palette.text.primary,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundAttachment: "fixed",
});

const styles = (theme) => ({
  root: {
    ...rootWraper,
  },
  rootFull: {
    ...rootWraper,
    height: "100%",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      overflow: "hidden",
    },
  },
  containerSide: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      overflow: "hidden",
    },
  },
  paperWrap: {
    width: "100%",
    maxWidth: 381,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "85%",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
  logoStyle: {
    transform: "scale(1.3)",
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  sideWrap: {
    ...wrapper(theme, 1),
    height: "100%",
    borderRadius: 0,
    [theme.breakpoints.up("md")]: {
      width: 480,
    },
    "& $topBar": {
      marginBottom: theme.spacing(4),
    },
  },
  fullWrap: {
    ...wrapper(theme, 0.9),
    height: "100%",
    borderRadius: 0,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    "& $topBar": {
      width: "100%",
    },
  },
  icon: {},
  topBar: {
    display: "flex",
    justifyContent: "space-between",
    "& $icon": {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      marginBottom: theme.spacing(3),
      "& a": {
        display: "none",
      },
    },
  },
  outer: {},
  brand: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px 10px",
    position: "relative",
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.primary,
    textDecoration: "none",
    "&$outer": {
      color: theme.palette.common.white,
    },
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(2),
    },
    "& img": {
      width: 104,
      marginRight: 10,
    },
  },
  pageFormWrap: {
    width: "100%",
    margin: `${theme.spacing(2)}px auto`,
    [theme.breakpoints.up("sm")]: {
      width: 480,
    },
  },
  pageFormSideWrap: {
    margin: "0 auto",
    [theme.breakpoints.only("sm")]: {
      width: 480,
    },
  },
  formControl: {
    width: "100%",
  },
  spaceMargin: {
    marginBottom: 20,
  },
  socmedLogin: {
    [theme.breakpoints.up("sm")]: {
      padding: "24px 100px 1px",
    },
    "& button": {
      padding: "4px 24px",
    },
  },
  socmedSideLogin: {
    padding: "24px 24px 1px",
    margin: "0 auto",
    "& button": {
      padding: "4px 16px",
      margin: `0 ${theme.spacing(1)}px`,
    },
    [theme.breakpoints.only("sm")]: {
      width: 480,
    },
  },
  userFormWrap: {
    width: "94%",
    [theme.breakpoints.up("md")]: {
      width: 720,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(3),
    },
  },
  sideFormWrap: {
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  fullFormWrap: {
    height: "100%",
    width: "100%",
  },
  title: {
    color: "rgba(255, 255, 255, 0.50)",
    textAlign: "center",
    fontSize: 16,
    fontWeight: 400,
    marginBottom: 24,
    width: "276px",
  },
  titleContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: 36,
  },
  field: {
    "& .MuiFormHelperText-contained": {
      marginRight: 0,
      marginLeft: 0,
    },
    "& .MuiInputBase-root": {
      backgroundColor: "rgba(255, 255, 255, 0.40) !important",
      color: "#62626B !important",
      fontFamily: ["ABeeZee", "Poppins", "Open Sans", "sans-serif"].join(","),
    },
    "& .MuiInputBase-root:hover": {
      backgroundColor: "transparent",
    },
    "& .MuiInputBase-root:focus": {
      backgroundColor: "transparent",
      outline: "2px solid white",
    },

    "& .MuiInputLabel-root": {
      color: "white", // Change label color to white
    },
    // "& .MuiOutlinedInput-root": {
    //   borderRadius: "8px",
    //   padding: 16,
    //   "& fieldset": {
    //     borderColor: "white", // Change outline color to white
    //   },
    //   "& input": {
    //     padding: 0,
    //   },
    // },
    // "& input::placeholder": {
    //   fontSize: "16px", // Change the placeholder font size to 16px
    //   textTransform: "capitalize",
    //   color: "#62626B !important",
    //   opacity: 1,
    // },
    "& input:-webkit-autofill": {
      // backgroundColor: "transparent !important", // Make the background transparent for autofill
      boxShadow: "none !important",
      WebkitBoxShadow: "none !important",
      transition: "background-color 9999s ease-in-out 0s",
      WebkitTextFillColor: "#62626B !important",
    },
    "& input:-internal-autofill-selected": {
      backgroundColor: "red !important",
      color: "red !important",
      appearance: "none !important",
      transition: "background-color 9999s ease-in-out 0s",
    },
    "& input:-webkit-autofill:hover": {
      transition: "background-color 9999s ease-in-out 0s",
    },
    "& input:-webkit-autofill:focus": {
      transition: "background-color 9999s ease-in-out 0s",
    },
  },
  eyeIcon: {
    color: "#62626B",
    padding: 0,
    "&:hover": {
      color: "#62626B",
      background: "transparent !important",
    },
    "& svg": {
      fontSize: 24,
    },
  },
  subtitle: {
    fontSize: 14,
    color: "white",
  },
  titleGradient: {
    background: gradientBgLight(theme),
    fontWeight: "bold",
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(3),
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.55em",
    },
  },
  opening: {
    color: theme.palette.common.white,
    width: "100%",
    textAlign: "center",
    "& h1": {
      display: "block",
      [theme.breakpoints.down("md")]: {
        fontSize: 32,
        lineHeight: "48px",
      },
    },
    "& p": {
      color: theme.palette.common.white,
      fontSize: 18,
      [theme.breakpoints.down("md")]: {
        fontSize: 14,
      },
    },
  },
  label: {
    "& label": {
      color: "#62626B !important",
      fontFamily: ["ABeeZee", "Poppins", "Open Sans", "sans-serif"].join(","),
      fontWeight: "400 !important",
      cursor: "pointer",
    },
  },
  btnArea: {
    display: "flex",
    justifyContent: "space-around",
    fontSize: 12,
    "& $label": {
      fontSize: 12,
      "& span": {
        fontSize: 12,
      },
    },
    "& button": {
      padding: "12px 20px",
    },
  },
  noMargin: {
    margin: 0,
  },
  redBtn: {
    color: red[500],
    borderColor: red[500],
    "&:hover": {
      borderColor: red[700],
    },
  },
  blueBtn: {
    color: indigo[300],
    borderColor: indigo[300],
    "&:hover": {
      borderColor: indigo[500],
    },
  },
  signInInput: {
    width: "100%",
    color: "white",
    textTransform: "capitalize",
    textAlign: "center",
    fontSize: 16,
    fontWeight: 400,
    fontFamily: ["ABeeZee", "Poppins", "Open Sans", "sans-serif"].join(","),
    borderRadius: 8,
  },
  cyanBtn: {
    color: cyan[500],
    borderColor: cyan[500],
    "&:hover": {
      borderColor: cyan[700],
    },
  },
  buttonLink: {
    background: "none",
    textTransform: "none",
    transition: "color ease 0.3s",
    color: "#62626B",
    fontFamily: ["ABeeZee", "Poppins", "Open Sans", "sans-serif"].join(","),
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: "1rem",
    padding: 0,
    textDecoration: "underline",
    "&:hover": {
      background: "none",
      textDecoration: "underline",
    },
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  footer: {
    textAlign: "center",
    padding: 5,
    background: theme.palette.grey[100],
    fontSize: 14,
    position: "relative",
  },
  welcomeWrap: {
    position: "relative",
  },
  tab: {
    margin: `${theme.spacing(3)}px 0 ${theme.spacing(1)}px`,
  },
  link: {
    fontSize: "0.875rem",
    color: theme.palette.secondary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  socmedFull: {
    textAlign: "center",
    width: "100%",
    margin: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
    "& button": {
      width: "100%",
      display: "block",
      margin: `0 auto ${theme.spacing(2)}px`,
    },
    [theme.breakpoints.up("sm")]: {
      "& button": {
        width: 400,
      },
    },
  },
  lockWrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  lockForm: {
    display: "flex",
    alignItems: "baseline",
  },
  unlockBtn: {
    top: -4,
  },
  notifyForm: {
    alignItems: "baseline",
    [theme.breakpoints.down("xs")]: {
      "& button": {
        marginTop: theme.spacing(3),
        width: "100%",
      },
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      "& button": {
        width: "auto",
      },
    },
  },
  lockField: {
    marginRight: theme.spacing(1),
    "& label": {
      color: `${theme.palette.common.white} !important`,
    },
    "& label + div": {
      background: fade(theme.palette.primary.light, 0.3),
      border: "none",
      "& svg": {
        fill: fade(theme.palette.common.white, 0.7),
      },
    },
  },
  avatar: {
    width: 150,
    height: 150,
    [theme.breakpoints.up("lg")]: {
      marginRight: theme.spacing(3),
    },
    boxShadow: theme.glow.medium,
  },
  userName: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(3),
      textAlign: "center",
    },
  },
  hint: {
    padding: theme.spacing(1),
  },
  brandCenter: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
  },
  centerAdornment: {
    justifyContent: "center",
    "& > div": {
      width: "100%",
    },
    "& aside": {
      top: -10,
      [theme.breakpoints.up("sm")]: {
        left: 10,
      },
      position: "relative",
    },
  },
  centerV: {
    justifyContent: "center",
  },
  optArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 10,
  },
  welcomeBackTitle: {
    color: "#1A1A1E",
    textAlign: "center",
    fontFamily: ["ABeeZee", "Poppins", "Open Sans", "sans-serif"].join(","),
    fontSize: 56,
    fontStyle: "normal",
    fontWeight: 400,
    [theme.breakpoints.down("xs")]: {
      fontSize: 34,
    },
  },
  subTitle: {
    color: "#62626B",
    textAlign: "center",
    fontFamily: ["ABeeZee", "Poppins", "Open Sans", "sans-serif"].join(","),
    fontSize: 18,
    fontStyle: "normal",
    fontWeight: 400,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  iconColored: {
    "& path": {
      fill: "black",
    },
  },
  btnMargin: {
    marginTop: 15,
  },
  optionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 2,
    marginTop: 15,
  },
  rememberMe: {
    color: "#62626B",
  },
  loginLink: {
    justifyContent: "flex-end",
  },
});

export default styles;
