import React from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { withStyles } from "@material-ui/core/styles";
import { EditIcon, DeleteIcon } from "api/icons/GlobalIcon";
import { Link } from "react-router-dom";
import urlUtils from "../../utils/url";
import styles from "./SharedCard-jss";

const SharedCard = ({ data, handleDelete, classes, editLink }) => {
  const { id, name, p_name: pName, logo, banner } = data;
  return (
    <Card className={classes.card}>
      <figure className={classes.media}>
        <img src={urlUtils.getProperImageUrl(banner)} alt={name} />
        <div className={classes.img_rounded}>
          <img src={urlUtils.getProperImageUrl(logo)} alt={name} />
        </div>
      </figure>
      <CardHeader
        classes={classes.cardHeader}
        title={<h4 className={classes.cardTitle}>{name}</h4>}
        action={(
          <div className={classes.cardActions}>
            <Link
              to={{
                pathname: editLink,
                state: { data },
              }}
            >
              <EditIcon />
            </Link>
            {handleDelete ? (
              <button type="button" onClick={handleDelete}>
                <DeleteIcon />
              </button>
            ) : null}
          </div>
        )}
      />
    </Card>
  );
};

const StyledSharedCard = withStyles(styles)(SharedCard);

export default StyledSharedCard;
