import React from "react";

const ChevronIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.96816 4.84839L5.97119 5.84536L9.20958 9.09081L5.97119 12.3363L6.96816 13.3332L11.2106 9.09081L6.96816 4.84839Z"
      fill="white"
    />
  </svg>
);

export default ChevronIcon;
