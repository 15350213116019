import React from "react";

const MapIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00004 8.95297C9.1488 8.95297 10.08 8.02172 10.08 6.87297C10.08 5.72422 9.1488 4.79297 8.00004 4.79297C6.85129 4.79297 5.92004 5.72422 5.92004 6.87297C5.92004 8.02172 6.85129 8.95297 8.00004 8.95297Z"
      stroke="currentcolor"
      strokeWidth="1.5"
    />
    <path
      d="M2.4133 5.66065C3.72664 -0.112681 12.28 -0.106014 13.5866 5.66732C14.3533 9.05399 12.2466 11.9207 10.4 13.694C9.05997 14.9873 6.93997 14.9873 5.5933 13.694C3.7533 11.9207 1.64664 9.04732 2.4133 5.66065Z"
      stroke="currentcolor"
      strokeWidth="1.5"
    />
  </svg>
);

export default MapIcon;
