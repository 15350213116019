import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { AnimatePresence, motion } from "framer-motion";
import useConfirm from "./hooks/useConfirm";

import styles from "./ConfirmDialog.module.scss";

const confirmDialogMotion = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.5 },
};

const boxMotion = {
  initial: { opacity: 0, y: -500 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -500 },
  transition: { duration: 0.5 },
};

const ConfirmDialog = () => {
  const {
    onConfirm,
    onCancel,
    confirmState: {
      title,
      text,
      confirmationLabel,
      cancelLabel,
      show
    }
  } = useConfirm();

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onCancel();
      }
    };

    window.addEventListener("keydown", close);

    return () => window.removeEventListener("keydown", close);
  }, []);

  const portalElement = document.getElementById("portal");
  const component = (
    <AnimatePresence mode="wait" initial={false}>
      {show && (
        <motion.div className={styles.confirmDialog} {...confirmDialogMotion}>
          <motion.div className={styles.box} {...boxMotion}>
            <div className={styles.content}>
              <h4 className={styles.title}>{title || `${window.location.hostname} says`}</h4>
              <p className={styles.text}>{text || "Are you sure to perform this action?"}</p>
            </div>
            <div className={styles.buttons}>
              <button onClick={onCancel}>{cancelLabel || "Cancel"}</button>
              <button className={styles.confirmButton} onClick={onConfirm}>{confirmationLabel || "Ok"}</button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );

  return createPortal(component || null, portalElement);
};
export default ConfirmDialog;
