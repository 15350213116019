import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { ADD_NOTIFICATION } from "actions/actionConstants";
import MeetUsLogo from "api/icons/MeetUsLogo";
import MeetUsLogoSmall from "api/icons/MeetUsLogoSmall";
import MeetusVrVerticalIcon from "api/icons/MeetusVrVerticalIcon";
import CloseIcon from "api/icons/CloseIcon";
import classNames from "classnames";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import UrlUtils from "utils/url";
import MainMenu from "./MainMenu";
import styles from "./sidebar-jss";

const SidebarContent = ({
  classes,
  drawerPaper,
  toggleDrawerOpen,
  loadTransition,
  dataMenu,
  theme,
  orgImage,
  orgName,
}) => {
  const modeType = theme.palette.type;
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      const isLargeScreenDevice = window.matchMedia(
        "(max-width: 600px)"
      ).matches;
      setMobileView(isLargeScreenDevice);
    };

    checkMobile();

    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  const displaySideBarImage = (
    lightSmImage,
    lightLgImage,
    darkSmImage,
    darkLgImage
  ) => {
    let smImage = lightSmImage;
    let lgImage = lightLgImage;

    if (modeType === "dark") {
      smImage = darkSmImage;
      lgImage = darkLgImage;
    }

    if (drawerPaper || mobileView) {
      return lgImage;
    }
    return smImage;
  };

  return (
    <div
      className={classNames(
        classes.drawerInner,
        !drawerPaper ? classes.drawerPaperClose : classes.drawerPaperOpen,
      )}
    >
      <div className={classes.contentHolder}>
        <div className={classes.drawerHeader}>
          <div className={classes.orgLogo}>
            {orgImage ? (
              <img
                src={UrlUtils.getProperImageUrl(
                  displaySideBarImage(orgImage, orgImage, orgImage, orgImage)
                )}
                alt={orgName}
                className={classes.orgLogoImage}
              />
            ) : (
              <p className={classes.orgName}>{orgName}</p>
            )}
          </div>
        </div>

        <div
          id="sidebar"
          className={classNames(
            classes.menuContainer,
            !drawerPaper && classes.collapsedMenu
          )}
        >
          <MainMenu
            loadTransition={loadTransition}
            dataMenu={dataMenu}
            toggleDrawerOpen={toggleDrawerOpen}
            isCollapsed={!drawerPaper}
            mobileView={mobileView}
          />
        </div>

        <div className={classes.drawerFooter}>
          <div className={classes.lineContainer}>
            <div className={classes.line} />
          </div>
          <div
            className={`${classes.meetusLogo} ${
              !drawerPaper && classes.collapsedLogo
            }
            ${
    drawerPaper ? classes.collapsedLogoHeight : ""
    }
            `}
          >
            {displaySideBarImage(
              <MeetusVrVerticalIcon />,
              <MeetUsLogo />,
              <MeetusVrVerticalIcon />,
              <MeetUsLogo />
            )}
          </div>
        </div>
      </div>

      <IconButton
        onClick={() => toggleDrawerOpen()}
        color="inherit"
        className={classNames(
          classes.closeIcon
        )}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
};

SidebarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  drawerPaper: PropTypes.bool.isRequired,
  toggleDrawerOpen: PropTypes.func,
  loadTransition: PropTypes.func,
  dataMenu: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
  orgImage: PropTypes.string,
  orgName: PropTypes.string,
  open: PropTypes.bool.isRequired,
};

SidebarContent.defaultProps = {
  toggleDrawerOpen: () => {},
  loadTransition: () => {},
};

const StyledComponent = withStyles(styles, { withTheme: true })(SidebarContent);

export default connect(
  (state) => ({
    orgId: state.getIn(["users", "user", "info", "organization_id"]),
    orgImage: state.getIn([
      "cachedData",
      "cache",
      "orgData",
      "value",
      "themes",
      "logo_url",
    ]),
    orgName: state.getIn(["cachedData", "cache", "orgData", "value", "name"]),
  }),
  (dispatch) => ({
    addNotification: (message) => dispatch({ type: ADD_NOTIFICATION, message }),
  })
)(StyledComponent);
