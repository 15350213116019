import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import { PropTypes } from "prop-types";
import styles from "./DashboardWrapper-jss";

const DashboardWrapper = ({ classes, children, marginAll }) => (
  <div
    className={classNames(
      classes.dashboardWrapper,
      marginAll ? classes.marginAll : ""
    )}
  >
    {children}
  </div>
);

DashboardWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  marginAll: PropTypes.bool,
};

export default withStyles(styles)(DashboardWrapper);
