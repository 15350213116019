import React from "react";

const SharedIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M20.62 13.07C20.24 13.07 19.92 12.78 19.87 12.4C19.63 10.14 18.41 8.08998 16.53 6.78998C16.19 6.54998 16.11 6.08998 16.34 5.74998C16.58 5.40998 17.05 5.32998 17.38 5.55998C19.62 7.10998 21.06 9.54998 21.35 12.24C21.39 12.65 21.1 13.02 20.68 13.07C20.67 13.07 20.64 13.07 20.62 13.07Z"
      fill="#4E5057"
    />
    <path
      d="M3.49003 13.12C3.46003 13.12 3.44003 13.12 3.41003 13.12C3.00003 13.07 2.70003 12.7 2.74003 12.29C3.01003 9.60001 4.44003 7.17001 6.65003 5.60001C6.99003 5.36001 7.46003 5.44001 7.70003 5.78001C7.94003 6.12001 7.86003 6.59001 7.52003 6.83001C5.66003 8.14001 4.46003 10.19 4.23003 12.45C4.20003 12.83 3.87003 13.12 3.49003 13.12Z"
      fill="#4E5057"
    />
    <path
      d="M12.06 22.61C10.58 22.61 9.17003 22.27 7.85003 21.61C7.48003 21.42 7.33003 20.97 7.52003 20.6C7.71003 20.23 8.16003 20.08 8.53003 20.27C10.69 21.36 13.29 21.38 15.47 20.33C15.84 20.15 16.29 20.31 16.47 20.68C16.65 21.05 16.49 21.5 16.12 21.68C14.84 22.3 13.48 22.61 12.06 22.61Z"
      fill="#4E5057"
    />
    <path
      d="M12.06 8.44C10.11 8.44 8.53003 6.86 8.53003 4.91C8.53003 2.96 10.11 1.38 12.06 1.38C14.01 1.38 15.59 2.96 15.59 4.91C15.59 6.86 14 8.44 12.06 8.44ZM12.06 2.89C10.94 2.89 10.03 3.8 10.03 4.92C10.03 6.04 10.94 6.95 12.06 6.95C13.18 6.95 14.09 6.04 14.09 4.92C14.09 3.8 13.17 2.89 12.06 2.89Z"
      fill="#4E5057"
    />
    <path
      d="M4.82999 20.67C2.87999 20.67 1.29999 19.09 1.29999 17.14C1.29999 15.2 2.87999 13.61 4.82999 13.61C6.77999 13.61 8.35999 15.19 8.35999 17.14C8.35999 19.08 6.77999 20.67 4.82999 20.67ZM4.82999 15.11C3.70999 15.11 2.79999 16.02 2.79999 17.14C2.79999 18.26 3.70999 19.17 4.82999 19.17C5.94999 19.17 6.85999 18.26 6.85999 17.14C6.85999 16.02 5.94999 15.11 4.82999 15.11Z"
      fill="#4E5057"
    />
    <path
      d="M19.17 20.67C17.22 20.67 15.64 19.09 15.64 17.14C15.64 15.2 17.22 13.61 19.17 13.61C21.12 13.61 22.7 15.19 22.7 17.14C22.69 19.08 21.11 20.67 19.17 20.67ZM19.17 15.11C18.05 15.11 17.14 16.02 17.14 17.14C17.14 18.26 18.05 19.17 19.17 19.17C20.29 19.17 21.2 18.26 21.2 17.14C21.19 16.02 20.29 15.11 19.17 15.11Z"
      fill="#4E5057"
    />
  </svg>
);

export default SharedIcon;
