/* eslint-disable arrow-body-style */
/* eslint-disable react/self-closing-comp */
import { useWallet } from "containers/App/WalletWrapper";
import React, { useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";

const Test = () => {
  // eslint-disable-next-line operator-linebreak

  return <div></div>;
};

export default Test;
