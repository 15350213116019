import React from "react";

const Stores360Icon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_4050_51)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.13174 11.7164C4.30793 11.8588 4.53903 11.914 4.76052 11.8664C8.23939 11.1199 11.765 11.1208 15.2395 11.8664C15.461 11.914 15.6921 11.8588 15.8683 11.7164C16.0445 11.5741 16.1469 11.3597 16.1469 11.1331V10.4167C17.2236 10.1128 18.1597 9.72168 18.8705 9.25104C19.6803 8.7149 20.3594 7.96054 20.3594 6.98859C20.3594 6.29435 20.0067 5.70624 19.526 5.24439C19.047 4.78418 18.3908 4.39681 17.6356 4.07653C16.1209 3.43421 14.0563 2.99828 11.7543 2.85045C11.3409 2.8239 10.9843 3.13748 10.9578 3.55084C10.9312 3.9642 11.2448 4.32082 11.6581 4.34736C13.8493 4.48808 15.7363 4.9004 17.0499 5.45748C17.7088 5.7369 18.1859 6.03703 18.4867 6.32603C18.7858 6.6134 18.8594 6.83623 18.8594 6.98859C18.8594 7.20191 18.7034 7.56275 18.0425 8.00033C17.4059 8.42176 16.4441 8.81128 15.2194 9.10963C14.8834 9.19148 14.6469 9.49248 14.6469 9.83832V10.2213C11.5615 9.66903 8.44176 9.66847 5.35316 10.2213V9.83836C5.35316 9.49251 5.11668 9.19151 4.78066 9.10966C3.55581 8.81131 2.59394 8.42179 1.95746 8.00036C1.29664 7.56281 1.14062 7.20196 1.14062 6.98859C1.14062 6.83631 1.21409 6.61366 1.5128 6.3265C1.81322 6.03769 2.28981 5.73772 2.94803 5.4584C4.26038 4.90149 6.14564 4.48909 8.33519 4.34786C8.74854 4.32119 9.06202 3.96449 9.03536 3.55114C9.0087 3.13778 8.65199 2.82431 8.23864 2.85097C5.93826 2.99935 3.87539 3.43539 2.36207 4.07758C1.60746 4.39781 0.951831 4.78507 0.473254 5.24513C-0.00704122 5.70685 -0.359375 6.29472 -0.359375 6.98859C-0.359375 7.9605 0.319556 8.71486 1.12934 9.25105C1.84018 9.72171 2.77636 10.1128 3.85316 10.4168V11.1331C3.85316 11.3597 3.95555 11.574 4.13174 11.7164Z"
        fill="#4E5057"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.390625 6.23865C0.804839 6.23865 1.14062 6.57443 1.14062 6.98865C1.14062 7.20198 1.29665 7.56291 1.95744 8.0005C2.59388 8.42197 3.55571 8.81152 4.78056 9.1097C5.11663 9.19151 5.35316 9.49253 5.35316 9.83841V16.4552C5.35316 16.6854 5.24751 16.9028 5.06655 17.045C4.88559 17.1872 4.64937 17.2384 4.42576 17.184C3.10786 16.8631 1.96344 16.4205 1.12931 15.8683C0.319533 15.3322 -0.359375 14.5778 -0.359375 13.606V6.98865C-0.359375 6.57443 -0.0235886 6.23865 0.390625 6.23865ZM1.14062 9.25865V13.606C1.14062 13.8193 1.29658 14.1801 1.95737 14.6175C2.42315 14.9259 3.06323 15.2172 3.85316 15.4681V10.4169C2.78201 10.1146 1.85006 9.72603 1.14062 9.25865Z"
        fill="#4E5057"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6095 6.23865C20.0237 6.23865 20.3595 6.57444 20.3595 6.98865C20.3595 6.98866 20.3595 6.98864 20.3595 6.98866L20.3594 13.5952C20.3595 13.5988 20.3595 13.6024 20.3595 13.606C20.3595 14.5778 19.6806 15.3322 18.8708 15.8683C18.0367 16.4205 16.8923 16.8631 15.5744 17.184C15.3508 17.2384 15.1145 17.1872 14.9336 17.045C14.7526 16.9028 14.647 16.6854 14.647 16.4552V9.83841C14.647 9.49255 14.8835 9.19153 15.2195 9.1097C16.4441 8.81152 17.406 8.42197 18.0425 8.00048C18.7035 7.56283 18.8595 7.20189 18.8595 6.98865L18.8595 6.98864C18.8595 6.57443 19.1953 6.23865 19.6095 6.23865ZM18.8595 9.25853C18.15 9.72594 17.218 10.1146 16.147 10.4169V15.4681C16.9369 15.2172 17.577 14.9259 18.0428 14.6175C18.6974 14.1841 18.8566 13.826 18.8595 13.612C18.8594 13.6095 18.8594 13.607 18.8594 13.6045L18.8595 9.25853Z"
        fill="#4E5057"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.72077 7.07649C7.44308 5.63024 12.5576 5.63176 16.2786 7.07893C16.6647 7.22907 16.8559 7.66374 16.7058 8.04978C16.5556 8.43583 16.121 8.62706 15.7349 8.47692C12.3634 7.16565 7.63662 7.16429 4.26401 8.47467C3.87792 8.62468 3.44331 8.4333 3.2933 8.0472C3.14329 7.66111 3.33467 7.22651 3.72077 7.07649Z"
        fill="#4E5057"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.00023 6.01403C5.22086 6.14947 5.28992 6.43812 5.15448 6.65875C4.97733 6.94734 4.80935 7.23805 4.65053 7.53041C4.97203 7.662 5.28647 7.80154 5.59353 7.94853C5.82703 8.06031 5.92572 8.34022 5.81394 8.57372C5.70216 8.80723 5.42225 8.90591 5.18874 8.79413C4.75097 8.58458 4.29687 8.39077 3.82738 8.21445C3.70256 8.16757 3.6036 8.06975 3.55529 7.94548C3.50698 7.8212 3.51388 7.68223 3.57426 7.56335C3.81269 7.09394 4.0731 6.62834 4.35552 6.16829C4.49095 5.94766 4.7796 5.8786 5.00023 6.01403Z"
        fill="#4E5057"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9971 6.01302C15.2177 5.87762 15.5064 5.94672 15.6418 6.16736C15.9248 6.62849 16.1858 7.0952 16.4246 7.56577C16.485 7.68464 16.4918 7.82356 16.4435 7.9478C16.3952 8.07204 16.2963 8.16983 16.1715 8.21672C15.7033 8.39267 15.2505 8.58595 14.8138 8.79493C14.5803 8.90669 14.3004 8.80799 14.1886 8.57447C14.0769 8.34096 14.1756 8.06105 14.4091 7.94929C14.715 7.80287 15.0282 7.66387 15.3485 7.53275C15.1891 7.23928 15.0205 6.94744 14.8427 6.65772C14.7073 6.43707 14.7764 6.14843 14.9971 6.01302Z"
        fill="#4E5057"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.35042 14.359C6.35042 14.6179 6.56029 14.8278 6.81917 14.8278C7.44257 14.8278 7.948 14.3224 7.948 13.6989C7.948 13.0755 7.44257 12.5701 6.81917 12.5701C6.27005 12.5701 5.81337 12.9617 5.7115 13.4807C5.66164 13.7348 5.82716 13.9811 6.0812 14.031C6.33524 14.0808 6.58159 13.9153 6.63145 13.6613C6.64864 13.5737 6.72665 13.5076 6.81917 13.5076C6.92484 13.5076 7.0105 13.5933 7.0105 13.6989C7.0105 13.8046 6.92484 13.8903 6.81917 13.8903C6.56029 13.8903 6.35042 14.1001 6.35042 14.359Z"
        fill="#4E5057"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.70131 15.1761C5.77802 15.7254 6.24893 16.1479 6.81926 16.1479C7.44269 16.1479 7.94809 15.6425 7.94809 15.0191C7.94809 14.3957 7.44267 13.8903 6.81926 13.8903C6.56037 13.8903 6.35051 14.1001 6.35051 14.359C6.35051 14.4728 6.39106 14.5771 6.45851 14.6583C6.37731 14.5909 6.27298 14.5503 6.15918 14.5503C5.9003 14.5503 5.69043 14.7602 5.69043 15.0191C5.69043 15.0717 5.69404 15.1241 5.70129 15.1759M6.51993 14.7198C6.60113 14.7872 6.70546 14.8278 6.81926 14.8278C6.92491 14.8278 7.01059 14.9134 7.01059 15.0191C7.01059 15.1248 6.92489 15.2104 6.81926 15.2104C6.72301 15.2104 6.64271 15.139 6.6298 15.0464L6.62975 15.046C6.6286 15.0378 6.62793 15.0288 6.62793 15.0191C6.62793 14.9053 6.58738 14.8009 6.51993 14.7198Z"
        fill="#4E5057"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.60427 15.2465C8.86146 15.2761 9.0939 15.0915 9.12345 14.8343C9.17546 14.3815 9.36256 14.0337 9.53992 13.7963C9.62846 13.6779 9.71298 13.5892 9.77329 13.5317C9.80332 13.5031 9.82698 13.4826 9.84162 13.4704C9.84893 13.4643 9.85395 13.4603 9.85634 13.4584C9.8574 13.4576 9.85794 13.4572 9.85794 13.4572L9.85791 13.4572L9.85712 13.4578L9.85632 13.4584L9.85582 13.4588L9.85554 13.459C10.063 13.3044 10.106 13.011 9.95161 12.8034C9.79711 12.5956 9.50346 12.5525 9.29574 12.707L9.57549 13.0831C9.29574 12.707 9.29558 12.7071 9.29543 12.7072L9.29509 12.7075L9.29437 12.708L9.29272 12.7093L9.28854 12.7124L9.27683 12.7215C9.26759 12.7288 9.2555 12.7385 9.24095 12.7506C9.21188 12.7749 9.17281 12.809 9.12677 12.8528C9.03492 12.9403 8.91391 13.0679 8.78895 13.2351C8.53933 13.5692 8.26756 14.0702 8.19207 14.7273C8.16253 14.9845 8.34707 15.217 8.60427 15.2465Z"
        fill="#4E5057"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.18115 14.889C8.18115 15.5599 8.72502 16.1038 9.39592 16.1038C10.0668 16.1038 10.6106 15.5599 10.6106 14.889C10.6106 14.2182 10.0668 13.6743 9.39592 13.6743C8.72502 13.6743 8.18115 14.2181 8.18115 14.889ZM9.39592 15.1663C9.24275 15.1663 9.11865 15.0421 9.11865 14.889C9.11865 14.7359 9.24275 14.6118 9.39592 14.6118C9.54899 14.6118 9.67311 14.7359 9.67311 14.889C9.67311 15.0422 9.54899 15.1663 9.39592 15.1663Z"
        fill="#4E5057"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8013 14.9556C10.8013 15.6264 11.345 16.1703 12.016 16.1703C12.6868 16.1703 13.2307 15.6265 13.2307 14.9556V13.7626C13.2307 13.0916 12.6868 12.5478 12.016 12.5478C11.345 12.5478 10.8013 13.0917 10.8013 13.7626V14.9556ZM12.016 15.2328C11.8629 15.2328 11.7388 15.1087 11.7388 14.9556V13.7626C11.7388 13.6094 11.8629 13.4853 12.016 13.4853C12.1691 13.4853 12.2932 13.6095 12.2932 13.7626V14.9556C12.2932 15.1086 12.1691 15.2328 12.016 15.2328Z"
        fill="#4E5057"
      />
    </g>
  </svg>
);

export default Stores360Icon;
