import axios from "axios";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { eventChannel, END } from "redux-saga";
// import { SubmissionError } from "redux-form";
import { fromJS } from "immutable";
import {
  SUBMIT_REQUEST,
  SUBMIT_PROGRESS,
  SUBMIT_COMPLETED,
  SUBMIT_ERROR,
  SUBMIT_STARTED
} from "../actions/actionConstants";
import formUtils from "../utils/form";
import { RequestGroup } from "../utils/data";

async function uploadAndReplaceImages(imagesToUpload, onProgress, cancelToken) {
  const size = imagesToUpload.map(({ parent, key }) => parent[key].size || 0);
  const uploaded = imagesToUpload.map(() => 0);
  const resolves = new Array(imagesToUpload.length);
  const rejects = new Array(imagesToUpload.length);
  const promises = imagesToUpload.map(
    (_, i) =>
      new Promise((resolve, reject) => {
        resolves[i] = resolve;
        rejects[i] = reject;
      })
  );
  const onUploadProgress = (i) => ({ loaded: newLoaded, total: newTotal }) => {
    uploaded[i] = newLoaded;
    size[i] = newTotal;
    const loaded = uploaded.reduce((sum, x) => x + sum, 0);
    const total = size.reduce((sum, x) => x + sum, 0);
    onProgress({ loaded, total });
  };

  const requestGroup = new RequestGroup(false);
  imagesToUpload.forEach(async ({ parent, key, imageProperties }, i) => {
    let imageUrl;
    try {
      if (imageProperties?.type === "raw") {
        imageUrl = await requestGroup.postFile(
          parent[key],
          imageProperties?.orgId,
          cancelToken
        );
      } else {
        ({ image_url: imageUrl } = await requestGroup.postOrgImage(
          parent[key],
          imageProperties,
          onUploadProgress(i),
          cancelToken
        ));
      }
      // eslint-disable-next-line no-param-reassign
      parent[key] = imageUrl;
      resolves[i]();
    } catch (ex) {
      rejects[i](ex);
    }
  });
  await Promise.all(promises);
}

async function upload(
  url,
  formValues,
  cancelToken,
  onProgress,
  imagesProperties,
  stringifyFields,
  requestMethod
) {
  let latestProgress = { loaded: 0, total: 1 };
  let onUploadProgress = (progressEvent) => {
    latestProgress = progressEvent;
    onProgress(progressEvent);
  };
  const imagesToUpload = formUtils.format.identifyImages(
    formValues,
    imagesProperties
  );
  await uploadAndReplaceImages(imagesToUpload, onUploadProgress, cancelToken);
  onUploadProgress = (ProgressEvent) => {
    if (
      ProgressEvent.loaded / ProgressEvent.total
      > latestProgress.loaded / latestProgress.total
    ) {
      onProgress(ProgressEvent);
    }
  };
  let immutable = fromJS(formValues);
  stringifyFields.forEach(path => {
    const pathArray = path.split(".");
    const oldValue = immutable.getIn(pathArray);
    immutable = immutable.setIn(pathArray, JSON.stringify(oldValue));
  });

  return axios({ method: requestMethod || "POST", url, data: immutable }, {
    onUploadProgress,
    cancelToken,
  });
}

function startUploader(
  url,
  formValues,
  imagesProperties,
  stringifyFields,
  requestMethod
) {
  let emit;
  const progressChannel = eventChannel((emitter) => {
    emit = emitter;
    return () => {};
  });
  const { token: cancelToken, cancel } = axios.CancelToken.source();
  const uploadPromise = upload(
    url,
    formValues,
    cancelToken,
    (ProgressEvent) => {
      if (ProgressEvent.loaded < ProgressEvent.total) {
        emit(Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total));
      } else emit(END);
    },
    imagesProperties,
    stringifyFields,
    requestMethod
  );

  return { uploadPromise, progressChannel, cancel };
}

function* progressChanged(formName, value) {
  yield put({ type: SUBMIT_PROGRESS, formName, progress: value });
}

function* submitForm(action) {
  const {
    formName,
    formUrl,
    formValues,
    resolve,
    reject,
    imagesProperties,
    stringifyFields,
    requestMethod,
  } = action;
  let canceller = () => {};
  try {
    const { uploadPromise, progressChannel, cancel } = yield call(
      startUploader,
      formUrl,
      formValues,
      imagesProperties,
      stringifyFields,
      requestMethod
    );
    canceller = cancel;
    yield put({ type: SUBMIT_STARTED, formName, cancel });
    yield takeLatest(progressChannel, progressChanged, formName);
    const { data: result } = yield call(() => uploadPromise);
    yield put({ type: SUBMIT_COMPLETED, formName });
    resolve({ result, message: "success" });
  } catch (error) {
    canceller();
    yield put({ type: SUBMIT_ERROR, formName });
    if (axios.isCancel(error)) {
      reject("cancelled");
    } else {
      // reject(new SubmissionError(error.response.data));
      reject(error.response?.data?.message || error.response?.data?.error || "unexpected error");
    }
  }
}

export default function* formsSaga() {
  yield takeEvery(SUBMIT_REQUEST, submitForm);
}
