import React from "react";

const EmailIcon = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.6445 2.83508H5.46743C3.31432 2.83508 1.87891 3.91164 1.87891 6.42361V11.4476C1.87891 13.9595 3.31432 15.0361 5.46743 15.0361H12.6445C14.7976 15.0361 16.233 13.9595 16.233 11.4476V6.42361C16.233 3.91164 14.7976 2.83508 12.6445 2.83508ZM12.9818 7.20591L10.7354 9.00018C10.2617 9.38056 9.65884 9.56716 9.05596 9.56716C8.45309 9.56716 7.84304 9.38056 7.37653 9.00018L5.13011 7.20591C4.90045 7.01931 4.86456 6.67481 5.04399 6.44514C5.23059 6.21548 5.56791 6.17241 5.79758 6.35902L8.044 8.15328C8.58945 8.59108 9.51529 8.59108 10.0608 8.15328L12.3072 6.35902C12.5368 6.17241 12.8813 6.2083 13.0608 6.44514C13.2474 6.67481 13.2115 7.01931 12.9818 7.20591Z"
      fill="currentColor"
    />
  </svg>
);

export default EmailIcon;
