import Drawer from "@material-ui/core/Drawer";
import { withStyles } from "@material-ui/core/styles";
import dummy from "api/dummy/dummyContents";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import SidebarContent from "./SidebarContent";
import styles from "./sidebar-jss";

class Sidebar extends React.Component {
  state = {
    status: dummy.user.status,
    anchorEl: null,
    turnDarker: false,
  };

  // Initial header style
  flagDarker = false;

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const doc = document.documentElement;
    const scroll = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    const newFlagDarker = scroll > 30;
    if (this.flagDarker !== newFlagDarker) {
      this.setState({ turnDarker: newFlagDarker });
      this.flagDarker = newFlagDarker;
    }
  };

  handleOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleChangeStatus = (status) => {
    this.setState({ status });
    this.handleClose();
  };

  render() {
    const {
      classes,
      open,
      toggleDrawerOpen,
      loadTransition,
      leftSidebar,
      dataMenu,
      user,
    } = this.props;
    const { status, anchorEl, turnDarker } = this.state;

    return (
      <Drawer
        variant="permanent"
        onClose={toggleDrawerOpen}
        classes={{
          paper: classNames(
            classes.drawer,
            classes.drawerPaper,
            !open ? classes.drawerPaperClose : classes.drawerPaperOpen
          ),
        }}
        open={open}
        anchor={leftSidebar ? "left" : "right"}
      >
        <SidebarContent
          drawerPaper={open}
          leftSidebar={leftSidebar}
          turnDarker={turnDarker}
          loadTransition={loadTransition}
          dataMenu={dataMenu}
          status={status}
          user={user}
          anchorEl={anchorEl}
          openMenuStatus={this.handleOpen}
          closeMenuStatus={this.handleClose}
          changeStatus={this.handleChangeStatus}
          toggleDrawerOpen={toggleDrawerOpen}
          open={open}
        />
      </Drawer>
    );
  }
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleDrawerOpen: PropTypes.func.isRequired,
  loadTransition: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  leftSidebar: PropTypes.bool,
  dataMenu: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

Sidebar.defaultProps = {
  leftSidebar: true,
};

export default withStyles(styles)(Sidebar);
