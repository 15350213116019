import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";
import { REMOVE_NOTIFICATION } from "../../actions/actionConstants";

import "react-toastify/dist/ReactToastify.css";
import styles from "./index-jss";

const NotificationsProvider = ({ notifications, remove, classes }) => (
  <ToastContainer
    position="top-right"
    autoClose={3000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    limit={4}
    className={classes.toastContainer}
  >
    {notifications.entrySeq().forEach(([key, value]) => {
      const checkMessage = value?.startsWith("class com.nasnav");
      toast((checkMessage || !value) ? "An error occurred" : value);
      remove(key);
    })}
  </ToastContainer>
);

const StyledComponent = withStyles(styles)(NotificationsProvider);

export default connect(
  state => ({
    notifications: state.getIn(["notifications", "notifications"])
  }),
  dispatch => ({
    remove: key => dispatch({ type: REMOVE_NOTIFICATION, key })
  })
)(StyledComponent);
