const style = (theme) => ({
  boxInfoBody: {
    marginTop: 20,
    display: "flex",
    padding: "15px 0",
    gap: "3rem",

    "& > p": {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: "143%",
      letterSpacing: 0.166718,
      textTransform: "capitalize",
    },
  },
  userAvatar: {
    borderRadius: "0.5rem",
    width: 144,
    height: 132,
  },
  userInfo: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1rem",
    "& .MuiListItemIcon-root": {
      minWidth: 40,
      color: "#1976D2",
      "& path": {
        fill: "currentcolor",
      },
    },
    "& .MuiListItem-gutters": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  flexText: {
    display: "flex",
    gap: ".5rem",
    "& :first-child": {
      minWidth: 90,
    },
  },
  link: {
    textDecoration: "underline",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 14,
    textTransform: "capitalize",
  },
  imgContainer: {
    width: 144,
    height: 144,
    borderRadius: 16,
    overflow: "hidden",
  },
  userImg: {
    width: "100%",
  },
});

export default style;
