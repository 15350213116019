export function setCookie(cookieName, cookieValue, daysToExpire) {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + daysToExpire);
  document.cookie = `${encodeURIComponent(cookieName)}=${encodeURIComponent(
    cookieValue
  )}; expires=${expirationDate.toUTCString()}; path=/`;
}

export function removeCookie(cookieName) {
  document.cookie =
    encodeURIComponent(cookieName) +
    "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export function getCookie(cookieName) {
  const cookies = document.cookie.split(";");

  for (const cookie of cookies) {
    const trimmedCookie = cookie.trim();
    if (trimmedCookie.startsWith(`${encodeURIComponent(cookieName)}=`)) {
      return decodeURIComponent(trimmedCookie.substring(cookieName.length + 1));
    }
  }
  return null;
}
