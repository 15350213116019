const styles = (theme) => ({
  outerContainer: {
    width: "100%",
    height: "100vh",
    position: "relative",
    overflow: "hidden",
    background: "rgba(255, 255, 255, 0.30)",
    backdropFilter: "blur(4.5px)",
    "-webkit-backdrop-filter": "blur(4.5px)",
  },
  contentContainer: {
    width: "100%",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 5,
    display: "flex",
  },
  formContent: {
    width: "40%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%",
      paddingInline: 15,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  meetUsLogo: {
    width: "60%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      width: "90%",
      maxWidth: 710,
      pointerEvents: "none",
    },
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  ellipseOne: {
    position: "fixed",
    top: "-372px",
    left: 38,
    zIndex: 1,
  },
  ellipseTwo: {
    position: "fixed",
    top: "-247px",
    left: 638,
    zIndex: 3,
  },
  ellipseThree: {
    position: "fixed",
    top: 846,
    left: "-117px",
    zIndex: 4,
  },
  ellipseFour: {
    position: "fixed",
    top: 667,
    left: 1083,
    zIndex: 2,
  },
});

export default styles;
