import {
  call,
  fork,
  put,
  takeLatest
} from "redux-saga/effects";
import {
  SAVE_USER_INFO,
  SAVE_CACHE,
} from "../actions/actionConstants";
import Data from "../utils/data";

function* saveOrgData(orgData, date) {
  yield put({ type: SAVE_CACHE, field: "orgData", data: orgData, requestDate: date });
}

function* getData({ userInfo: { organization_id: orgId } }) {
  const date = new Date();
  const orgData = yield call(Data.getOrgDataById, orgId);
  yield fork(saveOrgData, orgData, date);
}

export default function* loginSaga() {
  yield takeLatest(SAVE_USER_INFO, getData);
}
