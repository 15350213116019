import React from "react";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { ADD_NOTIFICATION } from "actions/actionConstants";
import EmailOutline from "api/icons/EmailOutline";
import LockOutline from "api/icons/LockOutline";
import MeetUsLogo from "api/icons/MeetUsLogo";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Field, reduxForm } from "redux-form/immutable";
import { CheckboxRedux, TextFieldRedux } from "./ReduxFormMUI";
import styles from "./user-jss";

// validation functions
const required = (value) => (value == null ? "Required" : undefined);
const email = (value) =>
  (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email"
    : undefined);

const LinkBtn = React.forwardRef((props, ref) => (
  // eslint-disable-next-line react/prop-types
  <NavLink to={props.to} {...props} innerRef={ref} />
));

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  handleClickShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {
    const { classes, handleSubmit } = this.props;
    const { showPassword } = this.state;

    return (
      <div className={classNames(classes.paperWrap)}>
        <header className={classes.titleContainer}>
          <section className={classNames(classes.brand, classes.outer)}>
            <MeetUsLogo className={classes.logoStyle} />
          </section>

          <Typography variant="h6" className={classes.welcomeBackTitle}>
            Welcome back
          </Typography>

          <Typography variant="h6" className={classes.subTitle}>
            Step into our shopping metaverse for an unforgettable shopping experience
          </Typography>
        </header>

        <form onSubmit={handleSubmit}>
          <FormControl className={classNames(classes.formControl, classes.spaceMargin)}>
            <Field
              name="email"
              component={TextFieldRedux}
              placeholder="Email"
              required
              validate={[required, email]}
              className={classes.field}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailOutline />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          <FormControl className={classes.formControl}>
            <Field
              name="password"
              component={TextFieldRedux}
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutline />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                    className={classes.eyeIcon}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
              required
              validate={required}
              className={classes.field}
            />
          </FormControl>

          <section className={classes.optArea}>
            <Field
              key="remember_me"
              name="remember_me"
              label="Remember"
              component={CheckboxRedux}
              className={classes.label}
            />
            <Button
              size="small"
              component={LinkBtn}
              to="/forgot-password"
              className={classes.buttonLink}
            >
              Forget password?
            </Button>
          </section>

          <footer className={classes.btnArea}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              className={classes.signInInput}
            >
              Login
            </Button>
          </footer>
        </form>
      </div>
    );
  }
}

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  deco: PropTypes.bool.isRequired,
};

const LoginFormReduxed = reduxForm({
  form: "immutableExample",
  enableReinitialize: true,
})(LoginForm);

const reducerLogin = "login";
const reducerUi = "ui";
const FormInit = connect(
  (state) => ({
    initialValues: state.getIn([reducerLogin, "usersLogin"]),
    deco: state.getIn([reducerUi, "decoration"]),
  }),
  (dispatch) => ({
    onSubmitSuccess: (message) => dispatch({ type: ADD_NOTIFICATION, message }),
    onSubmitFail: (...[, , submitError]) => {
      switch (submitError?.error) {
        case "U$LOG$0002":
          dispatch({
            type: ADD_NOTIFICATION,
            message: "Invalid email or password",
          });
          break;
        case "U$LOG$0003":
          dispatch({
            type: ADD_NOTIFICATION,
            message: "Sorry, the account needed to be activated",
          });
          break;
        case "U$LOG$0004":
          dispatch({
            type: ADD_NOTIFICATION,
            message: "Sorry, the account has been suspended",
          });
          break;
        default:
          dispatch({
            type: ADD_NOTIFICATION,
            message: submitError?.message || "unexpected error",
          });
          break;
      }
    },
  })
)(LoginFormReduxed);

export default withStyles(styles)(FormInit);
